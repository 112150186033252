export const Setup ={
    endpoint:"https://api.step-space.com",
    //endpoint:"http://localhost:4000",
    //endpoint:"http://192.168.89.233:4000",
    app_name:"step",
    filePoint:"https://media.step-space.com/",
    ImageClientPoint:"https://media.step-space.com/static_images/client/",
    payment_endpoint:process.env.REACT_APP_API_PAYMENT_ENDPOINT,
    version:process.env.REACT_APP_API_VERSION,
    default_malls:"ww8Sa3xrPp",
    mqtt:process.env.REACT_APP_MQTT,

}

//    "start": "set HTTPS=true&&react-scripts start",
