import React, {useEffect, useState} from 'react';
import "./DumbbellTraineeLandPlan.scss";
import loginImage from  "../../assets/dumbbell_image/login.png";
import logoWhite from  "../../assets/dumbbell_image/logo-white.png";
import keyIcon from  "../../assets/dumbbell_image/key_icon.png";
import {useHistory, useLocation, useParams} from "react-router";
import {setTokenToLocalStorage, setUserToLocalStorage, validation} from "../../Services/utils";
import {Drawer, message, Spin} from "antd";
import {loginApi, traineeWelcomeApi, trainerWelcomeApi, verifyApi} from "../../Services/Services";
import {Setup} from "../../Services/Setup";
import defaultUserIcon from "../../assets/images/default_user.png";
import whiteSpeakerIcon from "../../assets/dumbbell_image/V2/white_speaker_icon.svg";
function DumbbellTraineeLandPlan(){

    const history = useHistory()
    const location = useLocation()
    let {welcomeId,planId} = useParams()


    const [pageSpin,set_pageSpin] = useState(false)
    const [trainer,set_trainer] = useState(false)
    const [trainee,set_trainee] = useState({})
    const [plan,set_plan] = useState({})

    const [isExercisePrint,set_isExercisePrint] = useState(false)
    const [isDietPrint,set_isDietPrint] = useState(false)
    const [isSupplementPrint,set_isSupplementPrint] = useState(false)


    useEffect(()=>{
        if(welcomeId)
            autoLogin()
    },[])

    const printPro = (p)=>{
        let su = p.data.supplement.times.filter(t=>t.parts.length>0)
        let ex = p.data.exercise.times.filter(t=>t.parts.length>0)
        let di = p.data.diet.times.filter(t=>t.parts.length>0)

        set_isSupplementPrint(su.length>0)
        set_isExercisePrint(ex.length>0)
        set_isDietPrint(di.length>0)
    }

    const autoLogin = async ()=>{
        set_pageSpin(true)
        try{
            const res = await traineeWelcomeApi({welcomeId,planId})
            setTokenToLocalStorage(res.data.data.accessToken)
            setUserToLocalStorage(res.data.data.trainee)
            set_trainee(res.data.data.trainee)
            set_trainer(res.data.data.trainer)
            set_plan(res.data.data.plan)
            printPro(res.data.data.plan)
            set_pageSpin(false)
        }catch (e) {
            console.log(e);
            set_pageSpin(false)
        }
    }


    const login = ()=>{
        history.push(`/trainee/plan-list`)
    }

    const handleGoToPrint = (type)=>{
        if (type === "exercise")
            window.open(`/trainee/printable/${plan._id}`, '_blank');

        if (type === "diet")
            window.open(`/trainee/printable-diet/${plan._id}`, '_blank');

        if (type === "supplement")
            window.open(`/trainee/printable-supplement/${plan._id}`, '_blank');
    }

    const [visibleBottomDrawer,set_visibleBottomDrawer] = useState(false)

    const showBottomDrawer = ()=>{
        set_visibleBottomDrawer(!visibleBottomDrawer)
    }

    const onBottomDrawerClose = () => {
        set_visibleBottomDrawer(false);
    };

    return(

        <div>
            {trainer?.last_name?<div className="dumbbell-trainee-welcome">

                <div className="dumbbell-trainee-welcome-wrapper">


                    <div className="pl-header">
                        <div className="pl-trainer-sec">

                            <div className="pl-trainer-thumb">
                                {trainer.image?
                                    <img className="welcome-thumb" src={Setup.filePoint+trainer.image} alt=""/>:
                                    <img className="default-thumb" src={defaultUserIcon} alt=""/>
                                }
                            </div>

                            <div className="pl-trainer-info">
                                <span>{trainer.first_name+" "+trainer.last_name}</span>
                            </div>
                        </div>



                        <div className="pl-welcome-message">
                            <span>{trainee.first_name+" "+trainee.last_name} عزیز </span>
                            <p>{`برنامه ${plan.title} شما توسط مربی ${trainer.first_name+" "+trainer.last_name} تنظیم شده و آماده استفاده میباشد.  `}</p>
                        </div>

                        {/*<div className="pl-trainer-audio-description">*/}
                        {/*    <div className="pl-trainer-audio-btn clickable">*/}
                        {/*        <span>شنیدن توضیحات صوتی مربی</span>*/}
                        {/*        <img src={whiteSpeakerIcon} alt=""/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                    </div>

                    <div className="pl-digital-plan-sec ">
                        <p>با استفاده از لینک زیر میتونی وارد نسخه دیجیتال برنامه بشی و از کلی امکانات جدید استفاده کنی</p>
                        <button className="clickable" onClick={e=>{
                            history.push(`/dc/${planId}`)
                        }}>مشاهده نسخه دیجیتال برنامه</button>
                    </div>

                    <div className="pl-printable-plan-sec">
                        <p>با استفاده از لینک زیر میتونی برنامرو شبیه نسخه کاغذی ببینی و راحت ازش استفاده کنی و حتی پرینتش کنی</p>
                        <button className="clickable" onClick={showBottomDrawer}>مشاهده نسخه قابل پرینت برنامه</button>
                    </div>

                    <div className="pl-learn-plan-sec">
                        <p>با استفاده از لینک زیر میتونی آموزش تصویری برای استفاده از نسخه دیجیتال برنامه رو ببینی که خیلی بهت کمک میکنه</p>
                        <button className="clickable">آموزش تصویری استفاده از نسخه دیجیتال</button>
                    </div>

                    <Drawer height={300}
                            bodyStyle={{padding:0,margin:0,backgroundColor:"#8A86FE",borderRadius:"15px 15px 0 0"}}
                            mask={true}  closable={false}  placement="bottom" onClose={onBottomDrawerClose} visible={visibleBottomDrawer}>

                        <div className="bottom-drawer-handle">
                            <span></span>
                        </div>
                        <div className="bottom-drawer-body">
                            {isExercisePrint?
                                <div className="bottom-drawer-item clickable" onClick={e=>handleGoToPrint("exercise")} >
                                    <span className="label">مشاهده برنامه تمرینی</span>
                                </div>:null
                            }
                            {isDietPrint?
                                <div className="bottom-drawer-item clickable" onClick={e=>handleGoToPrint("diet")} >
                                    <span className="label">مشاهده برنامه غذایی</span>
                                </div>:null}
                            {isSupplementPrint?
                                <div className="bottom-drawer-item clickable"  onClick={e=>handleGoToPrint("supplement")}>
                                    <span className="label">مشاهده برنامه مکملی</span>
                                </div>:null}
                        </div>
                    </Drawer>


                </div>

            </div>:null}

            </div>

    )
}

export default DumbbellTraineeLandPlan;
