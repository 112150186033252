import React,{useEffect,useState} from "react"
import {getAdminTrainers_api} from "../../Services/Services";
import searchIcon from "../../assets/dumbbell_image/V2/search_icon_v2.svg";
import caretDownIcon from "../../assets/dumbbell_image/V2/caret_down_icon.svg";
import DumbbellHeader from "../../Components/DumbbellHeder/DumbbellHeder";
import "./AdminTrainerList.scss"
import {Setup} from "../../Services/Setup";
import defaultUserIcon from "../../assets/images/default_user.png";
import moment from "moment-jalaali";
import plusWhiteIcon from "../../assets/dumbbell_image/plus-white-icon.png";
import DumbbellAddTrainee from "../DumbbellAddTrainee/DumbbellAddTrainee";
import {Drawer} from "antd";
import AdminTrainerAdd from "../AdminTrainerAdd/AdminTrainerAdd";
import AdminTrainerDetail from "../AdminTrainerDetail/AdminTrainerDetail";

let search = ""
let year = "1403"
let month = "07"

function AdminTrainerList(){

    const [data,set_date] = useState([])
    const [monthInput,set_monthInput] = useState("")
    const [yearInput,set_yearInput] = useState("")
    const [searchInput,set_searchInput] = useState("")




    const setCurrentDate = e=>{
        let nowYear = moment(new Date()).format('jYYYY')
        let nowMonth = moment(new Date()).format('jMM')
        month=parseInt(nowMonth).toString()
        year=parseInt(nowYear).toString()
        set_yearInput(year)
        set_monthInput(month)
    }

    useEffect(()=>{
        setCurrentDate()
        getAdminTrainers()
    },[])

    const getAdminTrainers = async()=>{
        let obj = {
            shamsi_month:month,
            shamsi_year:year,
            search:search,
        }
        try{
            const result = await getAdminTrainers_api(obj)
            set_date(result.data)
        }catch (e) {
            console.log(e)
        }
    }


    const handleSearch = (word)=>{
        set_searchInput(word)
        if(word.length>2){
            search = word;
            getAdminTrainers()
        }
        if(word.length===0){
            search = "";
            getAdminTrainers()
        }
    }

    const handleChangeMonth = (m)=>{
        set_monthInput(m)
        month = m;
        getAdminTrainers()
    }
    const handleChangeYear = (y)=>{
        set_yearInput(y)
        year = y;
        getAdminTrainers()
    }


    //Add Trainer
    const [trainerFormModal,set_trainerFormModal] = useState(false)
    const showTrainerForm = ()=>{
        set_trainerFormModal(true)
    }
    const closeTrainerForm = ()=>{
        set_trainerFormModal(false)
    }
    const handleDone = ()=>{
        set_trainerFormModal(false)
        getAdminTrainers()
    }

    //Detail & Edit Trainer

    const [selectedTrainer,set_selectedTrainer] = useState(null)
    const [trainerDetailFormModal,set_trainerDetailFormModal] = useState(false)
    const showTrainerDetailForm = ()=>{
        set_trainerDetailFormModal(true)
    }
    const closeTrainerDetailForm = ()=>{
        set_trainerDetailFormModal(false)
    }
    const handleDetailDone = ()=>{
        set_trainerDetailFormModal(false)
        getAdminTrainers()
    }

    const handleSelectTrainer = (t)=>{
        set_selectedTrainer(t)
        console.log("Trainer selected : ",t)
        showTrainerDetailForm()
    }

    return(
        <div className="admin-trainer-container">

            <DumbbellHeader title={"لیست مربی ها"}  />



            <div className="admin-trainer-body">
                <div className="search-row">
                    <img src={searchIcon} alt=""/>
                    <input
                        value={searchInput}
                        onChange={e=>handleSearch(e.target.value)}
                        type="text" name="" id="" placeholder="جستجو با موبایل یا نام"/>
                </div>


                <div className="admin-filters">
                    <div className="admin-filter-item">
                        <select
                            value={monthInput}
                            onChange={e=>{
                                handleChangeMonth(e.target.value)
                            }}
                            name="" id="">
                            <option value="1">فروردین</option>
                            <option value="2">اردیبهشت</option>
                            <option value="3">خرداد</option>
                            <option value="4">تیر</option>
                            <option value="5">مرداد</option>
                            <option value="6">شهریور</option>
                            <option value="7">مهر</option>
                            <option value="8">آبان</option>
                            <option value="9">آذر</option>
                            <option value="10">دی</option>
                            <option value="11">بهمن</option>
                            <option value="12">اسفند</option>
                            <option value="all">همه</option>
                        </select>
                    </div>

                    <div className="admin-filter-item">
                        <select
                            value={yearInput}
                            onChange={e=>{
                                handleChangeYear(e.target.value)
                            }}
                            name="" id="">
                            <option value="1403">1403</option>
                            <option value="1404">1404</option>
                            <option value="1405">1405</option>
                            <option value="1406">1406</option>
                            <option value="1407">1407</option>
                            <option value="1408">1408</option>
                            <option value="1409">1409</option>
                            <option value="1410">1410</option>
                            <option value="1411">1411</option>
                            <option value="1412">1412</option>
                            <option value="1413">1413</option>
                            <option value="1414">1414</option>
                            <option value="all">همه</option>
                        </select>
                    </div>

                </div>

                <div className="admin-trainer-list">
                    {data.map(trainer=>{
                        return(
                            <div className="admin-trainer-card" onClick={e=>{
                                handleSelectTrainer(trainer)
                            }}>

                                <div className="admin-trainer-card-status">
                                    <span className="admin-trainer-card-average">{trainer.average.toFixed(3)}</span>
                                    <span className={`admin-trainer-card-debt ${trainer.debt>0?'red-bullet':'green-bullet'}`}></span>
                                </div>


                                <div className="admin-trainer-card-info">

                                    <div className="admin-trainer-card-labels">
                                        <div className="admin-trainer-card-names">
                                            <span>{trainer.first_name+" "+trainer.last_name}</span>
                                        </div>
                                        <div className="admin-trainer-card-mobile">
                                            <span>{trainer.mobile}</span>
                                        </div>
                                        <div className="admin-trainer-card-end-date">
                                            <span>{moment(trainer.credit_endDate).format("jYYYY/jMM/jDD")}</span>
                                        </div>
                                    </div>

                                    <div className="admin-trainer-card-image">
                                        {trainer.image?<img className="trainer-image" src={Setup.filePoint+trainer.image} alt=""/>
                                            :
                                            <img src={defaultUserIcon} alt=""/>
                                        }
                                    </div>
                                </div>

                            </div>
                        )
                    })}
                </div>


                <div className="float-add-btn-v2 clickable float-add-btn-admin"  onClick={showTrainerForm}>
                    {/*<span>شاگرد جدید</span>*/}
                    <img src={plusWhiteIcon} alt=""/>
                </div>

            </div>


            <Drawer bodyStyle={{padding:0,margin:0}}  width={"85vw"} closable={false}  onClose={closeTrainerForm} visible={trainerFormModal}>
                <AdminTrainerAdd handleDone={handleDone}/>
            </Drawer>

            <Drawer bodyStyle={{padding:0,margin:0}} destroyOnClose={true}  width={"85vw"} closable={false}  onClose={closeTrainerDetailForm} visible={trainerDetailFormModal}>
                <AdminTrainerDetail trainerId={selectedTrainer?._id} handleDone={handleDetailDone}/>
            </Drawer>
        </div>
    )
}

export default AdminTrainerList;
