import React, {useEffect, useRef, useState} from 'react';
import "./DumbbellTrainee.scss";
import DumbbellHeader from "../../Components/DumbbellHeder/DumbbellHeder";
import searchIcon from "../../assets/dumbbell_image/search-icon.png"
import messageIcon from "../../assets/dumbbell_image/V2/chat_icon.svg"
import picIcon from "../../assets/dumbbell_image/V2/pic_icon.svg"
import profileIcon from "../../assets/dumbbell_image/V2/profile_icon.svg"
import defaultUserIcon from "../../assets/images/default_user.png"
import traineeFaceIcon from "../../assets/dumbbell_image/sample-trainee-face.png";
import loadingImage from "../../assets/loading/load_2.gif"
import deleteIcon from "../../assets/card_icon/delete_icon.svg";
import plusWhiteIcon from "../../assets/dumbbell_image/plus-white-icon.png"
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import useWindowDimensions from "../../Hook/useWindowDimensions";
import {Drawer} from "antd";
import DumbbellAddTrainee from "../DumbbellAddTrainee/DumbbellAddTrainee";
import DumbbellChatTrainer from "../DumbbellChatTrainer/DumbbellChatTrainer";
import {useHistory, useLocation, useParams} from "react-router";
import {
    deleteTraineeApi,
    getMediasApi, getUnreadMessage_api,
    mediaChallengeDeleteApi, mediaDeleteApi,
    mediaRedDeleteApi,
    seenMediaApi,
    traineeListApi
} from "../../Services/Services";
import defaultUser from "../../assets/images/default_user.png";
import useOnScreen from "../../Components/useOnScreen/useOnScreen";
import DumbbellBoxTrainer from "../DumbbellBoxTrainer/DumbbellBoxTrainer";

import { CodeSandboxOutlined ,UserOutlined} from '@ant-design/icons';
import moment from "moment-jalaali";
import {Setup} from "../../Services/Setup";
import {useDispatchError} from "../../Hook/useDispatchError";
import useLongPress from "../../Hook/useLongPress";
import emptyIcon from "../../assets/dumbbell_image/V2/empty_icon_v2.svg";
import loadingGif from "../../assets/dumbbell_image/V2/page_spinner_v2.gif";
import caretDownIcon from "../../assets/dumbbell_image/V2/caret_down_icon.svg";

let page = 0;
let search = "";
let triggerID = "";
let type = "all";

const DeletableCardComp = ({children,item,removeFromList})=>{

    const [deleteStatus,set_deleteStatus] = useState(false)
    const errorDispatch = useDispatchError()

    const onLongPress = () => {
        set_deleteStatus(true)
    };
    const onClick = () => {

    }

    const defaultOptions = {
        shouldPreventDefault: false,
        delay: 1000,
    };

    const [spin,set_spin] = useState(false)

    const deleteCard= async ()=>{
        set_spin(true)
       console.log("delete...",item)
        try {
            const res = await deleteTraineeApi(item._id)
            removeFromList(item)
            set_spin(false)
        }catch (e) {
            set_spin(false)
            console.log(e)
        }
    }

    const cancelDeleteCard= ()=>{
        set_deleteStatus(false)
    }

    const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

    return(
        <div className="deletable-comp" {...longPressEvent}>
            {deleteStatus?
                <div className="deletable-comp-body">
                    {!spin?<div className="delete-mode">
                            <div className="delete-mode-title">
                                {/*<img src={deleteIcon} alt=""/>*/}
                                <span>آیا از حذف این شاگرد مطمئن هستید ؟</span>
                            </div>
                            <div className="delete-mode-btn">
                                <span onClick={deleteCard} className="delete-mode-btn-yes">بله</span>
                                <span onClick={cancelDeleteCard} className="delete-mode-btn-no">خیر</span>
                            </div>
                        </div>:
                        <div className="delete-mode-spin">
                            <img src={loadingImage} alt=""/>
                            <span>STEP</span>
                        </div>
                    }
                </div>
                :null}
            {children}
        </div>
    )
}



function TraineeCard({trainee,getPaginationTraineeList,removeFromList}){
    const ref = useRef()
    const isVisible = useOnScreen(ref)
    const history = useHistory()

    const [pageSeen,set_pageSeen] = useState(false);

    //Profile Trainee Drawer
    const [visibleProfileTraineeDrawer,set_visibleProfileTraineeDrawer] = useState(false)

    const handleDoneAddTrainee =()=>{
        set_visibleProfileTraineeDrawer(false);
    }

    const showProfileDrawer = ()=>{
        set_visibleProfileTraineeDrawer(!visibleProfileTraineeDrawer)
    }

    const onProfileDrawerClose = () => {
        set_visibleProfileTraineeDrawer(false);
    };


    //Chat Trainee Drawer
    const [visibleChatTraineeDrawer,set_visibleChatTraineeDrawer] = useState(false)

    const showChatDrawer = ()=>{
        set_visibleChatTraineeDrawer(!visibleChatTraineeDrawer)
    }

    const onChatDrawerClose = () => {
        set_visibleChatTraineeDrawer(false);
    };



    //Chat Trainee Drawer
    const [visibleBoxTraineeDrawer,set_visibleBoxTraineeDrawer] = useState(false)

    const showBoxDrawer = ()=>{
        set_visibleBoxTraineeDrawer(!visibleBoxTraineeDrawer)
    }

    const onBoxDrawerClose = () => {
        set_visibleBoxTraineeDrawer(false);
    };

    useEffect(()=>{
        if(isVisible && !pageSeen && triggerID===trainee._id){
            console.log(trainee._id,"is vis",isVisible)
            set_pageSeen(true)
            page = page+1
            getPaginationTraineeList()
        }
    },[isVisible])

    const leftColor = (date)=>{
        let now_date = new Date().getTime()
        let del_date=  new Date(date).getTime()
        let con= moment(del_date).format('jYYYY/jMM/jDD');
        let diff = del_date-now_date
        const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
        console.log(diffDays)

        if(diffDays>=4){
            return "#0f9b8e" //green
        }

        if(diffDays>=2 && diffDays<4){
            return "orange" //green
        }

        if(diffDays<2){
            return "#f36e6e" //green
        }
    }
    const leftDay = (date)=>{
        let now_date = new Date().getTime()
        let del_date=  new Date(date).getTime()
        let con= moment(del_date).format('jYYYY/jMM/jDD');
        let diff = del_date-now_date
        const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
        if(diffDays>1)
        return ` ${diffDays}  روز به تحویل `
        else
            return `از تحویل گذشته`
    }


    return(
        <DeletableCardComp item={trainee}  removeFromList={removeFromList}>
            <div className="dumbbell-trainee-card" ref={ref}>


                <div className="trainee-card-row clickable" onClick={e=>{
                    history.push(`/trainer/plan-list/${trainee._id}`)
                }}>

                    <div className="trainee-card-left-time">

                    </div>

                    <div className="dumbbell-trainee-card-trainee" >
                        {/*{trainee.delivery_date && parseInt(moment(trainee.delivery_date).format('jYYYY'))<1500?*/}
                        {/*    <div className="dumbbell-trainee-card-trainee-delivery" style={{backgroundColor:leftColor(trainee.delivery_date)}}>*/}
                        {/*        <span> {leftDay(trainee.delivery_date)}  </span>*/}
                        {/*    </div>:null*/}
                        {/*}*/}

                        <div className="dumbbell-trainee-card-trainee-info" >
                            <span className="dumbbell-trainee-card-trainee-info-name-v2">{trainee.first_name+" "+trainee.last_name}</span>
                            <span className="dumbbell-trainee-card-trainee-info-mobile-v2">{trainee.mobile}</span>
                            {/*<span className="dumbbell-trainee-card-trainee-info-mobile alert-message">منتظر دریافت برنامه</span>*/}
                        </div>
                        <div className="dumbbell-trainee-card-trainee-thumb" onClick={e=>{
                            history.push(`/trainer/plan-list/${trainee._id}`)
                        }}>
                            {trainee.image?<img className="trainee-image" src={Setup.filePoint+trainee.image} alt=""/>
                            :
                                    <img src={defaultUserIcon} alt=""/>
                            }
                        </div>
                    </div>



                </div>


                <div className="trainee-card-row">
                    <div className="dumbbell-trainee-card-actions">
                        <div className="dumbbell-trainee-card-box-v2" onClick={e=>{
                            showChatDrawer()
                        }}>
                            <img src={messageIcon} alt=""/>
                            {trainee && trainee.unread?<span className="dumbbell-trainee-card-message-badge">{trainee?.unread}</span>:null}
                        </div>

                        <div className="dumbbell-trainee-card-box-v2" onClick={e=>{
                            showBoxDrawer()
                        }}>
                            <img src={picIcon} alt=""/>
                        </div>

                        <div className="dumbbell-trainee-card-box-v2" onClick={e=>{
                            showProfileDrawer()
                        }}>
                            <img src={profileIcon} alt=""/>
                        </div>
                    </div>


                    <div className="trainee-card-last-visit">
                        <span></span>
                    </div>

                </div>






                {/*Box Trainee*/}
                <Drawer push={false}  bodyStyle={{padding:0,margin:0}}  width={300} closable={false}  onClose={onBoxDrawerClose} visible={visibleBoxTraineeDrawer}>
                    {visibleBoxTraineeDrawer?<DumbbellBoxTrainer visibleBoxTraineeDrawer={visibleBoxTraineeDrawer} trainee={trainee} />:null}
                </Drawer>

                <Drawer  bodyStyle={{padding:0,margin:0}}  width={300} closable={false}  onClose={onChatDrawerClose} visible={visibleChatTraineeDrawer}>
                    {visibleChatTraineeDrawer?<DumbbellChatTrainer visibleChatTraineeDrawer={visibleChatTraineeDrawer} trainee={trainee} />:null}
                </Drawer>


                <Drawer bodyStyle={{padding:0,margin:0}}  width={300} closable={false}  onClose={onProfileDrawerClose} visible={visibleProfileTraineeDrawer}>
                    <DumbbellAddTrainee fill={trainee} traineeMode={false} handleDoneAddTrainee={handleDoneAddTrainee}/>
                </Drawer>

            </div>
        </DeletableCardComp>


    )
}


function DumbbellTrainee(){
    // useEffect(()=>{
    //     document.body.style.backgroundColor = "#8A86FE";
    // },[])

    const location = useLocation()
    const history = useHistory()
    //trainees

    const [pageSpin,set_pageSpin] = useState(true)
    const [my,set_my] = useState(null)
    const [total_unreadMessage,set_total_unreadMessage] = useState(0)

    useEffect(()=>{
        set_currentType("all")
        type="all"
        if(location?.state?.filter){
            history.replace({ state: {} })
            console.log("location : ",location?.state?.filter)
            set_currentType(location?.state?.filter)
            type=location?.state?.filter
        }
        getLocalUser()
        getTraineeList()
        getUnreadMessage()
    },[])

    const [currentType,set_currentType] = useState("all")
    const [visibleBottomDrawer,set_visibleBottomDrawer] = useState(false)

    const handleChangeType = (t)=>{
        set_currentType(t)
        onBottomDrawerClose()
        type=t
        page = 0;
        getTraineeList()
    }

    const showBottomDrawer = ()=>{
        set_visibleBottomDrawer(!visibleBottomDrawer)
    }

    const onBottomDrawerClose = () => {
        set_visibleBottomDrawer(false);
    };


    const getUnreadMessage = async()=>{
        try{
            const resp = await getUnreadMessage_api()
            set_total_unreadMessage(resp.data.data)
        }catch (e) {
            console.log(e)
        }
    }

    const getLocalUser = ()=>{
        let user = localStorage.getItem('user')
        set_my(JSON.parse(user))
    }



    const [searchInput,set_searchInput] = useState("")



    //Add Trainee Drawer
    const [visibleTraineeDrawer,set_visibleTraineeDrawer] = useState(false)

    const showMenu = ()=>{
        set_visibleTraineeDrawer(!visibleTraineeDrawer)
    }

    const onMenuClose = () => {
        set_visibleTraineeDrawer(false);
    };

    const [message, setMessage] = useState('')
    const [trainees, set_trainees] = useState([])
    const [totalCount, set_totalCount] = useState(0)
    const commands = [
        {
            command: ['تمرین', 'استراحت'],
            callback: ({ command }) => setMessage(`شما گفتید : "${command}"`),
            matchInterim: true
        },
        ]

    // const {
    //     transcript,
    //     listening,
    //     resetTranscript,
    //     browserSupportsSpeechRecognition,
    //     browserSupportsContinuousListening
    // } = useSpeechRecognition({ commands });

    // useEffect(()=>{
    //     console.log("transcript : ",transcript)
    //     let sp = transcript.split(" ")
    //     console.log("transcript : ",sp.length)
    //     if(sp.length>3)
    //         resetTranscript()
    // },[transcript])


    // if (!browserSupportsSpeechRecognition) {
    //     return <span>Browser doesn't support speech recognition.</span>;
    // }
    //
    //
    // if (!browserSupportsContinuousListening) {
    //     return <span>Browser doesn't support speech recognition.</span>;
    // }






    const getTraineeList = async ()=>{
        set_pageSpin(true)
        set_trainees([])
        console.log("trace 1 :",type)
        try{
            const res = await traineeListApi(0,search,type)
            set_pageSpin(false)
            let trainee = res.data.data.trainee
            let count = res.data.data.count
            triggerID = trainee.length>95?trainee[95]._id:""
            set_trainees(trainee)
            set_totalCount(count)
        }catch(e){
            set_pageSpin(false)
            console.log(e)
        }
    }
    const getPaginationTraineeList = async ()=>{
        try{
            const res = await traineeListApi(page,search,type)
            let trainee = res.data.data.trainee
            let count = res.data.data.count
            triggerID = trainee.length>95?trainee[95]._id:""
            set_trainees(oldArray => [...oldArray, ...trainee])
            set_totalCount(count)
        }catch(e){
            console.log(e)
        }
    }


    const handleSearch = (word)=>{
        set_searchInput(word)
        if(word.length>2){
            page = 0;
            search = word;
            getTraineeList()
        }
        if(word.length===0){
            page = 0;
            search = "";
            getTraineeList()
        }
    }


    const handleDoneAddTrainee =()=>{
        set_visibleTraineeDrawer(false);
        getTraineeList()
    }

    const removeFromList = (item)=>{
        let t = trainees.filter(t=>t._id!==item._id)
        set_trainees([])
        set_trainees(t)
    }
    const handleChangeFilter=(page,t)=>{
        if(page==="trainee"){

        }
    }



    const convertTypeName = (t)=>{
        switch (t) {
            case 'all':
                return 'همه'
                break;
            case 'late':
                return 'تاریخ تحویل گذشته ها'
                break;
            case 'unSeen':
                return 'پیامهای خوانده نشده'
                break;
        }
    }


    return(
        <div className="dumbbell-trainee-container">

            <div className="fix-top-sec-v2">
                <div className="fix-content">


                    <DumbbellHeader title={"شاگردان من"} changeFilter={handleChangeFilter} />
                    <div className="my-trainee-page">

                        <div className="search-row">
                            <img src={searchIcon} alt=""/>
                            <input
                                value={searchInput}
                                onChange={e=>handleSearch(e.target.value)}
                                type="text" name="" id="" placeholder="جستجو با موبایل یا نام"/>
                        </div>
                    </div>

                    <div className="trainee-filters">
                        <div className="filter-row">
                            <div className="filter-item clickable" onClick={showBottomDrawer}>
                                <img src={caretDownIcon} alt=""/>
                                <span>{convertTypeName(currentType)}</span>
                            </div>
                        </div>
                    </div>


                    {/*<div className="dumbbell-trainee-total">*/}
                    {/*    {total_unreadMessage>0?<div className="dumbbell-trainee-unread-message">*/}
                    {/*        <span className="message-badge">{total_unreadMessage}</span>*/}
                    {/*        <span className="message-label">پیام ها</span>*/}
                    {/*    </div>:<div></div>}*/}
                    {/*    <span className="dumbbell-trainee-total-sec">{totalCount} نفر</span>*/}
                    {/*</div>*/}

                </div>
            </div>

            <div className="dumbbell-trainee-list-trainee-v2">

                {/*Card*/}
                {trainees.map(t=>{
                    return(
                        <TraineeCard removeFromList={removeFromList} getPaginationTraineeList={getPaginationTraineeList} trainee={t}/>
                    )
                })}

                {trainees.length<1 && !pageSpin?
                    <div className="empty-state">
                        <img src={emptyIcon} alt=""/>
                        <span>هنوز شاگردی ثبت </span>
                        <span>نشده !</span>
                    </div>:null
                }

                {pageSpin?
                    <div className="loading-state">
                        <span style={{width:20,height:20,border:"1.5px solid #8d89fd", borderBottomColor: "transparent"}} className="loader"></span>
                    </div>:null
                }




            </div>


            {/*<div className="speech-sec">*/}
            {/*    <h1>تبدیل صدا به متن</h1>*/}
            {/*    <p>Microphone: {listening ? 'on' : 'off'}</p>*/}
            {/*    <button onClick={e=>{*/}
            {/*        SpeechRecognition.startListening({ continuous: true,language: 'fa-IR',matchInterim:true,isFuzzyMatch:true })*/}
            {/*    }}>Start</button>*/}
            {/*    <button onClick={SpeechRecognition.stopListening}>Stop</button>*/}
            {/*    <button onClick={resetTranscript}>Reset</button>*/}
            {/*    <h1>{message}</h1>*/}
            {/*    <p>{transcript}</p>*/}

            {/*</div>*/}




            <div className="float-add-btn-v2 clickable" onClick={showMenu}>
                {/*<span>شاگرد جدید</span>*/}
                <img src={plusWhiteIcon} alt=""/>
            </div>



            {/*Add New Trainee*/}
            <Drawer destroyOnClose={true}  bodyStyle={{padding:0,margin:0}}  width={300} closable={false}  onClose={onMenuClose} visible={visibleTraineeDrawer}>
                <DumbbellAddTrainee handleDoneAddTrainee={handleDoneAddTrainee} />
            </Drawer>


            <Drawer height={220}
                    bodyStyle={{padding:0,margin:0,backgroundColor:"#8A86FE",borderRadius:"15px 15px 0 0"}}
                    mask={true}  closable={false}  placement="bottom" onClose={onBottomDrawerClose} visible={visibleBottomDrawer}>

                <div className="bottom-drawer-handle">
                    <span></span>
                </div>
                <div className="bottom-drawer-body">
                    <div className="bottom-drawer-item clickable" onClick={e=>handleChangeType("all")} >
                        <span className="label">همه شاگردان</span>
                    </div>
                    {/*<div className="bottom-drawer-item clickable" onClick={e=>handleChangeType("late")} >*/}
                    {/*    <span className="label">تاریخ تحویل گذشته ها</span>*/}
                    {/*</div>*/}
                    <div className="bottom-drawer-item clickable"  onClick={e=>handleChangeType("unSeen")}>
                        <span className="label">پیامهای خوانده نشده</span>
                    </div>
                </div>
            </Drawer>

        </div>
    )
}



export default DumbbellTrainee;
