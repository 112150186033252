export function syncCart(dispatch) {
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    dispatch(cart);
}

export function incCart(dispatch, item) {
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    const itemIndex = cart.findIndex(cartItem => cartItem._id === item._id);

    if (itemIndex !== -1) {
        // اگر محصول وجود دارد، مقدار quantity را افزایش بده
        cart[itemIndex].quantity += 1;
    } else {
        // اگر محصول وجود ندارد، آن را به همراه quantity اولیه 1 اضافه کن
        cart.push({ ...item, quantity: 1 });
    }

    localStorage.setItem('cart', JSON.stringify(cart));
    dispatch(cart);
}

export function decCart(dispatch, itemId) {
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    const itemIndex = cart.findIndex(cartItem => cartItem._id === itemId);

    if (itemIndex !== -1) {
        if (cart[itemIndex].quantity > 1) {
            // اگر quantity بیشتر از 1 است، کم کن
            cart[itemIndex].quantity -= 1;
        } else {
            // اگر quantity 1 بود، محصول را حذف کن
            cart.splice(itemIndex, 1);
        }
    }

    localStorage.setItem('cart', JSON.stringify(cart));
    dispatch(cart);
}

export function clearCart(dispatch) {
    localStorage.removeItem('cart');
    dispatch([]);
}

export function getItemCount(cart, itemId) {
    const item = cart.find(cartItem => cartItem._id === itemId);
    return item ? item.quantity : 0; // اگر محصول پیدا شد، مقدار quantity برگردانده می‌شود، در غیر این صورت 0
}

export function getTotalItems(cart) {
    return cart.reduce((total, item) => total + item.quantity, 0); // جمع کل quantity تمام محصولات
}

export function getTotalPrice(cart) {
    return cart.reduce((total, item) => {
        return total + (item.price * item.quantity); // جمع قیمت کل محصولات ضربدر تعداد
    }, 0);
}

export function getTotalDiscount(cart) {
    return cart.reduce((total, item) => {
        return total + (item.discount * item.quantity); // جمع تخفیف کل محصولات ضربدر تعداد
    }, 0);
}

export function getPayableAmount(cart) {
    return cart.reduce((total, item) => {
        const priceAfterDiscount = item.price - item.discount; // قیمت پس از تخفیف
        return total + (priceAfterDiscount * item.quantity); // جمع مبلغ نهایی برای هر محصول
    }, 0);
}
