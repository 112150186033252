import React, {useEffect, useRef, useState} from 'react';
import "./DumbbellVideoUpload.scss";
import DumbbellDrawerHeader from "../../Components/DumbbellDrawerHeader/DumbbellDrawerHeader";
import playIcon from "../../assets/dumbbell_image/play-icon.png";
import ReactPlayer from 'react-player'
import { v4 as uuidv4 } from 'uuid'

// import sampleMov from "../../assets/video/sample_video.mov";
// import sampleMp4 from "../../assets/video/sample_video.mp4";
import EditIcon from "../../assets/dumbbell_image/V2/edit_icon.svg"
import {
    boxImageUploadApi, picEditApi,
    picUploadApi,
    videoEditApi,
    videoEditTitleApi,
    videoUploadApi
} from "../../Services/Services";
import {LoadingOutlined,CameraOutlined} from "@ant-design/icons"
import {message,Spin} from "antd";
import {checkFileSize} from "../../Services/utils";
import {useDispatchUploadLoading} from "../../Hook/useDispatchUploadLoading";
import {Setup} from "../../Services/Setup";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

let firstEdit = true
function DumbbellVideoUpload({edited=null,handleUploadDone}){

    const [imageFile,set_imageFile] = useState(null)
    const [picFile,set_picFile] = useState(null)
    const fileRef = useRef(null);
    const filePicRef = useRef(null);
    const [picPrev,set_picPrev] = useState(null);
    const [imagePrev,set_imagePrev] = useState(null);
    const [spin,set_spin] = useState(false);
    const [spinTip,set_spinTip] = useState("")
    const[uploadMode,set_uploadMode] = useState(false)
    const[title,set_title] = useState("")
    const[cat,set_cat] = useState("")
    const[videoRefreshId,set_videoRefreshId] = useState()
    const setUploadLoading = useDispatchUploadLoading()


    useEffect(()=>{
        set_videoRefreshId(uuidv4())
        if(edited){
             firstEdit = true
            console.log("Edited : ",Setup.filePoint+edited.image)
            set_title(edited.fa_names[0])
            if(edited.video)
            set_imagePrev(Setup.filePoint+edited.video)
            if(edited.image)
                set_picPrev(Setup.filePoint+edited.image)
        }
    },[])

    useEffect(() => {

        if(firstEdit){
            firstEdit  =false
            return
        }

        if (!imageFile) {
            set_imagePrev(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(imageFile)
        set_imagePrev(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [imageFile])

    const handleImageChange = (e)=>{
        let limit = 100
        if(e.target.files && e.target.files[0]){
            if(!checkFileSize(e.target.files[0].size,limit)){
                return message.error({
                    content: `حداکثر حجم فایل انتخابی باید ${limit} مگابایت باشد `,
                    className: 'toast-class'
                });
            }
            set_imageFile(e.target.files[0])
        }
    }

    const handleClickItems = (num)=>{
        fileRef.current.click();
    }


    //Pic ---------------------------------------
    useEffect(() => {
        console.log('')
        if(firstEdit){
            firstEdit  =false
            return
        }

        if (!picFile) {
            // set_picPrev(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(picFile)
        set_picPrev(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [picFile]);

    const handlePicChange = (e)=>{
        let limit = 5
        if(e.target.files && e.target.files[0]){
            if(!checkFileSize(e.target.files[0].size,limit)){
                return message.error({
                    content: `حداکثر حجم فایل انتخابی باید ${limit} مگابایت باشد `,
                    className: 'toast-class'
                });
            }
            set_picFile(e.target.files[0])
        }
    }

    const handleClickPicItems = (num)=>{
        filePicRef.current.click();
    }


    const [thumbUploadProgress,set_thumbUploadProgress] = useState(0)

    useEffect(()=>{
        if(thumbUploadProgress>0){
            if(thumbUploadProgress>80)
                setUploadLoading({visible:true,percent:thumbUploadProgress,title:"در حال فشرده سازی و ذخیره تصویر  . . ."})
            else
                setUploadLoading({visible:true,percent:thumbUploadProgress,title:"در حال آپلود تصویر  . . ."})
        }
    },[thumbUploadProgress])

    const upload = async ()=>{

        if(title.length<2){
            return message.error({
                content: "لطفا عنوان حرکت را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(!picFile && !imageFile){
            return message.error({
                content: "لطفا ویدیو و یا تصویر  را انتخاب نمایید",
                className: 'toast-class'
            });
        }


        let vId = uuidv4();
        set_spinTip("در حال آپلود...")
        set_spin(true)
        set_uploadMode(true)
        if(imageFile){
            try{

                await videoUploadApi(imageFile,title,cat,set_thumbUploadProgress,vId);
                setUploadLoading({visible:false,percent:0})
                set_thumbUploadProgress(0)
                set_spin(false)
                set_uploadMode(false)
                set_imagePrev(null)
                handleUploadDone()
                return message.success({
                    content: "ویدیو حرکت شما با موفقیت آپلود شد",
                    className: 'toast-class'
                });
            }catch(e){
                setUploadLoading({visible:false,percent:0})
                set_thumbUploadProgress(0)
                set_spin(false)
                set_uploadMode(false)
                console.log(e)
                return message.error({
                    content: "مشکل در آپلود ویدیو",
                    className: 'toast-class'
                });
            }
        }
        if(picFile){
            try{
                await picUploadApi(picFile,title,cat,set_thumbUploadProgress,vId);
                setUploadLoading({visible:false,percent:0})
                set_thumbUploadProgress(0)
                set_spin(false)
                set_uploadMode(false)
                set_imagePrev(null)
                handleUploadDone()
                return message.success({
                    content: "ویدیو حرکت شما با موفقیت آپلود شد",
                    className: 'toast-class'
                });
            }catch(e){
                setUploadLoading({visible:false,percent:0})
                set_thumbUploadProgress(0)
                set_spin(false)
                set_uploadMode(false)
                console.log(e)
                return message.error({
                    content: "مشکل در آپلود ویدیو",
                    className: 'toast-class'
                });
            }
        }

    }

    const editVideo = async ()=>{

        if(title.length<2){
            return message.error({
                content: "لطفا عنوان حرکت را وارد نمایید",
                className: 'toast-class'
            });
        }

        // if(!imageFile){
        //     return message.error({
        //         content: "لطفا ویدیو جدید را انتخاب نمایید",
        //         className: 'toast-class'
        //     });
        // }



        if(imageFile){
            set_spinTip("در حال آپلود...")
            set_spin(true)
            set_uploadMode(true)
            try{
                await videoEditApi(edited._id,edited.video,imageFile,title,cat,set_thumbUploadProgress);
                setUploadLoading({visible:false,percent:0})
                set_thumbUploadProgress(0)
                set_spin(false)
                set_uploadMode(false)
                set_imagePrev(null)
                handleUploadDone()
                set_videoRefreshId(uuidv4())
                return message.success({
                    content: "ویدیو حرکت شما با موفقیت آپلود شد",
                    className: 'toast-class'
                });
            }catch(e){
                setUploadLoading({visible:false,percent:0})
                set_thumbUploadProgress(0)
                set_spin(false)
                set_uploadMode(false)
                console.log(e)
                return message.error({
                    content: "مشکل در آپلود ویدیو",
                    className: 'toast-class'
                });
            }
        }

        if(picFile){
            set_spinTip("در حال آپلود...")
            set_spin(true)
            set_uploadMode(true)
            try{
                await picEditApi(edited._id,edited.image,picFile,title,cat,set_thumbUploadProgress);
                setUploadLoading({visible:false,percent:0})
                set_thumbUploadProgress(0)
                set_spin(false)
                set_uploadMode(false)
                set_imagePrev(null)
                handleUploadDone()
                set_videoRefreshId(uuidv4())
                return message.success({
                    content: "تصویر حرکت شما با موفقیت آپلود شد",
                    className: 'toast-class'
                });
            }catch(e){
                setUploadLoading({visible:false,percent:0})
                set_thumbUploadProgress(0)
                set_spin(false)
                set_uploadMode(false)
                console.log(e)
                return message.error({
                    content: "مشکل در آپلود تصویر",
                    className: 'toast-class'
                });
            }
        }



        if(!imageFile && !picFile){
              try{
                  set_spin(true)
                  await videoEditTitleApi(edited._id,title)
                  set_spin(false)
                  return message.success({
                      content: "تایتل ویدیو با موفقیت ویرایش شد",
                      className: 'toast-class'
                  });
              }catch(e){
                  set_spin(false)
                  console.log(e)
                  return message.error({
                      content: "مشکل در ویرایش ویدیو",
                      className: 'toast-class'
                  });
              }
          }



    }


    const uri = "https://media.starium-space.com/d69b6df3-e7ea-4450-aced-ad0470e3857a/sample_video.mov"

    return(
        <Spin indicator={antIcon}  spinning={spin}>
            <div>
                <DumbbellDrawerHeader title={"آپلود "}/>

                <div className="dumbbell-video-upload-wr">
                    {imagePrev?<div className="edit-video-icon clickable" onClick={handleClickItems}>
                            <img src={EditIcon} alt=""/>
                    </div>:null}
                    {!imagePrev?<div className="dumbbell-video-upload-sec clickable" onClick={e=>{
                        handleClickItems()
                    }}>
                        <img   src={playIcon} alt=""/>
                    </div>:null}
                    {imagePrev && imagePrev.startsWith('https')?
                        <ReactPlayer width={"100%"} height={"auto"}  url={imagePrev+`?id=${videoRefreshId}`} controls />:
                        null
                    }
                    {imagePrev && !imagePrev.startsWith('https')?
                        <ReactPlayer width={"100%"} height={"auto"}  url={imagePrev} controls />:
                        null
                    }

                    <div className="hint-message">
                        <span>حجم هر ویدیو ارسالی نباید بیشتر از 100 مگابایت باشد</span>
                    </div>
                </div>




                <div className="dumbbell-video-upload-wr">
                    {picPrev?<div className="edit-video-icon clickable" onClick={handleClickPicItems}>
                        <img src={EditIcon} alt=""/>
                    </div>:null}
                    {!picPrev?<div className="dumbbell-video-upload-sec clickable" onClick={e=>{
                        handleClickPicItems()
                    }}>
                        <CameraOutlined style={{
                            fontSize: 25,
                            color: '#6b6969'
                        }} />
                        {/*<img   src={playIcon} alt=""/>*/}
                    </div>:null}
                    <div className="image-upload-section">
                        {picPrev && picPrev.startsWith('https')?
                            <img className="pre-upload-image" src={picPrev+`?id=${videoRefreshId}`} alt=""/>:
                            null
                        }
                        {picPrev && !picPrev.startsWith('https') ?
                            <img className="pre-upload-image" src={picPrev} alt=""/>:
                            null
                        }
                    </div>

                    <div className="hint-message">
                        <span>حجم هر تصویر ارسالی نباید بیشتر از 5 مگابایت باشد</span>
                    </div>
                </div>


                <div className="dumbbell-video-upload-title">
                    <input
                        value={title}
                        onChange={e=>set_title(e.target.value)}
                        type="text" name="" id="" placeholder="عنوان حرکت"/>
                </div>

                {edited?
                    <div className="dumbbell-video-upload-btn" onClick={
                        e=>editVideo()
                    }>
                        <div>ویرایش </div>
                    </div>:
                    <div className="dumbbell-video-upload-btn" onClick={
                        e=>upload()
                    }>
                        <div>آپلود </div>
                    </div>
                }

                <input type="file" ref={fileRef} name="" accept="video/mp4,video/x-m4v,video/*" style={{visibility: "hidden"}} onChange={handleImageChange} id=""/>
                <input type="file" ref={filePicRef} name=""  style={{visibility: "hidden"}} onChange={handlePicChange} id=""/>
            </div>
        </Spin>
    )
}

export default DumbbellVideoUpload;
