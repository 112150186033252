import React, {useEffect, useState, useRef, Fragment} from "react"
import "./PageV2.scss"
import "../PageEditV2/PageEditV2.scss"
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import {Setup} from "../../Services/Setup";
import {LoadingOutlined,PictureOutlined} from "@ant-design/icons"
import ReactPlayer from "react-player";
import EditIcon from "../../assets/dumbbell_image/V2/edit_icon.svg"
import calendarIcon from "../../assets/dumbbell_image/V2/calendar_icon_v2.svg"
import halterIcon from "../../assets/dumbbell_image/V2/halter_icon_v2.svg"
import inActivePageIcon from "../../assets/dumbbell_image/V2/inactive_page.svg"
import followupIcon from "../../assets/dumbbell_image/V2/followup_icon.svg"
import defaultIcon from "../../assets/dumbbell_image/V2/default_user_icon_v2.svg"
import locationIcon from "../../assets/dumbbell_image/V2/location_icon.svg"
import { v4 as uuidv4 } from 'uuid'

import youTubeImage from "../../assets/dumbbell_image/V2/social_youtube.svg"
import youTubeImageOff from "../../assets/dumbbell_image/V2/social_youTube_off.svg"
import telegramImage from "../../assets/dumbbell_image/V2/social_telegram.svg"
import telegramImageOff from "../../assets/dumbbell_image/V2/social_telegram_off.svg"
import instagramImage from "../../assets/dumbbell_image/V2/social_instagram.svg"
import instagramImageOff from "../../assets/dumbbell_image/V2/social_instagram_off.svg"
import whatsAppImage from "../../assets/dumbbell_image/V2/social_whatsApp.svg"
import whatsAppImageOff from "../../assets/dumbbell_image/V2/social_whatsApp_off.svg"
import plusProductIcon from "./assets/plusProductIcon.svg"

import Swiper from "react-id-swiper";
import {useParams} from "react-router";
import {
    deletePackage_api,
    getLandApi,
    getLandPackage_api,
    getProductsApi, getProductsRegalApi, getServices_land_api,
    hasCredit_api
} from "../../Services/Services";
import DumbbellBookingLand from "../DumbbellBookingLand/DumbbellBookingLand";
import {Drawer, message, Spin} from "antd";
import DumbbellLandingPageAddTraineeDrawer
    from "../DumbbellLandingPageAddTraineeDrawer/DumbbellLandingPageAddTraineeDrawer";
import trashWhiteIcon from "../../assets/dumbbell_image/trash_white_icon.png";
import {numberWithCommas} from "../../Services/utils";
import {useDispatchLoading} from "../../Hook/useDispatchLoading";
import OnlineShop from "../OnlineShop/OnlineShop";
import emptyIcon from "../../assets/dumbbell_image/V2/empty_icon_v2.svg";

Array.prototype.contains = function(obj) {
    let i = this.length;
    while (i--) {
        if (this[i] === obj) {
            return true;
        }
    }
    return false;
}

function PackageCard({item,slug,page}){
    const [cardSpin,set_cardSpin] = useState(false)

    //plans drawer
    const [visibleTraineeDrawer,set_visibleTraineeDrawer] = useState(false)

    const showPayDrawer = (p)=>{
        set_visibleTraineeDrawer(true)
    }

    const onPayDrawerClose = () => {
        set_visibleTraineeDrawer(false);
    };

    return(
        <Fragment>
            <Spin spinning={cardSpin}>
                <div className="package-card" style={{margin:"10px 12px"}}>
                    <div className="package-card-header">
                        <h6>{item.title}</h6>
                    </div>
                    <div className="package-card-des">
                        {item.descriptions.map(d=>{
                            return(
                                <div className="package-card-des-item">
                                    <div className="package-card-des-item-label">
                                        <span>{d.text}</span>
                                    </div>
                                    <div className="package-card-des-item-bullet"></div>
                                </div>
                            )
                        })}

                        {item.delivered_type==="instant"?
                            <div className="package-card-des-item">
                                <div className="package-card-des-item-label">
                                    <span>{`تحویل پکیج بطور آنی بعد از پرداخت`}</span>
                                </div>
                                <div className="package-card-des-item-bullet"></div>
                            </div>:null
                        }

                        {item.delivered_type==="default"?
                            <div className="package-card-des-item">
                                <div className="package-card-des-item-label">
                                    <span>{`حداکثر زمان آماده سازی ${item.delivery_estimate} روز میباشد`}</span>
                                </div>
                                <div className="package-card-des-item-bullet"></div>
                            </div>:null
                        }

                        <div className="package-card-des-item">
                            <div className="package-card-des-item-label">
                                <span>{`طول مدت پکیج ${item.duration} روز میباشد`}</span>
                            </div>
                            <div className="package-card-des-item-bullet"></div>
                        </div>

                        {item.chat_option?
                            <div className="package-card-des-item">
                                <div className="package-card-des-item-label">
                                    <span>قابلیت ارتباط با مربی از طریق چت</span>
                                </div>
                                <div className="package-card-des-item-bullet"></div>
                            </div>:null
                        }

                        {item.box_option?
                            <div className="package-card-des-item">
                                <div className="package-card-des-item-label">
                                    <span>قابلیت تبادل عکس با مربی از طریق چت</span>
                                </div>
                                <div className="package-card-des-item-bullet"></div>
                            </div>:null
                        }



                    </div>

                    <div className="package-card-price">

                        <span className="package-card-pay_amount">{numberWithCommas(item.payable_amount)}تومان </span>
                        {item.amount-item.payable_amount>0?
                            <span className="package-card-amount">{numberWithCommas(item.amount)}تومان </span>:
                            null
                        }
                        <span className="package-card-extra_label">{item.extra_label} </span>
                    </div>

                    <div className="package-card-btn clickable" onClick={e=>{
                        showPayDrawer()
                    }}>
                        <span>گرفتن پکیج</span>
                    </div>

                </div>
            </Spin>
            <Drawer bodyStyle={{padding:0,margin:0}}  width={300} closable={false}  onClose={onPayDrawerClose} visible={visibleTraineeDrawer}>
                <DumbbellLandingPageAddTraineeDrawer plan={item} slug={slug} page={page} trainerName={page.full_name} handleDoneAddTrainee={e=>{
                    console.log("done")
                }} />
            </Drawer>
        </Fragment>
    )
}


function PageV2(){

    const {slug} = useParams()
    const [video,set_video] = useState("")
    const [socials,set_socials] = useState([])
    const [mute,set_mute] = useState(false)
    const socialRef = useRef(null);

    const MyRef = useRef(null);

    const [full_name,set_full_name] = useState("")
    const [short_description,set_short_description] = useState("")
    const [descriptions,set_descriptions] = useState([])
    const [image,set_image] = useState("")
    const [address,set_address] = useState([])
    const [phones,set_phones] = useState([])
    const [email,set_email] = useState("")

    const [telegram,set_telegram] = useState("")
    const [instagram,set_instagram] = useState("")
    const [whatsApp,set_whatsApp] = useState("")
    const [youtube,set_youtube] = useState("")

    const [uniqId,set_uniqId] = useState(uuidv4())



    const params = {
        slidesPerView: '2.5',
        spaceBetween: 10,
        // rebuildOnUpdate : true,
        rtl:true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        }
    }

    const paramsLink = {
        slidesPerView: '3.7',
        spaceBetween: 2,
        // rebuildOnUpdate : true,
        rtl:true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        }
    }


    function showMap(lat,lng){
        var url = "https://maps.google.com/?q=" + lat + "," + lng;
        window.open(url);
    }
    const goToMap = ()=>{{
        let ua = navigator.userAgent.toLowerCase();
        console.log("UA : ",ua)
        showMap(location.lat,location.lng)
        // let isAndroid = ua.indexOf("android") > -1;
        // if(isAndroid) {
        //     window.location = 'geo:50.060915,19.948066';
        // }
        // else {
        //     window.location = 'http://maps.apple.com/?ll=50.060915,19.948066';
        // }
    }}

    useEffect(()=>{
        getLand()
    },[])

    const [credit,set_credit]  = useState(null)
    const hasCredit=async (tId)=>{
        try{
            const response = await hasCredit_api(tId)
            set_credit(response.data.data)
            if(response.data.data.plan)
                getPackage()
        }catch (e) {
            console.log(e)
        }
    }

    const [packages,set_packages] = useState([])
    const getPackage =async ()=>{
        set_spin(true)
        try{
            const resp = await getLandPackage_api(slug)
            set_packages(resp.data.data)
            set_spin(false)
        }catch (e) {
            set_spin(false)
            console.log(e)
        }
    }

    const setLoader = useDispatchLoading()
    const [pageNotFound,set_pageNotFound] = useState("default")

    const [spin,set_spin] = useState(false);
    const [spinTip,set_spinTip] = useState("");

    useEffect(()=>{
        setLoader(spin)
    },[spin])

    const [page,set_page] = useState(null)
    const getLand =async ()=>{
        set_spin(true)
        set_spinTip("بزودی صفحه بارگذاری می شود ...")
        try{
            const resp = await getLandApi(slug)
            set_page(resp.data.data)
            fillPage(resp.data.data)
             hasCredit(resp.data.data.trainer_id)
            set_pageNotFound("found")
            set_spin(false)
        }catch (e) {
            set_pageNotFound("not_found")
            set_spin(false)
            console.log(e)
        }
    }

    const getProducts = async ()=>{
        try{
            let productsData = await  getProductsApi(slug)
            let productsRegalData = await  getProductsRegalApi(slug)
            console.log(productsData)
            set_products(productsData.data.data)
            set_productsReal(productsRegalData.data.data)

        }catch (e){
            console.log(e)
        }
    }


    const [links,set_links] = useState(new Array(10).fill({title:"",image:"",type:"link",address:""}))
    const [products,set_products] = useState([])
    const [productsReal,set_productsReal] = useState([])
    const [gallery,set_gallery] = useState(new Array(10).fill({title:"",image:"",type:"gallery",address:""}))
    const [shopTitle,set_shopTitle] = useState("")
    const [shopAddress,set_shopAddress] = useState("")
    const [shopDescription,set_shopDescription] = useState("")

    const [shopStatus,set_shopStatus] = useState(false)
    const [linkStatus,set_linkStatus] = useState(false)
    const [galleryStatus,set_galleryStatus] = useState(false)
    const [contactStatus,set_contactStatus] = useState(false)
    const [mapStatus,set_mapStatus] = useState(false)
    const [location,set_location] = useState({})

    const fillPage =async (p)=>{
        set_full_name(p.full_name)
        set_short_description(p.short_description)
        set_descriptions(p.descriptions)
        set_video(p.video)
        set_image(p.image)



        if(p.map_status){
            set_location(p.location)
        }

        set_telegram(p.telegram)
        set_instagram(p.instagram)
        set_whatsApp(p.whatsApp)
        set_youtube(p.youtube)

        if(p.shop_status){
            getProducts()
        }
        set_shopStatus(p.shop_status)
        set_linkStatus(p.links_status)
        set_galleryStatus(p.gallery_status)
        set_contactStatus(p.contact_status)
        set_mapStatus(p.map_status)

        if(p.contact_status){
            set_phones(p.phones)
            set_address(p.address)
            set_email(p.email)
        }

        if(p.shop){
            set_shopTitle(p.shop.title)
            set_shopAddress(p.shop.address)
            set_shopDescription(p.shop.description)
        }


        if(p.links){
            let linksTemp = links
            p.links.forEach((l)=>{
                linksTemp[l.order]= l
            })
            linksTemp = linksTemp.filter(l=>l.title.length>0 || l.address.length>0 || l.image.length>0)
            set_links(linksTemp)
        }

        if(p.gallery){
            let galleryTemp = gallery
            p.gallery.forEach((g)=>{
                galleryTemp[g.order]= g
            })
            galleryTemp = galleryTemp.filter(l=>l.title.length>0 || l.address.length>0 || l.image.length>0)
            set_gallery(galleryTemp)
        }


        // set_location(p.location)
        try{
            set_socials([
                {link:p.telegram?p.telegram:"",image:p.telegram?.length>0?telegramImage:telegramImageOff,title:""},
                {link:p.youtube?p.youtube:"",image:p.youtube?.length>0?youTubeImage:youTubeImageOff,title:""},
                {link:p.instagram?p.instagram:"",image:p.instagram?.length>0?instagramImage:instagramImageOff,title:""},
                {link:p.whatsApp?p.whatsApp:"",image:p.whatsApp?.length>0?whatsAppImage:whatsAppImageOff,title:""},
            ])
        }catch (e) {

        }
    }


    //plans drawer
    const [visiblePlansDrawer,set_visiblePlansDrawer] = useState(false)

    const showPlansDrawer = (p)=>{
        if(credit && credit.plan ){
            if(page.has_capacity){
                getPackage()
                set_visiblePlansDrawer(true)
            }else {
                return message.error({
                    content: "ظرفیت پذیرش برای امروز به پایان رسیده است",
                    className: 'toast-class'
                });
            }
        }
        else {
            return message.error({
                content: "در حال حاضر سرویس برنامه آنلاین غیر فعال است",
                className: 'toast-class'
            });
        }
    }

    const onPlansDrawerClose = () => {
        set_visiblePlansDrawer(false);
    };


    //booking drawer
    const [visibleBookingDrawer,set_visibleBookingDrawer] = useState(false)

    const showBookingDrawer = async (p)=>{

        try{
            const resp = await getServices_land_api(slug);
            if(resp.data.data.length > 0){
            if(credit && credit.booking)
                set_visibleBookingDrawer(true)
            else {
                return message.error({
                    content: "در حال حاضر سرویس وقت حضوری غیر فعال است",
                    className: 'toast-class'
                });
            }}else {
                return message.error({
                    content: "در حال حاضر سرویس وقت حضوری غیر فعال است",
                    className: 'toast-class'
                });
            }
        }catch (e) {
            console.log(e)
        }


    }

    const onBookingDrawerClose = () => {
        set_visibleBookingDrawer(false);
    };

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    const goToLink =(address)=>{
        if(address && address.length>0)
            window.open(address, '_blank')
    }

    return(
        <div>
            {pageNotFound==="default"?
                <div className="default-page">
                    <span></span>
                </div>
            :null
            }
            {pageNotFound==="found"?
                <>
                    <div className="page-container-v2">
                        <br/>
                        <div className="page-video-sec">
                            <div className="player-sec">
                                <ReactPlayer
                                    playsinline={true}
                                    playing={true}
                                    volume={0.7}
                                    loop={true}
                                    controls={true}
                                    muted={mute}
                                    height={'auto'}
                                    width={"100%"}   url={Setup.filePoint+video+"?id="+uniqId}  />
                            </div>

                            {/*<div className="edit-icon">*/}
                            {/*    <img src={EditIcon} alt=""/>*/}
                            {/*</div>*/}
                            {/*<div className="upload-btn">*/}
                            {/*    <span>آپلود ویدیو</span>*/}
                            {/*</div>*/}
                        </div>

                        <div className="page-actions-btns">


                            {page?.services.contains("booking")?
                                <div className="page-actions-btn clickable" onClick={e=>{
                                    showBookingDrawer()
                                }}>
                                    <div className="page-actions-btn-icon">
                                        <img src={calendarIcon} alt=""/>
                                    </div>
                                    <div className="page-actions-btn-label-left">
                                        <span>گرفتن وقت</span>
                                    </div>
                                </div>:null}

                            {page?.services.contains("plan")?
                                <div className="page-actions-btn clickable" onClick={e=>{
                                    showPlansDrawer()
                                }}>
                                    <div className="page-actions-btn-label-right">
                                        <span>گرفتن برنامه</span>
                                    </div>
                                    <div className="page-actions-btn-icon">
                                        <img src={halterIcon} alt=""/>
                                    </div>
                                </div>:null
                            }


                        </div>


                        {full_name?.length>0? <div className="page-profile">
                            <div className="page-profile-img">
                                <div className="imag-thumb">
                                    <img className="user_img" src={Setup.filePoint+image+"?id="+uniqId} alt=""/>
                                </div>
                            </div>

                            <div className="page-profile-name">
                                <h6>{full_name}</h6>
                                <span>{short_description}</span>
                            </div>
                        </div>:null}


                        {descriptions.length>0?
                        <>
                            <div className="page-divider">
                                <hr/>
                            </div>

                            <div className="page-desc-items">
                                {descriptions.map(d=>{
                                    return(
                                        <div className="page-desc-item">
                                            <div className="page-desc-item-text-p">
                                                <span>{d.text}</span>
                                            </div>
                                            <div className="page_bullet"></div>
                                        </div>
                                    )
                                })}
                            </div>

                        </>:null
                        }


                        {shopStatus?
                            <div className="page-section">
                                <div className="page-divider">
                                    <hr/>
                                </div>

                                {products.length>0?
                                    <OnlineShop page={page} productsRegal={productsReal} products={products} slug={slug}/>
                                    :null}



                                {/*<div className="page-shop">*/}

                                {/*    <div className="page-shop-btn">*/}
                                {/*<span onClick={e=>{*/}
                                {/*    goToLink(shopAddress)*/}
                                {/*}} className="clickable">{shopTitle}</span>*/}
                                {/*    </div>*/}

                                {/*    <div className="page-shop-description">*/}
                                {/*        <p>{shopDescription}</p>*/}
                                {/*    </div>*/}

                                {/*</div>*/}

                            </div>:null
                        }


                        {linkStatus?<div className="page-section">
                            <div className="page-divider">
                                <hr/>
                            </div>

                            <div className="page-sec-header" style={{justifyContent:"flex-end"}}>
                                <span>لینک ها</span>
                            </div>

                            <div className="swiper-page-wrapper">
                                {linkStatus?
                                    <Swiper ref={MyRef}  {...paramsLink}>
                                        {links.map((l,i)=>{
                                            return(
                                                <div className="page-swiper-gallery-item" onClick={e=>{
                                                    goToLink(l.address)
                                                }}>
                                                    <div className="img-wrapper">
                                                        {l.image.length>0?
                                                            <img src={`${Setup.filePoint+l.image}?id=${uniqId}`} alt=""/>:
                                                            <div className="default-icon">
                                                                <PictureOutlined/>
                                                            </div>
                                                        }
                                                    </div>
                                                    {l.title?
                                                        <span className="link-title">{l.title}</span>:<span></span>
                                                    }

                                                </div>
                                            )
                                        })}
                                    </Swiper>:null
                                }

                            </div>
                        </div>:null}


                        {/*<div className="page-socials">*/}
                        {/*    <Swiper ref={socialRef}  {...params}>*/}
                        {/*        {socials.map(social=>{*/}
                        {/*            return(*/}
                        {/*                <div  className={'social-bar-item clickable'} onClick={e=>{*/}
                        {/*                    window.open(social.link, '_blank');*/}
                        {/*                }}>*/}
                        {/*                    <img src={social.image} alt=""/>*/}
                        {/*                </div>*/}
                        {/*            )*/}
                        {/*        })}*/}
                        {/*    </Swiper>*/}
                        {/*</div>*/}




                        {galleryStatus?
                            <div className="page-section">
                                <div className="page-divider">
                                    <hr/>
                                </div>



                                <div className="page-sec-header" style={{justifyContent:"flex-end"}}>
                                    <span>گالری</span>
                                </div>

                                <div className="swiper-page-wrapper">
                                    {galleryStatus?

                                        <Swiper ref={MyRef}  {...params}>
                                            {gallery.map((l,i)=>{
                                                return(
                                                    <div className="page-swiper-item gallery_wrapper">
                                                        {l.image.length>0?
                                                            <Zoom style={{width:"100%"}}>
                                                                <div className="img-wrapper">
                                                                    <img src={Setup.filePoint+l.image} alt=""/>
                                                                </div>
                                                            </Zoom>:
                                                            <div className="img-wrapper">

                                                            </div>}
                                                        {l.title?
                                                            <span className="link-title">{l.title}</span>:<span></span>
                                                        }
                                                    </div>
                                                )
                                            })}
                                        </Swiper>:null}

                                </div>

                            </div>:null
                        }



                        {contactStatus?
                            <div className="page-section">
                                <div className="page-divider">
                                    <hr/>
                                </div>



                                {phones?.length>0?<div className="page-phone">
                                    {phones.map((p,i)=>{
                                        return(
                                            <Fragment>
                                                <span>{p.text}</span>
                                                {phones.length-1>i?<span> - </span>:null}
                                            </Fragment>
                                        )
                                    })}
                                    <h6> تلفن : </h6>
                                </div>:null}

                                {email?.length>0?
                                    <div className="page-email">
                                        <span>{email}</span>
                                        <h6> ایمیل : </h6>
                                    </div>:
                                    null}

                                {address?.length>0?
                                    <div className="page-address">
                                        {address.map(a=>{
                                            return(
                                                <span>{a}</span>
                                            )
                                        })}
                                    </div>:null
                                }
                            </div>:null
                        }

                        {mapStatus?
                            <div className="page-location">
                                <div className="page-actions-btn clickable">
                                    <div className="page-actions-btn-icon">
                                        <img src={locationIcon} alt=""/>
                                    </div>
                                    <div className="page-actions-btn-label-left" onClick={goToMap}>
                                        <span> جای من روی نقشه</span>
                                    </div>
                                </div>

                            </div>:null
                        }

                        {/*<div className="page-divider">*/}
                        {/*    <hr/>*/}
                        {/*</div>*/}

                        {/*<div className="page-followup clickable">*/}
                        {/*    <div className="page-followup-btn">*/}
                        {/*        <img src={followupIcon} alt=""/>*/}
                        {/*        <span>پیگیری</span>*/}
                        {/*    </div>*/}

                        {/*</div>*/}

                        {/*Add New Trainee*/}
                        <Drawer destroyOnClose={true}  bodyStyle={{padding:0,margin:0}}  width={"85vw"} closable={false}  onClose={onPlansDrawerClose} visible={visiblePlansDrawer}>
                            <br/>
                            <div className="package-list">
                                {packages.map(d=>{
                                    return(
                                        <PackageCard  page={page} item={d} slug={slug}/>
                                    )
                                })}
                                {packages.length<1 && !spin?
                                    <div className="empty-state">
                                        <img src={emptyIcon} alt=""/>
                                        <span>در حال حاضر پکیجی وجود</span>
                                        <span>ندارد !</span>
                                    </div>:null
                                }
                            </div>
                        </Drawer>

                        <Drawer destroyOnClose={true} width={"85vw"}   bodyStyle={{padding:0,margin:0}} closable={false}  placement="right" onClose={onBookingDrawerClose} visible={visibleBookingDrawer}>
                            {page?.trainer_id?
                                <DumbbellBookingLand page={page} slug={slug} trainerId={page.trainer_id} />:
                                null
                            }
                        </Drawer>

                    </div>
                </>:null
            }

            {pageNotFound==="not_found"?
                <div className="not-found-page">
                    <img src={inActivePageIcon} alt=""/>
                    <span>در حال حاضر این صفحه فعال نمیباشد</span>
                </div>:null
            }

        </div>

    )
}

export default PageV2
