import React, {useEffect, useState} from 'react';
import "./DumbbellVideoList.scss";
import DumbbellDrawerHeader from "../../Components/DumbbellDrawerHeader/DumbbellDrawerHeader";
import {LoadingOutlined,CameraOutlined} from "@ant-design/icons"
import searchIcon from "../../assets/dumbbell_image/V2/search_icon_v2.svg"
import handClickIcon from "../../assets/dumbbell_image/V2/hand_click_icon.svg"
import playIcon from "../../assets/dumbbell_image/play-icon.png";
import plusWhiteIcon from "../../assets/dumbbell_image/plus-white-icon.png";
import {Drawer, message, Popconfirm, Spin} from "antd";
import DumbbellAddTrainee from "../DumbbellAddTrainee/DumbbellAddTrainee";
import DumbbellVideoUpload from "../DumbbellVideoUpload/DumbbellVideoUpload";
import {cancelMeet_api, deleteVideo_api, getMyVideosApi, searchVideo_api} from "../../Services/Services";
import ReactPlayer from 'react-player'
import "antd/dist/antd.css";
import trashWhiteIcon from "../../assets/dumbbell_image/trash_white_icon.png";
import EditIcon from "../../assets/dumbbell_image/V2/edit_icon.svg"
import {Button, Modal} from 'antd';
import {Setup} from "../../Services/Setup";
import DumbbellHeader from "../../Components/DumbbellHeder/DumbbellHeder";
import {useDispatchLoading} from "../../Hook/useDispatchLoading";

let globalSearch = ""
let globalType = "all"

function DumbbellVideoList({header = true, select = false, handleSelectItem}) {

    useEffect(() => {
        document.body.style.backgroundColor = "#ffffff";
    }, [])

    const [videos, set_videos] = useState([])
    const [searchWord, set_searchWord] = useState("")
    const [type, set_type] = useState("all")

    //Add Trainee Drawer
    const [visibleTraineeDrawer, set_visibleTraineeDrawer] = useState(false)
    const [selectedVideo, set_selectedVideo] = useState("")
    const [selectedImage, set_selectedImage] = useState("")


    const showMenu = () => {
        set_visibleTraineeDrawer(!visibleTraineeDrawer)
    }

    const onMenuClose = () => {
        set_visibleTraineeDrawer(false);
        getVideos()
    };

    useEffect(() => {
        getVideos()
        getLocalUser()
    }, [])


    const [spin, set_spin] = useState(false);
    const setLoader = useDispatchLoading()
    useEffect(() => {
        setLoader(spin)
    }, [spin])
    const getVideos = async () => {
        set_spin(true)
        try {
            const response = await searchVideo_api(globalSearch, globalType)
            set_videos([])
            set_videos(response.data.data)
            set_spin(false)
        } catch (e) {
            set_spin(false)
            console.log(e)
        }
    }


    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = (urlVideo,urlImage) => {
        set_selectedVideo(urlVideo)
        set_selectedImage(urlImage)
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const [my, set_my] = useState(null)
    const getLocalUser = () => {
        let user = localStorage.getItem('user')
        set_my(JSON.parse(user))
    }

    const handleChangeType = (t) => {
        set_type(t)
        globalType = t
        getVideos()
    }

    const handleSearch = async (text) => {
        set_searchWord(text)
        globalSearch = text
        getVideos()
    }


    const confirm = async (video_id) => {
        set_spin(true)
        try {
            await deleteVideo_api(video_id)
            set_spin(false)
            getVideos()
            return message.success({
                content: "آیتم حذف شد",
                className: 'toast-class'
            });
        } catch (e) {
            set_spin(false)
            console.log(e)
            return message.success({
                content: "شما در حال حاضر قادر به حذف این آیتم نمی باشید",
                className: 'toast-class'
            });
        }
    };
    const cancel = (e) => {
        console.log(e);
        // message.error('Click on No');
    };

    const handleUploadDone = () => {
        console.log('Upload Done')
        onMenuClose()
    }

    const [editedVideo,set_editedVideo] = useState(null)
    const handleShowEdit  = (v)=>{
        set_editedVideo(v)
        showMenu()
    }


    return (
        <div className="dumbbell-video-container">

            {/*Add New Trainee*/}
            <Drawer destroyOnClose={true} bodyStyle={{padding: 0, margin: 0}} width={300} closable={false} onClose={onMenuClose}
                    visible={visibleTraineeDrawer}>
                <DumbbellVideoUpload edited={editedVideo} handleUploadDone={handleUploadDone}/>
            </Drawer>

            {header ? <DumbbellHeader title="تصاویر"/> : null}

            <div className="dumbbell-trainee-search-sec-v2">
                <div className="dumbbell-trainee-search">
                    {/*<div className="dumbbell-trainee-filter">*/}
                    {/*    <select*/}
                    {/*        onChange={e=>handleChangeType(e.target.value)}*/}
                    {/*        value={type}*/}
                    {/*        name="" id="">*/}
                    {/*        <option value="all">همه</option>*/}
                    {/*        <option value="public">آرشیو عمومی</option>*/}
                    {/*        <option value="me">آرشیو من</option>*/}
                    {/*    </select>*/}
                    {/*</div>*/}
                    <img src={searchIcon} alt=""/>
                    <input
                        value={searchWord}
                        onChange={e => {
                            handleSearch(e.target.value)
                        }}
                        type="text"
                        placeholder={"نام حرکت را وارد نمایید"}
                        name="" id=""/>

                </div>
            </div>

            {/*<div className="video-tabs">*/}
            {/*    <div className="tabs-row">*/}
            {/*        <div onClick={e => handleChangeType("all")}*/}
            {/*             className={type === "all" ? "tabs-item clickable active-tab" : "tabs-item clickable"}>*/}
            {/*            <span>همه</span>*/}
            {/*        </div>*/}
            {/*        <div onClick={e => handleChangeType("public")}*/}
            {/*             className={type === "public" ? "tabs-item clickable active-tab" : "tabs-item clickable"}>*/}
            {/*            <span>عمومی</span>*/}
            {/*        </div>*/}
            {/*        <div onClick={e => handleChangeType("me")}*/}
            {/*             className={type === "me" ? "tabs-item clickable active-tab" : "tabs-item clickable"}>*/}
            {/*            <span>آرشیو من</span>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}


            <div className="dumbbell-video-list">


                {videos.map((v, index) => {
                    return (
                        <div className="dumbbell-video-card">
                            {select ?
                                <div className="dumbbell-video-card-number select-video clickable" onClick={e => {
                                    handleSelectItem(v)
                                }
                                }>
                                    <img src={handClickIcon} alt=""/>
                                </div> :
                                <>
                                    {v.type === "me" ?
                                        <div className="dumbbell-video-card-number ">
                                            <div className="delete-video clickable">
                                                <Popconfirm
                                                    title="آیا شما از حذف این آیتم مطمئن هستید ؟"
                                                    onConfirm={e => confirm(v._id)} onCancel={cancel}
                                                    okText="بله"
                                                    cancelText="خیر"
                                                >
                                                    <img src={trashWhiteIcon} alt=""/>
                                                </Popconfirm>
                                            </div>
                                            <div className="edit-video clickable" onClick={e=>{
                                                handleShowEdit(v)
                                            }}>
                                                <img src={EditIcon} alt=""/>
                                            </div>
                                        </div>
                                        : <div></div>}

                                </>
                            }

                            <div className="dumbbell-video-card-main">
                                <div className="dumbbell-video-card-info">
                                    <div className="dumbbell-video-card-info-title">
                                        <span>{v.fa_names[0]}</span>
                                    </div>
                                    <div className="dumbbell-video-card-info-type">
                                        <span
                                            className={v.type === "me" ? "dumbbell-video-card-info-type-me" : "dumbbell-video-card-info-type-public"}

                                        >{v.type === "me" ? "شخصی" : "عمومی"}</span>
                                    </div>
                                </div>

                                <div className="dumbbell-video-card-video clickable" onClick={e => showModal(v.video,v.image)}>
                                    {/*<img src={playIcon} alt=""/>*/}
                                    <CameraOutlined style={{
                                        fontSize: 25,
                                        color: '#6b6969'
                                    }} />
                                    {/*<ReactPlayer*/}
                                    {/*    className="videoFrame"*/}
                                    {/*    url={v.url}*/}
                                    {/*    playing*/}
                                    {/*    controls*/}
                                    {/*/>*/}
                                </div>
                            </div>
                        </div>
                    )
                })}


                <Drawer bodyStyle={{padding: 0, margin: 0}} placement="top" height={400} closable={false}
                        onClose={handleCancel} visible={isModalOpen}>
                    {isModalOpen ?
                        <div className="top-shower">
                            {selectedVideo?
                                <ReactPlayer width={"100%"}  url={Setup.filePoint + selectedVideo} controls/>
                                :null}
                            {selectedImage?
                                <img src={Setup.filePoint+selectedImage} alt=""/>
                                :null}

                        </div>
                         :
                        null
                    }

                </Drawer>
                <div className="float-add-btn" onClick={e=>{
                    set_editedVideo(null)
                    showMenu()
                }}>
                    <span>تصویر جدید</span>
                    <img src={plusWhiteIcon} alt=""/>
                </div>

            </div>

        </div>
    )
}

export default DumbbellVideoList;
