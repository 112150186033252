import React from "react";
import { CartDispatchContext } from "../providers";

function useDispatchCart() {
    const context = React.useContext(CartDispatchContext);
    if (context === undefined) {
        throw new Error("useDispatchCart must be used within a CartProvider");
    }
    return context;
}

export { useDispatchCart };
