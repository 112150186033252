import React ,{useState,useEffect} from 'react';
import "./DumbbellRequestPlan.scss";
import DumbbellDrawerHeader from "../../Components/DumbbellDrawerHeader/DumbbellDrawerHeader";
import {addTraineeApi, getLib_api, payMySelfPlanInvoiceApi, sendRequestPlanInvoiceApi} from "../../Services/Services";
import {message, Spin} from "antd";
import {
    convertToEnglishNumbers,
    englishNumber,
    numberWithCommas,
    numberWithCommasString,
    validation
} from "../../Services/utils";

function DumbbellRequestPlan({handleDoneSendRequest,traineeId}){

    let [title,set_title] = useState("")
    let [cost,set_cost] = useState("")
    let [discount,set_discount] = useState("")
    let [estimate,set_estimate] = useState("1")
    let [target,set_target] = useState("")
    let [duration,set_duration] = useState(30)
    let [supportDay,set_supportDay] = useState("30")
    let [spinPage,set_spinPage] = useState(false)


    let [libs,set_libs] = useState([])
    let [selectedLib,set_selectedLib] = useState("not_select")
    let [deliveredType,set_deliveredType] = useState("default")
    let [chatOption,set_chatOption] = useState(false)
    let [boxOption,set_boxOption] = useState(false)

    useEffect(()=>{
        getLibs()
    },[])

    const getLibs = async ()=>{
        try{
            const res = await getLib_api()
            set_libs(res.data.data)
        }catch (e) {
            console.log("Error :",e)
        }
    }

    const resetForm =()=>{
        set_title("")
        set_cost("")
        set_discount("")
        set_estimate("1")
        set_target("")

        set_chatOption(false)
        set_boxOption(false)
        set_selectedLib("not_select")
        set_deliveredType("default")
    }

    const sendInvoice = async ()=>{
        let cost_num= cost.length>0?cost.replaceAll(",", ""):"0";
        let discount_num=discount.length>0?discount.replaceAll(",", ""):"0";
        let costIsNum = /^\d+$/.test(cost_num);
        let discountIsNum = /^\d+$/.test(discount_num);
        let estimateIsNum = /^\d+$/.test(estimate);


        if(title.length<2){
            return message.error({
                content: "لطفا عنوان را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(target.length<2){
            return message.error({
                content: "لطفا هدف را وارد نمایید",
                className: 'toast-class'
            });
        }

        // if(cost.length<1){
        //     return message.error({
        //         content: "لطفا هزینه را وارد نمایید",
        //         className: 'toast-class'
        //     });
        // }

        if(!costIsNum){
            return message.error({
                content: "لطفا هزینه را بصورت صحیح وارد نمایید",
                className: 'toast-class'
            });
        }

        // if(discount.length<1){
        //     return message.error({
        //         content: "لطفا تخفیف را وارد نمایید",
        //         className: 'toast-class'
        //     });
        // }

        if(!discountIsNum){
            return message.error({
                content: "لطفا تخفیف را بصورت صحیح وارد نمایید",
                className: 'toast-class'
            });
        }


        if(deliveredType==="instant" && selectedLib==="not_select"){
            return message.error({
                content: "لطفا یک برنامه انتخاب نمایید",
                className: 'toast-class'
            });
        }
        if(deliveredType==="default" && !estimateIsNum){
            return message.error({
                content: "لطفا مدت زمان تحویل را وارد نمایید",
                className: 'toast-class'
            });
        }


        let obj ={
            title: title,
            type:"trainee-pay",
            target:target,
            duration:parseInt(duration),
            support_day:parseInt(supportDay),
            traineeId: traineeId,
            amount: parseInt(cost_num),
            discount: parseInt(discount_num),
            payable_amount: parseInt(cost_num)-parseInt(discount_num),
            delivery_estimate: estimate,
            delivered_type:deliveredType,
            chat_option:chatOption,
            box_option:boxOption,
            connected_lib_id:selectedLib
        }

        if(selectedLib==="not_select"){
            delete obj['connected_lib_id']
        }

        try{
            await sendRequestPlanInvoiceApi(obj)
            resetForm()
            message.success({
                content: "فاکتور برنامه برای شاگرد ارسال شد",
                className: 'toast-class'
            });

            handleDoneSendRequest()
        }catch (e) {
            console.log(e.response.status)
            if(e.response.status===403 || e.response.status===405){
                return message.error({
                    content: "شما قادر به انجام این کار نمیباشید",
                    className: 'toast-class'
                });
            }

            return message.error({
                content: "مشکل در ارسال برنامه بوجود آمده است",
                className: 'toast-class'
            });

        }

    }

    const payMySelfInvoice = async ()=>{
        let cost_num= cost.replaceAll(",", "");
        let discount_num= discount.replaceAll(",", "");
        let costIsNum = /^\d+$/.test(cost_num);
        let discountIsNum = /^\d+$/.test(discount_num);
        let estimateIsNum = /^\d+$/.test(estimate);


        if(title.length<2){
            return message.error({
                content: "لطفا عنوان را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(cost.length<2){
            return message.error({
                content: "لطفا هزینه را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(!costIsNum){
            return message.error({
                content: "لطفا هزینه را بصورت صحیح وارد نمایید",
                className: 'toast-class'
            });
        }

        if(discount.length<2){
            return message.error({
                content: "لطفا تخفیف را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(!discountIsNum){
            return message.error({
                content: "لطفا تخفیف را بصورت صحیح وارد نمایید",
                className: 'toast-class'
            });
        }



        if(!estimateIsNum){
            return message.error({
                content: "لطفا مدت زمان تحویل را وارد نمایید",
                className: 'toast-class'
            });
        }


        let obj ={
            title: title,
            type:"trainer-pay",
            traineeId: traineeId,
            amount: parseInt(cost_num),
            discount: parseInt(discount_num),
            payable_amount: parseInt(cost_num)-parseInt(discount_num),
            delivery_estimate: estimate,
        }


        try{
            await payMySelfPlanInvoiceApi(obj)
            resetForm()
            message.success({
                content: "فاکتور برنامه ارسال شد",
                className: 'toast-class'
            });

            handleDoneSendRequest()
        }catch (e) {
            console.log(e)
            return message.error({
                content: "مشکل در ارسال برنامه بوجود آمده است",
                className: 'toast-class'
            });
        }
    }


    const calcTotal = ()=>{
        let c = cost;
        let d = discount;

        if(typeof c==="number")
            c= c.toString()
        if(typeof d==="number")
            d= d.toString()

        return numberWithCommas((c.replaceAll(",", ""))-(d.replaceAll(",", "")) >0? (c.replaceAll(",", ""))-(d.replaceAll(",", "")): 0)
    }


    return(
        <div className="dumbbell-add-trainee-container">

           <DumbbellDrawerHeader title={"ایجاد برنامه"}/>

            <Spin spinning={spinPage}>

                <div className="dumbbell-add-trainee-form">

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={title}
                            onChange={e=>set_title(e.target.value)}
                            type="text" name=""
                            placeholder="مثلا : برنامه تمرینی"
                            id=""/>
                        <span>عنوان برنامه</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <textarea
                            value={target}
                            onChange={e=>set_target(e.target.value)}
                            name=""
                            placeholder="مثلا : کاهش وزن"
                        >

                        </textarea>
                        <span>هدف برنامه</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={cost}
                            pattern="[0-9]*"
                            inputMode="numeric"
                            onChange={e=>set_cost(numberWithCommasString(convertToEnglishNumbers(e.target.value)))}
                            type="text" name="" id=""/>
                        <span>هزینه برنامه (تومان)</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            pattern="[0-9]*"
                            inputMode="numeric"
                            value={discount}
                            onChange={e=>set_discount(numberWithCommasString(convertToEnglishNumbers(e.target.value)))}
                            type="text" name="" id=""/>
                        <span>تخفیف برنامه (تومان)</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <select
                            className="select-amend"
                            value={deliveredType}
                            onChange={e=>set_deliveredType(e.target.value)}
                            name="" id="">
                            <option value="instant">تحویل آنی</option>
                            <option value="default">تحویل مدت دار</option>
                        </select>
                        <span>نوع تحویل</span>
                    </div>

                    {deliveredType==="default"?
                        <div className="dumbbell-add-trainee-form-item">
                            <input
                                value={estimate}
                                onChange={e=>set_estimate(e.target.value)}
                                type="text" name="" id=""/>
                            <span>مدت زمان تحویل(روز)</span>
                        </div>:null
                    }

                    {deliveredType==="instant"?
                        <div className="dumbbell-add-trainee-form-item">
                            <select
                                className="select-amend"
                                value={selectedLib}
                                onChange={e=>set_selectedLib(e.target.value)}
                                name="" id="">
                                <option value="not_select">انتخاب برنامه</option>
                                {libs.map(l=>{
                                    return(
                                        <option value={l._id}>{l.title}</option>
                                    )
                                })}
                            </select>
                            <span>انتخاب برنامه</span>
                        </div>:null
                    }

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={duration}
                            onChange={e=>set_duration(e.target.value)}
                            type="text"/>
                        <span>طول مدت برنامه</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <select
                            className="select-amend"
                            value={supportDay}
                            onChange={e=>set_supportDay(e.target.value)}
                            name="" id="">
                            <option value="30">30</option>
                            <option value="60">60</option>
                            <option value="90">90</option>
                            <option value="120">120</option>
                            <option value="150">150</option>
                            <option value="180">180</option>
                        </select>
                        <span>طول مدت پشتیبانی (روز)</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            onClick={e=>set_chatOption(!chatOption)}
                            type="checkbox" checked={chatOption} name="" id=""/>
                        <span>قابلیت چت با مربی</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            onClick={e=>set_boxOption(!boxOption)}
                            type="checkbox" checked={boxOption} name="" id=""/>
                        <span>قابلیت تبادل تصویر با مربی</span>
                    </div>


                    <div className="total-section">
                        <span className="total-section-label">{`مبلغ قابل پرداخت`}</span>
                        <span className="total-section-price">{calcTotal()} تومان </span>
                    </div>



                    <div className="dumbbell-add-trainee-form-submit-send-invoice" onClick={e=>{
                        sendInvoice()
                    }}>
                        <span>ارسال فاکتور برنامه</span>
                    </div>
                    {/*<div className="dumbbell-add-trainee-form-submit-pay-my-invoice" onClick={e=>{*/}
                    {/*    payMySelfInvoice()*/}
                    {/*}}>*/}
                    {/*    <span>خودم پرداخت میکنم</span>*/}
                    {/*</div>*/}

                </div>

            </Spin>






        </div>
    )
}

export default DumbbellRequestPlan;
