import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter as Router} from "react-router-dom";
import {
    UserProvider,
    ErrorProvider,
    NotificationProvider,
    LoadingProvider,
    UploadLoadingProvider,
    CartProvider
} from "./providers";
// import * as serviceWorker from './serviceWorker';


const Providers = ({children}) =>{
    return(
        <LoadingProvider>
            <CartProvider>
        <UploadLoadingProvider>
        <NotificationProvider>
            <ErrorProvider>
                {children}
            </ErrorProvider>
        </NotificationProvider>
        </UploadLoadingProvider>
            </CartProvider>
        </LoadingProvider>
    )
}



ReactDOM.render(
    <Router>
        <Providers>
            <App />
        </Providers>
    </Router>,
    document.getElementById('root')
);

// serviceWorker.register();
