import React,{useEffect,useState} from 'react';
import {planOneApi, printOneApi, printOneTraineeApi} from "../../Services/Services";
import {useParams} from "react-router";
import "./PrintablePlan.scss"
import moment from "moment-jalaali";
import { v4 as uuidv4 } from 'uuid';
import {useDispatchLoading} from "../../Hook/useDispatchLoading";

function SuperCard({m}){
    return(
        <div className="super-sec">
            <div className="super-title">
                <span className="super-title-text">سوپر ست</span>
            </div>
            <div className="super-list">
                {m.list.map(l=>{
                    if(l.type==="row"){
                        return(
                            <RowCard showOrder={true} m={l}/>
                        )
                    }
                    if(l.type==="alter"){
                        return(
                            <AlterCard showOrder={false} m={l}/>
                        )
                    }
                })}
            </div>

        </div>
    )
}

function AlterCard({m}){

    return(
        <div className="alter-sec">
            <div className="alter-title">
                {/*<span className="alter-title-number">{m.order}</span>*/}
                <span className="alter-title-text">جایگزین (از وعده های زیر یکی کافیست)</span>
            </div>
            <div className="alter-list">
                {m.list.map(l=>{
                    return(
                        <RowCard showOrder={true} m={l}/>
                    )
                })}
            </div>
        </div>
    )
}

function RowCard({m,showOrder,margin=null}){
    return(
        <>
            {!m.desc_mode?
                <div className="print-day-card-body-row" style={margin?{margin:`0 ${margin}px`}:{}}>
                    {showOrder?
                        <div className="print-day-card-body-item index-item-diet">
                            <span>{m.text}</span>
                        </div>:null
                    }
                    <div className="print-day-card-body-item print-col-2">
                        {m.items.map((i,index)=>{
                            return(
                                <>
                                    <span>{i.text} </span>
                                    {index<m.items.length-1?<span> - </span>:null}
                                </>

                            )
                        })}
                    </div>
                </div>:null}

            {m.desc_mode?
                <div className="desc_meal_print">
                    <h5>توضیحات : </h5>

                    <span>{m.text}</span>
                </div>:null}

        </>

    )
}


function PrintCard({t}){

    const [main,set_main] = useState([])

    useEffect(()=>{
        generateTable(t)
    },[])

    const generateTable = (t)=>{
        let results =[]
        let supers = t.supers
        let alter = t.alternatives



        let removedItem = []
        supers.forEach(s=>{
            t.parts.forEach((p,i)=>{
                let uid= uuidv4()
                if(s.root.id===p.id){
                    t.parts[i]={type:"super",id:uid,order:s.root.order,list:s.others}
                    let removedArr = s.others.filter(c=>c.id!==s.root.id)
                    removedItem.push(removedArr)
                }
            })
        })

        removedItem = removedItem.flat()

        t.parts.forEach((p,i)=>{
            let find = removedItem.find(r=>r.id===p.id)
            if(find)
                delete t.parts[i]
        })





        let removedAlterItem = []
        t.parts.forEach((p,i)=>{
            if(p.type==="super"){
                p.list.forEach(((l,i1)=>{
                    alter.forEach(a=>{
                        let uid= uuidv4()
                        if(a.root.id===l.id){
                            p.list[i1]={type:"alter",id:uid,order:a.root.order,list:a.others}
                            removedAlterItem.push({
                                super:p.id,
                                data:a.others.filter(o=>o.id!==a.root.id)
                            })
                        }
                    })
                }))
            }
        })

        console.log("removedAlterItem : ",removedAlterItem)

        removedAlterItem.forEach(r=>{
            t.parts.forEach(p=>{
                if(p.id===r.super && p.type==="super"){
                    let newList = []
                    p.list.forEach(l=>{
                        let find= r.data.find(d=>d.id===l.id);
                        if(!find)
                            newList.push(l)
                    })
                    p.list = newList
                }
            })
        })

       //-----------------------
       //  return console.log("parts Row : ",t)

        let removedList = []
        alter.forEach(s=>{
            t.parts.forEach((p,i)=>{
                let uid= uuidv4()
                if(s.root.id===p.id){
                    t.parts[i]={type:"alter",id:uid,order:s.root.order,list:s.others}
                    let removedArr = s.others.filter(c=>c.id!==s.root.id)
                    removedList.push(removedArr)
                }
            })
        })

        removedList = removedList.flat()

        t.parts.forEach((p,i)=>{
            let find = removedList.find(r=>r.id===p.id)
            if(find)
                delete t.parts[i]
        })



        t.parts.forEach(p=>{
            if(!p.type)
                p['type']="row"



            if(p.type==="super"){
                p.list.forEach(s=>{
                    if(!s.type)
                        s['type']="row"

                    if(s.type==="alter"){
                        s.list.forEach(a=>{
                            if(!a.type)
                                a['type']="row"
                        })
                    }


                })
            }

            if(p.type==="alter"){
                p.list.forEach(a=>{
                    if(!a.type)
                        a['type']="row"
                })
            }
        })

        console.log("parts Row : ",t.parts)

        set_main(t.parts)
    }

    return(
        <div className="print-day-card">
            <div className="print-day-card-header">
                <span> {t.weekDay} </span>
                <span> {t.jDate} </span>
            </div>
            <div className="print-day-card-body">

                {/*<div className="print-day-card-body-row">*/}
                {/*    <div className="print-day-card-body-item-header index-item-diet">*/}
                {/*        #*/}
                {/*    </div>*/}
                {/*    <div className="print-day-card-body-item-header print-col-2">*/}
                {/*        <span>ساعت</span>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {main.map((m,index)=>{
                    if(m.type==="row"){
                        return(
                            <RowCard margin={20} showOrder={true} m={m}/>
                        )
                    }
                    if(m.type==="alter"){
                        return(
                            <AlterCard m={m}/>
                        )
                    }
                    if(m.type==="super"){
                        return(
                            <SuperCard m={m}/>
                        )
                    }
                })}
            </div>

        </div>
    )
}

function PrintableTraineePlanSupplement (){

    const [plan,set_plan] = useState({})
    const [trainee,set_trainee] = useState({})
    const {traineeId,planId} = useParams()
    const[items,set_items] = useState([])
    const setLoader = useDispatchLoading()

    useEffect(()=>{
        setLoader(true)
        getPlan()
    },[])

    const getPlan = async ()=>{
        try{
            const response = await printOneTraineeApi(traineeId,planId)
            set_plan(response.data.data.print)
            set_trainee(response.data.data.trainee)
            setLoader(false)
            normalExerciseTimes(response.data.data.print.data.supplement.times)
        }catch (e) {
            setLoader(false)
            console.log(e)
        }
    }

    const normalExerciseTimes = (list)=>{
        console.log("Ex times : ",list)
        let hasPart = list.filter(l=>l.parts.length>0)
        console.log("hasPart : ",hasPart)
        let result = createPrintPrats(hasPart)
        console.log("result : ",result)
        set_items(result)
    }

    const createPrintPrats = (days)=>{
        days.forEach(d=>{
            let alternatives = d.alternatives;
            let supers = d.supers;
            let parts = d.parts;
            let new_parts =[]
            parts.forEach(p=>{
                let alter_root_id = ""
                let super_root_id = ""
                alternatives.forEach(a=>{
                    let find = a.others.find(o=>o.id===p.id)
                    if(find){alter_root_id = a.root.id}
                })
                supers.forEach(a=>{
                    let find = a.others.find(o=>o.id===p.id)
                    if(find){super_root_id = a.root.id}
                })
                new_parts.push({...p,alter_root_id,super_root_id})
            })
            d.parts = new_parts
        })

        return days
    }

    return(
        <>
            {plan?._id?
                <div className="printable-plan-container">

                    <div className="printable-plan-header">

                        <div className="printable-plan-header-row">
                            <div className="printable-plan-header-item">
                                <h6>شاگرد</h6>
                                <span>{trainee?.first_name+" "+trainee?.last_name}</span>
                            </div>

                            <div className="printable-plan-header-item">
                                <h6>مربی</h6>
                                <span>{plan.trainer_first_name+" "+plan.trainer_last_name}</span>
                            </div>
                        </div>


                        <div className="printable-plan-header-row">
                            <div className="printable-plan-header-item">
                                <h6></h6>
                                <span></span>
                                <h6>تاریخ تحویل</h6>
                                <span>{moment(plan.createdAt).format('jYYYY/jMM/jDD')}</span>
                            </div>
                            {/*<div className="printable-plan-header-item">*/}
                            {/*    <h6>طول مدت برنامه </h6>*/}
                            {/*    <span>{plan.duration} روز</span>*/}
                            {/*</div>*/}

                            <div className="printable-plan-header-item">
                                <h6>عنوان</h6>
                                <span>{plan.title}</span>
                            </div>
                        </div>

                        {/*<div className="printable-plan-header-row">*/}
                        {/*    <div className="printable-plan-header-item">*/}
                        {/*        <h6></h6>*/}
                        {/*        <span></span>*/}
                        {/*        <h6>تاریخ تحویل</h6>*/}
                        {/*        <span>{moment(plan.createdAt).format('jYYYY/jMM/jDD')}</span>*/}
                        {/*    </div>*/}

                        {/*    <div className="printable-plan-header-item">*/}
                        {/*        <h6>هدف</h6>*/}
                        {/*        <span>{plan.target}</span>*/}
                        {/*    </div>*/}

                        {/*</div>*/}


                    </div>

                    <div className="printable-plan-body">
                        {items?.map(t=>{
                            return(
                                <PrintCard t={t}/>
                            )
                        })}
                    </div>

                </div>:null
            }
            </>
    )

}

export default PrintableTraineePlanSupplement;
