import React from "react";

export const CartContext = React.createContext();
export const CartDispatchContext = React.createContext();

function CartProvider({ children }) {
    const [state, setState] = React.useState([]); // مقدار پیش‌فرض خالی برای سبد خرید

    return (
        <CartContext.Provider value={state}>
            <CartDispatchContext.Provider value={setState}>
                {children}
            </CartDispatchContext.Provider>
        </CartContext.Provider>
    );
}

export { CartProvider };
