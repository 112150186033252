import React,{useEffect,useState,useRef} from 'react';
import "./DumbbellPlanListTrainee.scss";
import "../DumbbellTrainee/DumbbellTrainee.scss";
import DumbbellHeader from "../../Components/DumbbellHeder/DumbbellHeder";
import searchIcon from "../../assets/dumbbell_image/search-icon.png";
import sampleFace from "../../assets/images/default_user.png"
import refreshIcon from "../../assets/dumbbell_image/refresh-icon.png"
import {getMyProfileApi, getTraineeApi, planListApi, traineeListApi, traineePlanListApi} from "../../Services/Services";
import {useHistory, useParams} from "react-router";
import plusWhiteIcon from "../../assets/dumbbell_image/plus-white-icon.png";
import {Drawer} from "antd";
import DumbbellChatTrainer from "../DumbbellChatTrainer/DumbbellChatTrainer";
import DumbbellRequestPlan from "../DumbbellRequestPlan/DumbbellRequestPlan";
import useOnScreen from "../../Components/useOnScreen/useOnScreen";
import moment from "moment-jalaali";
import {convertMonthNumberToName, numberWithCommas} from "../../Services/utils";
import messageIcon from "../../assets/dumbbell_image/message-icon.png";
import { CodeSandboxOutlined ,UserOutlined} from '@ant-design/icons';
import DumbbellBoxTrainer from "../DumbbellBoxTrainer/DumbbellBoxTrainer";
import DumbbellBoxTrainee from "../DumbbellBoxTrainee/DumbbellBoxTrainee";
import DumbbellChatTrainee from "../DumbbellChatTrainee/DumbbellChatTrainee";
import DumbbellAddTrainee from "../DumbbellAddTrainee/DumbbellAddTrainee";
import DumbbellHeaderTrainee from "../../Components/DumbbellHederTrainee/DumbbellHederTrainee";
import deleteIcon from "../../assets/card_icon/delete_icon.svg";

let page = 0;
let search = "";
let triggerID = ""



function btnStatusAccess(status,pay_status) {
    if(status==="default" && pay_status==="default")
        return false

    if(status==="default" && pay_status==="payed")
        return false

    if(status==="delivered" && pay_status==="payed")
        return true
}

function btnStatus(status,pay_status) {
    if(status==="default" && pay_status==="default")
        return "منتظر پرداخت"

    if(status==="default" && pay_status==="payed")
        return "در حال تنظیم برنامه"

    if(status==="delivered" && pay_status==="payed")
        return "مشاهده برنامه"
}


function PlanCard({plan,getPaginationPlanList,traineeId,trainee,getPlanList}){

    const ref = useRef()
    const isVisible = useOnScreen(ref)
    const history = useHistory()

    const [pageSeen,set_pageSeen] = useState(false);
    const [unread,set_unread] = useState(false);

    useEffect(()=>{
        if(plan?.unread?.length>0)
            set_unread(plan.unread[0].count)
    },[])

    useEffect(()=>{
        if(isVisible && !pageSeen && triggerID===plan._id){
            console.log(plan._id,"is vis",isVisible)
            set_pageSeen(true)
            page = page+1
            getPaginationPlanList()
        }
    },[isVisible])



    //Profile Trainee Drawer
    const [visibleProfileTraineeDrawer,set_visibleProfileTraineeDrawer] = useState(false)

    const showProfileDrawer = ()=>{
        set_visibleProfileTraineeDrawer(!visibleProfileTraineeDrawer)
    }

    const onProfileDrawerClose = () => {
        set_visibleProfileTraineeDrawer(false);
    };


    //Chat Trainee Drawer
    const [visibleChatTraineeDrawer,set_visibleChatTraineeDrawer] = useState(false)

    const showChatDrawer = ()=>{
        set_visibleChatTraineeDrawer(!visibleChatTraineeDrawer)
    }

    const onChatDrawerClose = () => {
        set_unread(0)
        set_visibleChatTraineeDrawer(false);
    };



    //Chat Trainee Drawer
    const [visibleBoxTraineeDrawer,set_visibleBoxTraineeDrawer] = useState(false)

    const showBoxDrawer = ()=>{
        set_visibleBoxTraineeDrawer(!visibleBoxTraineeDrawer)
    }

    const onBoxDrawerClose = () => {
        set_visibleBoxTraineeDrawer(false);
    };


    return(
        <div className="plan-card-list-trainee" ref={ref}>
            <div className="plan-card-header">
                {/*<img src={deleteIcon} alt=""/>*/}
                <span>{numberWithCommas(plan.payable_amount)}تومان</span>
                {/*<span>{plan.title} ( تحویل حداکثر {plan.delivery_estimate} روز بعد از پرداخت ) </span>*/}
                <span>{plan.title}</span>
            </div>
            <div className="plan-card-body">
                <div className="plan-card-trainer">

                    <div className="dumbbell-header-plan-card-actions">
                        {plan.chat_option?
                            <div className="dumbbell-trainee-card-message" onClick={e=>{
                                showChatDrawer()
                            }}>
                                <img src={messageIcon} alt=""/>
                                {unread>0? <span className="dumbbell-trainee-card-message-badge">{unread}</span>:null}
                            </div>:null
                        }


                        {plan.box_option?
                            <div className="dumbbell-trainee-card-box" onClick={e=>{
                                showBoxDrawer()
                            }}>
                                <CodeSandboxOutlined style={{
                                    color: "#cdc4c4",
                                    fontSize: 19,
                                    marginTop: 6,
                                }} />
                            </div>:null
                        }


                        <div className="dumbbell-trainee-card-box" onClick={e=>{
                            showProfileDrawer()
                        }}>
                            <UserOutlined  style={{
                                color: "#cdc4c4",
                                fontSize: 19,
                                marginTop: 6,
                            }} />
                        </div>
                    </div>

                    <div className="trainer-name-trainee">
                        <span>مربی : {plan.trainer_first_name+" "+plan.trainer_last_name}</span>
                    </div>
                </div>
                <div className="plan-card-progress">
                    <div className="plan-card-progress-station" style={{backgroundColor:"#3195FF"}}>
                    <span className="plan-card-progress-station-date">
                        <span>{moment(plan.createdAt).format('jDD')}</span>
                        <span>{convertMonthNumberToName(moment(plan.createdAt).format('jMM'))}</span>
                    </span>
                        <span className="plan-card-progress-station-label">ثبت</span>
                    </div>
                    <div className="plan-card-progress-line" style={plan.payedAt?{backgroundColor:"#3195FF"}:{backgroundColor:"##D9D9D9"}}></div>
                    <div className="plan-card-progress-station" style={plan.payedAt?{backgroundColor:"#3195FF"}:{backgroundColor:"##D9D9D9"}}>
                    <span className="plan-card-progress-station-date">
                        {plan.payedAt?
                            <>
                                <span>{moment(plan.payedAt).format('jDD')}</span>
                                <span>{convertMonthNumberToName(moment(plan.payedAt).format('jMM'))}</span>
                            </>
                            :""}
                    </span>
                        <span className="plan-card-progress-station-label">پرداخت</span>
                    </div>
                    <div className="plan-card-progress-line" style={plan.deliveredAt?{backgroundColor:"#3195FF"}:{backgroundColor:"##D9D9D9"}}></div>
                    <div className="plan-card-progress-station" style={plan.deliveredAt?{backgroundColor:"#3195FF"}:{backgroundColor:"##D9D9D9"}}>
                    <span className="plan-card-progress-station-date">
                        {plan.deliveredAt?
                            <>
                                <span>{moment(plan.deliveredAt).format('jDD')}</span>
                                <span>{convertMonthNumberToName(moment(plan.deliveredAt).format('jMM'))}</span>
                            </>
                            :""}
                        {trainee.delivery_date && !plan.deliveredAt?
                            <div className="plan-card-delivery_date">
                                <span>{moment(trainee.delivery_date).format('jDD')}</span>
                                <span>{convertMonthNumberToName(moment(trainee.delivery_date).format('jMM'))}</span>
                            </div>
                            :""}
                    </span>
                        <span className="plan-card-progress-station-label">تحویل</span>
                    </div>
                </div>

                <div className="plan-card-footer">

                    <div className="plan-card-footer-btn clickable" onClick={e=>{
                        if(btnStatusAccess(plan.status,plan.pay_status))
                            history.push(`/dc/${plan._id}`)
                    }}>
                        <span>{btnStatus(plan.status,plan.pay_status)}</span>
                    </div>
                </div>

                <div className="plan-card-target">
                    <span className="plan-card-target-title"> هدف برنامه  </span>
                    <p>{plan.target}</p>
                </div>

                {plan?.refId?.length>0?
                    <div className="plan-ref-number">
                        <p>{plan.refId}</p>
                        <span className="plan-ref-title">شماره پیگیری پرداخت  </span>
                    </div>:null
                }

            </div>

            {/*Box Trainee*/}
            <Drawer push={false}  bodyStyle={{padding:0,margin:0}}  width={300} closable={false}  onClose={onBoxDrawerClose} visible={visibleBoxTraineeDrawer}>
                {visibleBoxTraineeDrawer?<DumbbellBoxTrainee visibleBoxTraineeDrawer={visibleBoxTraineeDrawer} trainee={{_id:plan.trainer_id,first_name:plan.trainer_first_name,last_name:plan.trainer_last_name,mobile:""}} />:null}
            </Drawer>

            <Drawer  bodyStyle={{padding:0,margin:0}}  width={300} closable={false}  onClose={onChatDrawerClose} visible={visibleChatTraineeDrawer}>
                {visibleChatTraineeDrawer?<DumbbellChatTrainee visibleChatTraineeDrawer={visibleChatTraineeDrawer} trainee={{_id:plan.trainer_id,first_name:plan.trainer_first_name,last_name:plan.trainer_last_name,mobile:""}} />:null}
            </Drawer>


            <Drawer bodyStyle={{padding:0,margin:0}}  width={300} closable={false}  onClose={onProfileDrawerClose} visible={visibleProfileTraineeDrawer}>
                <DumbbellAddTrainee fill={trainee}/>
            </Drawer>
        </div>
    )
}


function DumbbellPlanListTrainee(){

    // useEffect(()=>{
    //     document.body.style.backgroundColor = "#8A86FE";
    // },[])


    const [plans,set_plans] = useState([])
    const [totalCount, set_totalCount] = useState(0)
    const [my, set_my] = useState({})


    useEffect(()=>{
            getPlanList()
            getMyProfile()
    },[])

    const getMyProfile = async ()=>{
        try{
            const res = await getMyProfileApi()
            set_my(res.data.data)
        }catch (e) {
            console.log(e)
        }
    }



    const getPlanList = async ()=>{
        set_plans([])
        try{
            const res = await traineePlanListApi(0,search)
            let trainee = res.data.data.plans
            let count = res.data.data.count
            triggerID = trainee.length>45?trainee[45]._id:""
            set_plans([])
            set_plans(trainee)
            set_totalCount(count)
        }catch(e){
            console.log(e)
        }
    }
    const getPaginationPlanList = async ()=>{
        try{
            const res = await traineePlanListApi(page,search)
            let trainee = res.data.data.plans
            let count = res.data.data.count
            triggerID = trainee.length>45?trainee[45]._id:""
            set_plans(oldArray => [...oldArray, ...trainee])
            set_totalCount(count)
        }catch(e){
            console.log(e)
        }
    }

    const [visibleRequestPlanDrawer,set_visibleRequestPlanDrawer] = useState(false)

    const showRequestDrawer = ()=>{
        set_visibleRequestPlanDrawer(!visibleRequestPlanDrawer)
    }

    const onRequestDrawerClose = () => {
        set_visibleRequestPlanDrawer(false);
    };

    const handleDoneSendRequest =()=>{
        set_visibleRequestPlanDrawer(false);
        getPlanList()
    }

    return(
        <div className="fix-top-sec">
            <div className="fix-content">
                <DumbbellHeaderTrainee title="لیست برنامه ها" />

                {/*<div className="plan-list-header">*/}
                {/*    <div className="plan-list-header-info">*/}
                {/*        {trainee.first_name?<span className="name">{trainee?.first_name+" "+trainee?.last_name}</span>:null}*/}
                {/*        {trainee.mobile?<span className="mobile">{trainee.mobile}</span>:null}*/}
                {/*    </div>*/}

                {/*    <div className="plan-list-header-image">*/}
                {/*        <img src={sampleFace} alt=""/>*/}
                {/*    </div>*/}

                {/*</div>*/}
            </div>

            <div className="plan-list">
                {plans.map(p=>{
                    return(
                        <PlanCard trainee={my}   plan={p} getPlanList={getPlanList} getPaginationPlanList={getPaginationPlanList}/>
                    )
                })}
            </div>


            {/*<div className="float-add-btn" onClick={showRequestDrawer}>*/}
            {/*    <span>درخواست برنامه</span>*/}
            {/*    <img src={plusWhiteIcon} alt=""/>*/}
            {/*</div>*/}


        </div>
    )
}

export default DumbbellPlanListTrainee;
