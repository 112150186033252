import React,{useState,useEffect} from "react"
import "./AdminTrainerAdd.scss";
import {useDispatchLoading} from "../../Hook/useDispatchLoading";
import {message, Switch} from "antd";
import {addTrainer_api} from "../../Services/Services";
import moment from "moment-jalaali";

function AdminTrainerAdd({handleDone}){

    const [fName,set_fName] =useState("")
    const [lName,set_lName] =useState("")
    const [mobile,set_mobile] =useState("")
    const [email,set_email] =useState("")
    const [pageName,set_pageName] =useState("")
    const [welcomeLink,set_welcomeLink] =useState("")
    const [maxVideos,set_maxVideos] =useState(100)
    const [maxProduct,set_maxProduct] =useState(100)
    const [planCommission,set_planCommission] =useState(4000)
    const [bookCommission,set_bookCommission] =useState(20000)
    const [buyCommission,set_buyCommission] =useState(20000)
    const [shopCommission,set_shopCommission] =useState(20000)
    const [planMaxCount,set_planMaxCount] =useState(1000)
    const [bookMaxCount,set_bookMaxCount] =useState(1000)
    const [buyMaxCount,set_buyMaxCount] =useState(1000)
    const [shopMaxCount,set_shopMaxCount] =useState(1000)
    const [endDate,set_endDate] =useState("1405/02/01")
    const [mainSpin,set_mainSpin] =useState(false)

    const [onlinePlanService,set_onlinePlanService] =useState(true)
    const [onlineBookingService,set_onlineBookingService] =useState(true)
    const [onlineOnlineShopService,set_onlineOnlineShopService] =useState(true)


    const handleAddTrainer = async ()=>{
        set_mainSpin(true)
        if(
            fName.length<1 ||
            lName.length<1 ||
            mobile.length<1 ||
            pageName.length<1 ||
            welcomeLink.length<1||
            endDate.length<1
        ){
            set_mainSpin(false)
            return message.error({
                content: "لطفا فرم را کامل نمایید",
                className: 'toast-class'
            });
        }

        let sObj = []
        if(onlinePlanService)
            sObj.push('plan')
        if(onlineBookingService)
            sObj.push('booking')
        if(onlineOnlineShopService)
            sObj.push('shop')


        let obj = {
            first_name:fName,
            last_name:lName,
            mobile:mobile,
            email:email,
            slug:pageName,
            services:sObj,
            welcome_link:welcomeLink,
            maxVideos:maxVideos,
            maxProduct:maxProduct,
            planCommission:planCommission,
            bookCommission:bookCommission,
            buyCommission:buyCommission,
            planMaxCount:planMaxCount,
            bookMaxCount:bookMaxCount,
            buyMaxCount:buyMaxCount,
            endDate:moment(endDate,'jYYYY/jMM/jDD').format('YYYY/MM/DD')
        }



        try{
            await addTrainer_api(obj)
            handleDone()
             message.success({
                content: "مربی ثبت شد",
                className: 'toast-class'
            });
            set_mainSpin(false)
        }catch (e) {
            message.error({
                content: "مشکل در ثبت مربی",
                className: 'toast-class'
            });
            set_mainSpin(false)
            console.log(e)
        }
    }

    return(
        <>
            {!mainSpin?
                <div className="admin-trainer-add-container">

                    <div className="admin-trainer-add-form">

                        <div className="admin-trainer-add-item">
                            <input
                                value={fName}
                                onChange={e=>set_fName(e.target.value)}
                                type="text" name="" id=""/>
                            <span>نام</span>
                        </div>
                        <div className="admin-trainer-add-item">
                            <input
                                value={lName}
                                onChange={e=>set_lName(e.target.value)}
                                type="text" name="" id=""/>
                            <span>نام خانوادگی</span>
                        </div>

                        <div className="admin-trainer-add-item">
                            <input
                                value={mobile}
                                pattern="[0-9]*"
                                onChange={e=>set_mobile(e.target.value)}
                                type="tel" name="" id=""/>
                            <span>موبایل</span>
                        </div>
                        <div className="admin-trainer-add-item">
                            <input
                                value={email}
                                pattern="[0-9]*"
                                onChange={e=>set_email(e.target.value)}
                                type="tel" name="" id=""/>
                            <span>ایمیل</span>
                        </div>

                        <div className="admin-trainer-add-item">
                            <input
                                value={pageName}
                                onChange={e=>set_pageName(e.target.value)}
                                type="text" name="" id=""/>
                            <span>نام صفحه</span>
                        </div>

                        <div className="admin-trainer-add-item">
                            <input
                                value={welcomeLink}
                                onChange={e=>set_welcomeLink(e.target.value)}
                                type="text" name="" id=""/>
                            <span>لینک خوش آمد</span>
                        </div>

                        <div className="admin-trainer-detail-services">
                            <div className="admin-trainer-detail-services-row">
                                <Switch defaultChecked checked={onlinePlanService}  onChange={e=>{
                                    set_onlinePlanService(e)
                                }} />
                                <span>برنامه آنلاین</span>
                            </div>

                            <div className="admin-trainer-detail-services-row">
                                <Switch defaultChecked checked={onlineBookingService}   onChange={e=>{
                                    set_onlineBookingService(e)
                                }} />
                                <span>نوبت دهی</span>
                            </div>
                            <div className="admin-trainer-detail-services-row">
                                <Switch defaultChecked checked={onlineOnlineShopService}   onChange={e=>{
                                    set_onlineOnlineShopService(e)
                                }} />
                                <span>فروشگاه</span>
                            </div>
                        </div>

                        <div className="admin-trainer-add-item">
                            <input
                                value={maxVideos}
                                pattern="[0-9]*"
                                onChange={e=>set_maxVideos(e.target.value)}
                                type="text" name="" id=""/>
                            <span>ماکزیمم ویدیو</span>
                        </div>

                        <div className="admin-trainer-add-item">
                            <input
                                value={maxProduct}
                                pattern="[0-9]*"
                                onChange={e=>set_maxProduct(e.target.value)}
                                type="text" name="" id=""/>
                            <span>ماکزیمم محصول </span>
                        </div>


                        <div className="admin-trainer-add-item">
                            <input
                                value={planCommission}
                                pattern="[0-9]*"
                                onChange={e=>set_planCommission(e.target.value)}
                                type="text" name="" id=""/>
                            <span>کارمزد هر پلن(تومان)</span>
                        </div>

                        <div className="admin-trainer-add-item">
                            <input
                                value={planMaxCount}
                                pattern="[0-9]*"
                                onChange={e=>set_planMaxCount(e.target.value)}
                                type="text" name="" id=""/>
                            <span>ماکزیمم پلن</span>
                        </div>



                        <div className="admin-trainer-add-item">
                            <input
                                value={buyCommission}
                                pattern="[0-9]*"
                                onChange={e=>set_buyCommission(e.target.value)}
                                type="text" name="" id=""/>
                            <span>کارمزد هر خرید(تومان)</span>
                        </div>


                        <div className="admin-trainer-add-item">
                            <input
                                value={buyMaxCount}
                                pattern="[0-9]*"
                                onChange={e=>set_buyMaxCount(e.target.value)}
                                type="text" name="" id=""/>
                            <span>ماکزیمم خرید</span>
                        </div>

                        <div className="admin-trainer-add-item">
                            <input
                                value={bookCommission}
                                pattern="[0-9]*"
                                onChange={e=>set_bookCommission(e.target.value)}
                                type="text" name="" id=""/>
                            <span>کارمزد هر رزرو(تومان)</span>
                        </div>

                        <div className="admin-trainer-add-item">
                            <input
                                value={bookMaxCount}
                                pattern="[0-9]*"
                                onChange={e=>set_bookMaxCount(e.target.value)}
                                type="text" name="" id=""/>
                            <span>ماکزیمم رزرو</span>
                        </div>



                        <div className="admin-trainer-add-item">
                            <input
                                value={bookMaxCount}
                                pattern="[0-9]*"
                                onChange={e=>set_bookMaxCount(e.target.value)}
                                type="text" name="" id=""/>
                            <span>ماکزیمم رزرو</span>
                        </div>


                        <div className="admin-trainer-add-item">
                            <input
                                value={endDate}
                                onChange={e=>set_endDate(e.target.value)}
                                type="text" name="" id=""/>
                            <span>پایان اعتبار</span>
                        </div>
                    </div>

                    <div className="admin-trainer-add-btn clickable" onClick={e=>{
                        handleAddTrainer()
                    }}>
                        <span>ثبت </span>
                    </div>

                </div>:
                <div className="menu-loading">
                    <span style={{width:40,height:40,border:"1px solid #8d89fd", borderBottomColor: "transparent"}} className="loader"></span>
                </div>
            }
            </>

    )
}

export default AdminTrainerAdd;
