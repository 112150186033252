import React, {useState, useEffect, Fragment} from "react";
import DumbbellHeader from "../../Components/DumbbellHeder/DumbbellHeder";
import DumbbellBoxTrainee from "../DumbbellBoxTrainee/DumbbellBoxTrainee";
import {Drawer, Spin} from "antd";
import plusWhiteIcon from "../../assets/dumbbell_image/plus-white-icon.png";
import DumbbellAddPackage from "../DumbbellAddPackage/DumbbellAddPackage";
import {deletePackage_api, listPackage_api} from "../../Services/Services";
import "./Package.scss";
import trashWhiteIcon from "../../assets/dumbbell_image/trash_white_icon.png";
import {numberWithCommas} from "../../Services/utils";
import {useDispatchLoading} from "../../Hook/useDispatchLoading";

function PackageCard({item,fetchList,editPack}){
    const [cardSpin,set_cardSpin] = useState(false)
    const deletePackage = async ()=>{
        set_cardSpin(true)
        try{
             await deletePackage_api(item._id)
            set_cardSpin(false)
            fetchList()
        }catch (e) {
            set_cardSpin(false)
            console.log(e)
        }
    }

    return(
        <Fragment>
            <Spin spinning={cardSpin}>
                <div className="package-card">
                    <div className="package-card-header">
                        <h6>{item.title}</h6>
                        <img onClick={deletePackage} className="clickable" src={trashWhiteIcon} alt=""/>
                    </div>
                    <div className="package-card-des">
                        {item.descriptions.map(d=>{
                            return(
                                <div className="package-card-des-item">
                                    <div className="package-card-des-item-label">
                                        <span>{d.text}</span>
                                    </div>
                                    <div className="package-card-des-item-bullet"></div>
                                </div>
                            )
                        })}
                        {item.delivered_type==="instant"?
                            <div className="package-card-des-item">
                                <div className="package-card-des-item-label">
                                    <span>{`تحویل پکیج بطور آنی بعد از پرداخت`}</span>
                                </div>
                                <div className="package-card-des-item-bullet"></div>
                            </div>:null
                        }

                        {item.delivered_type==="default"?
                            <div className="package-card-des-item">
                                <div className="package-card-des-item-label">
                                    <span>{`حداکثر زمان آماده سازی ${item.delivery_estimate} روز میباشد`}</span>
                                </div>
                                <div className="package-card-des-item-bullet"></div>
                            </div>:null
                        }

                        <div className="package-card-des-item">
                            <div className="package-card-des-item-label">
                                <span>{`طول مدت پکیج ${item.duration} روز میباشد`}</span>
                            </div>
                            <div className="package-card-des-item-bullet"></div>
                        </div>

                        {item.chat_option?
                            <div className="package-card-des-item">
                                <div className="package-card-des-item-label">
                                    <span>قابلیت ارتباط با مربی از طریق چت</span>
                                </div>
                                <div className="package-card-des-item-bullet"></div>
                            </div>:null
                        }

                        {item.box_option?
                            <div className="package-card-des-item">
                                <div className="package-card-des-item-label">
                                    <span>قابلیت تبادل عکس با مربی از طریق چت</span>
                                </div>
                                <div className="package-card-des-item-bullet"></div>
                            </div>:null
                        }

                    </div>

                    <div className="package-card-price">

                        <span className="package-card-pay_amount">{numberWithCommas(item.payable_amount)}تومان </span>
                        {item.amount-item.payable_amount>0?
                            <span className="package-card-amount">{numberWithCommas(item.amount)}تومان </span>:
                            null
                        }
                        <span className="package-card-extra_label">{item.extra_label} </span>
                    </div>

                    <div className="package-card-btn clickable" onClick={e=>{
                        editPack(item)
                    }}>
                        <span>ویرایش پکیج</span>
                    </div>

                </div>
            </Spin>
        </Fragment>
    )
}

function Package (){

    const [data,set_data] = useState([])
    const setLoader = useDispatchLoading()

    const getPackages = async ()=>{
        setLoader(true)
        try{
            const resp = await listPackage_api()
            setLoader(false)
            set_data(resp.data.data)
        }catch (e) {
            setLoader(false)
            console.log(e)
        }
    }

    useEffect(()=>{
        getPackages()
    },[])

    //drawer
    const [visibleAddPackageDrawer,set_visibleAddPackageDrawer] = useState(false)

    const showBoxDrawer = ()=>{
        set_visibleAddPackageDrawer(!visibleAddPackageDrawer)
    }

    const onAddPackageDrawerClose = () => {
        set_visibleAddPackageDrawer(false);
    };

    const handleDoneSendRequest =()=>{
        onAddPackageDrawerClose()
        getPackages()
    }

    const [currentEdit,set_currentEdit] = useState(null)
    const editPack =(pack)=>{
        set_currentEdit(pack)
        showBoxDrawer()
    }

    return(
        <>
            <DumbbellHeader title="پکیج ها"/>
            <div className="package-page">
                <div className="package-list">
                    {data.map(d=>{
                        return(
                            <PackageCard editPack={editPack} item={d} fetchList={getPackages}/>
                        )
                    })}
                </div>
            </div>

            <div className="float-add-btn-v2 clickable" onClick={e=>{
                set_currentEdit(null)
                showBoxDrawer()
            }}>
                <img src={plusWhiteIcon} alt=""/>
            </div>

            <Drawer push={false}  bodyStyle={{padding:0,margin:0}}  width={300} closable={false}  onClose={onAddPackageDrawerClose} visible={visibleAddPackageDrawer}>
                <DumbbellAddPackage edit={currentEdit} handleDoneSendRequest={handleDoneSendRequest}/>
            </Drawer>
            </>
    )

}

export default Package