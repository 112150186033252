import React, {Fragment, useEffect, useState} from 'react';
import "./DumbbellCallback.scss";
import loginImage from  "../../assets/dumbbell_image/login.png";
import logoWhite from  "../../assets/dumbbell_image/logo-white.png";
import mobileIcon from  "../../assets/dumbbell_image/mobile_icon.png";
import {Spin,message} from "antd";
import {loginApi, verifyInvoiceApi, viewInvoiceApi, viewInvoiceAuthorityApi} from "../../Services/Services";
import "antd/dist/antd.css"
import {useHistory,useLocation} from "react-router";
import {numberWithCommas, validation} from "../../Services/utils";
import moment from "moment-jalaali";

function DumbbellCallback(){

    const search = useLocation().search;
    const authority=new URLSearchParams(search).get("Authority");
    const status=new URLSearchParams(search).get("Status");

    useEffect(()=>{
        document.body.style.backgroundColor = "#8A86FE";
    },[])

    const history = useHistory()
    const [spin,set_spin] = useState(true)
    const [mode,set_mode] = useState("default") //default - invoice - failed - success
    const [data,set_data] = useState({})

    useEffect(()=>{
        console.log(authority)
        console.log(status)
        getInvoice()
    },[])


    const getInvoice = async ()=>{
        try{
            const resp = await viewInvoiceAuthorityApi(authority)
            set_data(resp.data.data)
            set_mode("invoice")
            if(status==="OK" && authority.length>5){
                verifyProcess(resp.data.data)
            }else {
                set_spin(false)
                set_mode("failed")
            }
        }catch (e) {
            console.log(e)
        }
    }


    const verifyProcess = async (d)=>{
        try{
            if(d.plan.pay_status!=="payed")
                await verifyInvoiceApi(authority)

            set_mode("success")
            set_spin(false)
        }catch (e) {
            set_spin(false)
            set_mode("failed")
        }
    }

    return(
        <div className="dumbbell-login-container">

            <Spin spinning={spin}>

                <div className="dumbbell-login-default">

                    <div className="dumbbell-invoice-page callback-page">

                        <div className="dumbbell-invoice-page-title">
                            <span>{mode==="success"?"پرداخت شد":""}</span>
                            <span>{mode==="failed"?"پرداخت ناموفق":""}</span>
                        </div>

                        {mode!=="default" ?<Fragment>
                            <div className="dumbbell-invoice-page-form">

                                <div className="dumbbell-invoice-page-form-row">
                                    <span className="dumbbell-invoice-page-form-value">{data?.plan?.title}</span>
                                    <span className="dumbbell-invoice-page-form-label">عنوان برنامه </span>
                                </div>

                                <div className="dumbbell-invoice-page-form-row">
                                    <span className="dumbbell-invoice-page-form-value">{data?.trainer?.first_name+" "+data?.trainer?.last_name}</span>
                                    <span className="dumbbell-invoice-page-form-label">مربی</span>
                                </div>

                                <div className="dumbbell-invoice-page-form-row">
                                    <span className="dumbbell-invoice-page-form-value">{data?.trainee?.first_name+" "+data?.trainee?.last_name}</span>
                                    <span className="dumbbell-invoice-page-form-label">شاگرد</span>
                                </div>

                                {data?.plan?.delivery_type==="instant"?
                                    <div className="dumbbell-invoice-page-form-row">
                                        <span className="dumbbell-invoice-page-form-value">{data?.plan?.delivery_estimate}</span>
                                        <span className="dumbbell-invoice-page-form-label">حداکثر زمان تحویل(روز)</span>
                                    </div>:null
                                }
                                {data?.plan?.delivery_type==="default"?
                                    <div className="dumbbell-invoice-page-form-row">
                                        <span className="dumbbell-invoice-page-form-value">سریع , بعد از پرداخت</span>
                                        <span className="dumbbell-invoice-page-form-label">حداکثر زمان تحویل</span>
                                    </div>:null
                                }


                                <div className="dumbbell-invoice-page-form-row">
                                    <span className="dumbbell-invoice-page-form-value">{numberWithCommas(data?.plan?.amount)}  تومان</span>
                                    <span className="dumbbell-invoice-page-form-label">مبلغ برنامه</span>
                                </div>

                                <div className="dumbbell-invoice-page-form-row">
                                    <span className="dumbbell-invoice-page-form-value">{numberWithCommas(data?.plan?.discount)}  تومان</span>
                                    <span className="dumbbell-invoice-page-form-label">تخفیف برنامه </span>
                                </div>

                            </div>


                            <div className="dumbbell-invoice-page-payable">
                                <span className="dumbbell-invoice-page-payable-value">{numberWithCommas(data?.plan?.payable_amount)}  تومان</span>
                                <span className="dumbbell-invoice-page-payable-label">مبلغ قابل پرداخت</span>
                            </div>

                        </Fragment>:null}



                        {mode==="success"?
                            <div className="dumbbell-callback-message-success">
                                <span className="dumbbell-callback-message-title">پرداخت شما با موفقیت انجام شد</span>
                                {data?.plan?.delivery_type==="instant"?
                                <span className="dumbbell-callback-message-text">حداکثر تا دقایقی دیگر برنامه توسط {data?.trainer?.first_name+" "+data?.trainer?.last_name} تنظیم شده و برای شما ارسال می شود</span>
                                    :null}
                                {data?.plan?.delivery_type==="default"?
                                    <span className="dumbbell-callback-message-text">حداکثر تا مدت زمان {data?.plan?.delivery_estimate} روز از تاریخ {moment(data?.payedAt).format(" jYYYY/jMM/jDD ")} برنامه توسط {data?.trainer?.first_name+" "+data?.trainer?.last_name} تنظیم شده و برای شما ارسال می شود</span>
                                    :null}
                            </div>:null

                        }

                        {mode==="success"?
                        <div className="dumbbell-callback-message-warning">
                            <span className="dumbbell-callback-message-text"> شما میتوانید با ورود به اپلیکیشن از طریق لینک زیر مراحل آماده شدن برنامه خود را مشاهده نمایید</span>
                        </div>:null}

                        {mode==="failed"?<div className="dumbbell-callback-message-failed">
                            <span className="dumbbell-callback-message-title">پرداخت ناموفق</span>
                        </div>:null}

                        {mode==="success"?
                            <div className="dumbbell-login-btn" onClick={e=>{
                                window.open(`https://localhost:3000/welcome/${data.trainee.welcome_link}`, '_blank');
                            }} >
                                <span>ورود به اپ</span>
                            </div>:null
                        }

                    </div>



                    {data?.plan?.type==="trainer_pay"?
                        <div className="dumbbell-login-btn" >
                            <span>برگشت</span>
                        </div>:null
                    }
                </div>


            </Spin>




        </div>
    )
}

export default DumbbellCallback;
