import React ,{useState,useEffect} from 'react';
import "./DumbbellAddTrainee.scss";
import DumbbellDrawerHeader from "../../Components/DumbbellDrawerHeader/DumbbellDrawerHeader";
import {addTraineeApi, editTraineeApi} from "../../Services/Services";
import {message,Switch } from "antd";
import {validation} from "../../Services/utils";

function DumbbellAddTrainee({fill=null,handleDoneAddTrainee,traineeMode=false}){

    let [first_name,set_first_name] = useState("")
    let [last_name,set_last_name] = useState("")
    let [mobile,set_mobile] = useState("")
    let [email,set_email] = useState("")

    let [blood,set_blood] = useState("")
    let [gender,set_gender] = useState("")
    let [age,set_age] = useState("")
    let [weight,set_weight] = useState("")

    let [sport_background,set_sport_background] = useState("")
    let [disease_background,set_disease_background] = useState("")
    let [supplement_use_background,set_supplement_use_background] = useState("")
    let [drug_use_background,set_drug_use_background] = useState("")


    const [doesNotIranNumber,set_doesNotIranNumber] = useState(false)
    const onChange = (checked) => {
        set_doesNotIranNumber(!doesNotIranNumber)
    };

    useEffect(e=>{
       if(fill){
           fillProcess(fill)
       }
    },[])

    const fillProcess = (f)=>{
        set_first_name(f.first_name)
        set_last_name(f.last_name)
        set_mobile(f.mobile)
        set_email(f.email)
        set_blood(f.profile.blood)
        set_gender(f.profile.gender)
        set_age(f.profile.age)
        set_weight(f.profile.weight)
        set_sport_background(f.profile.sport_background)
        set_disease_background(f.profile.disease_background)
        set_supplement_use_background(f.profile.supplement_use_background)
        set_drug_use_background(f.profile.drug_use_background)
        if(f.email){
            set_doesNotIranNumber(true)
        }
    }

    const resetForm = ()=>{
        set_first_name("")
        set_last_name("")
        set_mobile("")
        set_blood("")
        set_gender("")
        set_age("")
        set_weight("")
        set_sport_background("")
        set_disease_background("")
        set_supplement_use_background("")
        set_drug_use_background("")
    }

    const editTrainee = async ()=>{


        if(doesNotIranNumber && !validation("EMAIL",email)){
            return message.error({
                content: "فرمت ایمیل درست نمیباشد",
                className: 'toast-class'
            });
        }

        if(!doesNotIranNumber && !validation("MOBILE",mobile)){
            return message.error({
                content: "فرمت موبایل درست نمیباشد",
                className: 'toast-class'
            });
        }

        if(first_name.length<2){
            return message.error({
                content: "لطفا نام را وارد نمایید",
                className: 'toast-class'
            });
        }
        if(last_name.length<2){
            return message.error({
                content: "لطفا نام خانوادگی را وارد نمایید",
                className: 'toast-class'
            });
        }





        let obj ={
            first_name: first_name,
            last_name: last_name,
            mobile: mobile,
            email: email,
            profile:{
                blood,
                gender,
                age,
                weight,
                sport_background,
                disease_background,
                supplement_use_background,
                drug_use_background
            }
        }

        try{
            await editTraineeApi(obj,fill._id)
            // resetForm()
            message.success({
                content: "شاگرد ویرایش شد",
                className: 'toast-class'
            });

            // handleDoneAddTrainee()
        }catch (e) {
            console.log(e.response)
            if (e.response?.data.message==="trainee is exist"){
                return message.error({
                    content: "مشکلی در ویرایش شاگرد بوجود آمده است.",
                    className: 'toast-class'
                });
            }else {
                return message.error({
                    content: "مشکلی در ویرایش شاگرد بوجود آمده است",
                    className: 'toast-class'
                });
            }

        }
    }


    const addTrainee = async ()=>{


        if(doesNotIranNumber && !validation("EMAIL",email)){
            return message.error({
                content: "فرمت ایمیل درست نمیباشد",
                className: 'toast-class'
            });
        }

        if(!doesNotIranNumber && !validation("MOBILE",mobile)){
            return message.error({
                content: "فرمت موبایل درست نمیباشد",
                className: 'toast-class'
            });
        }

        if(first_name.length<2){
            return message.error({
                content: "لطفا نام را وارد نمایید",
                className: 'toast-class'
            });
        }
        if(last_name.length<2){
            return message.error({
                content: "لطفا نام خانوادگی را وارد نمایید",
                className: 'toast-class'
            });
        }





        let obj ={
            first_name: first_name,
            last_name: last_name,
            mobile: mobile,
            email: email,
            profile:{
                blood,
                gender,
                age,
                weight,
                sport_background,
                disease_background,
                supplement_use_background,
                drug_use_background
            }
        }

        try{
             await addTraineeApi(obj)
            resetForm()
             message.success({
                content: "شاگرد ایجاد شد",
                className: 'toast-class'
            });

             handleDoneAddTrainee()
        }catch (e) {
            console.log(e.response)
            if (e.response?.data.message==="trainee is exist"){
                return message.error({
                    content: "این شماره در لیست شاگردان شما موجود است",
                    className: 'toast-class'
                });
            }else {
                return message.error({
                    content: "مشکلی در ثبت شاگرد بوجود آمده است",
                    className: 'toast-class'
                });
            }

        }
    }


    return(
        <div className="dumbbell-add-trainee-container">

           <DumbbellDrawerHeader title={" پرونده ورزشی"}/>

            <div className="dumbbell-add-trainee-form">

                {!fill?._id?<div className="dumbbell-add-trainee-switch-item">

                    <Switch onChange={onChange} />
                    <span>شماره موبایل ایران ندارد</span>
                </div>:null}


                {doesNotIranNumber?
                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            disabled={traineeMode}
                            placeholder={"مثلا : saman@gmail.com"}
                            value={email}
                            onChange={e=>set_email(e.target.value)}
                            type="text" name="" id=""/>
                        <span>ایمیل</span>
                    </div>
                    :
                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            disabled={traineeMode}
                            placeholder={"مثلا : ******* 0912"}
                            value={mobile}
                            onChange={e=>set_mobile(e.target.value)}
                            type="text" name="" id=""/>
                        <span>شماره موبایل</span>
                    </div>
                }



                <div className="dumbbell-add-trainee-form-item">
                    <input
                        disabled={traineeMode}
                        value={first_name}
                        onChange={e=>set_first_name(e.target.value)}
                        type="text" name="" id=""/>
                    <span>نام</span>
                </div>

                <div className="dumbbell-add-trainee-form-item">
                    <input
                        disabled={traineeMode}
                        value={last_name}
                        onChange={e=>set_last_name(e.target.value)}
                        type="text" name="" id=""/>
                    <span>نام خانوادگی</span>
                </div>





                <div className="dumbbell-add-trainee-form-item">
                    <div className="select-wr">
                        <select
                            style={{minWidth:150}}
                            disabled={traineeMode}
                            value={blood}
                            onChange={e=>set_blood(e.target.value)}
                            name="" id="">
                            <option value="A">A</option>
                            <option value="A+">A+</option>
                            <option value="A-">A-</option>
                            <option value="B">B</option>
                            <option value="B+">B+</option>
                            <option value="B-">B-</option>
                            <option value="AB">AB</option>
                        </select>
                    </div>

                    <span>گروه خون</span>
                </div>

                <div className="dumbbell-add-trainee-form-item">
                    <div className="select-wr">
                        <select
                            style={{minWidth:150}}
                            disabled={traineeMode}
                            value={gender}
                            onChange={e=>set_gender(e.target.value)}
                            name="" id="">
                            <option value="woman">زن</option>
                            <option value="man">مرد</option>
                        </select>
                    </div>
                    <span>جنسیت</span>
                </div>

                <div className="dumbbell-add-trainee-form-item">
                    <input
                        disabled={traineeMode}
                        value={age}
                        onChange={e=>set_age(e.target.value)}
                        type="text" name="" id=""/>
                    <span>حدود سن</span>
                </div>

                <div className="dumbbell-add-trainee-form-item">
                    <input
                        disabled={traineeMode}
                        value={weight}
                        onChange={e=>set_weight(e.target.value)}
                        type="text" name="" id=""/>
                    <span>حدود وزن</span>
                </div>

                {/*<div className="dumbbell-add-trainee-form-item">*/}
                {/*    <textarea*/}
                {/*        value={target}*/}
                {/*        onChange={e=>set_target(e.target.value)}*/}
                {/*    ></textarea>*/}
                {/*    <span>هدف شما از برنامه</span>*/}
                {/*</div>*/}


                <div className="dumbbell-add-trainee-form-item">
                    <textarea
                        disabled={traineeMode}
                        value={sport_background}
                        onChange={e=>set_sport_background(e.target.value)}
                    ></textarea>
                    <span>سابقه ورزشی</span>
                </div>

                <div className="dumbbell-add-trainee-form-item">
                    <textarea
                        disabled={traineeMode}
                        value={disease_background}
                        onChange={e=>set_disease_background(e.target.value)}
                    ></textarea>
                    <span>سابقه بیماری</span>
                </div>

                <div className="dumbbell-add-trainee-form-item">
                    <textarea
                        disabled={traineeMode}
                        value={supplement_use_background}
                        onChange={e=>set_supplement_use_background(e.target.value)}
                    ></textarea>
                    <span>سابقه مصرف مکمل ورزشی</span>
                </div>

                <div className="dumbbell-add-trainee-form-item">
                    <textarea
                        disabled={traineeMode}
                        value={drug_use_background}
                        onChange={e=>set_drug_use_background(e.target.value)}
                    ></textarea>
                    <span>سابقه مصرف دارو</span>
                </div>


                {/*<div className="dumbbell-add-trainee-form-item">*/}
                {/*    <input type="text" name="" id=""/>*/}
                {/*    <span>تصاویر</span>*/}
                {/*</div>*/}

                {!fill?
                    <div className="dumbbell-add-trainee-form-submit" onClick={e=>{
                        addTrainee()
                    }}>
                        <span>ثبت </span>
                    </div>:null
                }
                {fill && !traineeMode?
                    <div className="dumbbell-add-trainee-form-submit" onClick={e=>{
                        editTrainee()
                    }}>
                        <span>ویرایش </span>
                    </div>:null
                }

            </div>




        </div>
    )
}

export default DumbbellAddTrainee;
