import React, {useState,useEffect,useRef} from 'react';
import "./DumbbellLandingPageAddBookingDrawer.scss";
import DumbbellDrawerHeader from "../../Components/DumbbellDrawerHeader/DumbbellDrawerHeader";
import {message, Spin, Switch} from "antd";
import {convertToEnglishNumbers, numberWithCommas, validation} from "../../Services/utils";
import {
    addTraineeApi,
    payLandBooingApi,
    payLandPlanApi,
    sendOtpRequestApi, sendReceiptLandBookingApi, uploadReceiptLandBookingApi,
    uploadReceiptLandPlanApi
} from "../../Services/Services";
import moment from "moment-jalaali";
import {useDispatchUploadLoading} from "../../Hook/useDispatchUploadLoading";
import { CloudUploadOutlined } from '@ant-design/icons';

function DumbbellLandingPageAddBookingDrawer({slug,trainerId,turn,handleDoneAddTrainee,page}){


    let [first_name,set_first_name] = useState("")
    let [last_name,set_last_name] = useState("")
    let [mobile,set_mobile] = useState("")
    let [email,set_email] = useState("")

    const [doesNotIranNumber,set_doesNotIranNumber] = useState(false)
    const onChange = (checked) => {
        set_doesNotIranNumber(!doesNotIranNumber)
    };

    const [counter,set_counter] = useState(0)
    const runCountDown = ()=>{
        set_counter(60)
        setInterval(()=>{
            set_counter(c=>c-1)
        },1000)
    }

    const [payType,set_payType] = useState("online") //online , receipt


    let [blood,set_blood] = useState("")
    let [gender,set_gender] = useState("")
    let [age,set_age] = useState("")
    let [weight,set_weight] = useState("")

    let [sport_background,set_sport_background] = useState("")
    let [disease_background,set_disease_background] = useState("")
    let [supplement_use_background,set_supplement_use_background] = useState("")
    let [drug_use_background,set_drug_use_background] = useState("")
    const setUploadLoading = useDispatchUploadLoading()


    const sendBookingReceipt = async ()=>{

        if(!doesNotIranNumber && !validation("MOBILE",mobile)){
            return message.error({
                content: "فرمت موبایل درست نمیباشد",
                className: 'toast-class'
            });
        }

        if(doesNotIranNumber && !validation("EMAIL",email)){
            return message.error({
                content: "فرمت ایمیل درست نمیباشد",
                className: 'toast-class'
            });
        }


        if(payType==="receipt" && !imageFile){
            return message.error({
                content: "لطفا تصویر رسید را بارگذاری نمایید",
                className: 'toast-class'
            });
        }

        if(first_name.length<2){
            return message.error({
                content: "لطفا نام را وارد نمایید",
                className: 'toast-class'
            });
        }
        if(last_name.length<2){
            return message.error({
                content: "لطفا نام خانوادگی را وارد نمایید",
                className: 'toast-class'
            });
        }


        if(otpCode.length<4){
            return message.error({
                content: "لطفا کد تایید را وارد نمایید",
                className: 'toast-class'
            });
        }

        let obj ={
            first_name: first_name,
            last_name: last_name,
            mobile: mobile,
            email:email,
            otp_code:otpCode,
            auth_type:doesNotIranNumber?"email":"mobile",
            turn:turn,
            slug:slug,
            trainerId:trainerId
        }

        try{
            let response = await sendReceiptLandBookingApi(obj)
            if(imageFile){
                let upload_payload ={
                    image:imageFile,
                    bookingId:response.data.data._id,
                    trainerId:trainerId
                }
                await uploadReceiptLandBookingApi(upload_payload,set_linkUploadProgress)
                setUploadLoading({visible:false,percent:0})
                set_linkUploadProgress(0)
                message.success({
                    content: "رسید شما ارسال شد",
                    className: 'toast-class'
                });
                window.location.href=`https://${window.location.host}/track/booking/${response.data.data.trackId}`
            }
            handleDoneAddTrainee()
        }catch (e) {
            setUploadLoading({visible:false,percent:0})
            set_linkUploadProgress(0)
            console.log(e)
            if(e.response.data.message==="validation error"){
                return message.error({
                    content: "لطفا اطلاعات را بصورت صحیح وارد نمایید",
                    className: 'toast-class'
                });
            }
            if(e.response.data.message==="OTP not found" ||
                e.response.data.message==="OTP has expired" ||
                e.response.data.message==="OTP is incorrect"
            ){
                return message.error({
                    content: "کد تایید قابل قبول نمیباشد",
                    className: 'toast-class'
                });
            }
            if(e.response.data.message==="turn is fill" || e.response.data.message ==="error on booked"){
                return message.error({
                    content: "این نوبت در دسترس نیست",
                    className: 'toast-class'
                });
            }
            return message.error({
                content: "مشکلی بوجود آمده است",
                className: 'toast-class'
            });
        }
    }



    const payPlan = async ()=>{

        if(!doesNotIranNumber && !validation("MOBILE",mobile)){
            return message.error({
                content: "فرمت موبایل درست نمیباشد",
                className: 'toast-class'
            });
        }

        if(doesNotIranNumber && !validation("EMAIL",email)){
            return message.error({
                content: "فرمت ایمیل درست نمیباشد",
                className: 'toast-class'
            });
        }


        if(payType==="receipt" && !imageFile){
            return message.error({
                content: "لطفا تصویر رسید را بارگذاری نمایید",
                className: 'toast-class'
            });
        }

        if(first_name.length<2){
            return message.error({
                content: "لطفا نام را وارد نمایید",
                className: 'toast-class'
            });
        }
        if(last_name.length<2){
            return message.error({
                content: "لطفا نام خانوادگی را وارد نمایید",
                className: 'toast-class'
            });
        }


        if(otpCode.length<4){
            return message.error({
                content: "لطفا کد تایید را وارد نمایید",
                className: 'toast-class'
            });
        }


        let obj ={
            first_name: first_name,
            last_name: last_name,
            mobile: mobile,
            otp_code:otpCode,
            auth_type:doesNotIranNumber?"email":"mobile",
            email:email,
            turn:turn,
            trainerId:trainerId
        }

        try{
            let response = await payLandBooingApi(obj)
            window.open(response.data.data.url, '_self');
            handleDoneAddTrainee()
        }catch (e) {
            console.log(e)
            if(e.response.data.message==="validation error"){
                return message.error({
                    content: "لطفا اطلاعات را بصورت صحیح وارد نمایید",
                    className: 'toast-class'
                });
            }
            if(e.response.data.message==="OTP not found" ||
                e.response.data.message==="OTP has expired" ||
                e.response.data.message==="OTP is incorrect"
            ){
                return message.error({
                    content: "کد تایید قابل قبول نمیباشد",
                    className: 'toast-class'
                });
            }
            if(e.response.data.message==="turn is fill" || e.response.data.message ==="error on booked"){
                return message.error({
                    content: "این نوبت در دسترس نیست",
                    className: 'toast-class'
                });
            }
            return message.error({
                content: "مشکلی بوجود آمده است",
                className: 'toast-class'
            });
        }
    }

    //otp

    let [otpSpin,set_otpSpin] = useState(false)
    let [otpCode,set_otpCode] = useState("")

    const [imageFile,set_imageFile] = useState(null)
    const fileRef = useRef(null);
    const [imagePrev,set_imagePrev] = useState(null);

    useEffect(() => {
        if (!imageFile) {
            set_imagePrev(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(imageFile)
        set_imagePrev(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [imageFile])

    const handleImageChange = (e)=>{
        if(e.target.files && e.target.files[0]){
            set_imageFile(e.target.files[0])
        }
    }

    const sendOtpCode = async ()=>{
        let obj =  {}

        if(!doesNotIranNumber && !validation("MOBILE",mobile)){
            return message.error({
                content: "فرمت موبایل درست نمیباشد",
                className: 'toast-class'
            });
        }

        if(doesNotIranNumber && !validation("EMAIL",email)){
            return message.error({
                content: "فرمت ایمیل درست نمیباشد",
                className: 'toast-class'
            });
        }


        if(!doesNotIranNumber){
            obj['phoneNumber'] = mobile
            obj['type'] = "mobile"
        }
        if(doesNotIranNumber){
            obj['email'] = email
            obj['type'] = "email"
        }


        set_otpSpin(true)

        try{
            await sendOtpRequestApi(obj)
            set_otpSpin(false)
            runCountDown()
            return message.success({
                content: `کد تایید برای شما ارسال شد`,
                className: 'toast-class'
            });
        }catch(e){
            set_otpSpin(false)
            return message.error({
                content: "مشکل در ارسال کد تایید",
                className: 'toast-class'
            });
        }
    }

    const [linkUploadProgress,set_linkUploadProgress] = useState(0)

    useEffect(()=>{
        if(linkUploadProgress>0){
            if(linkUploadProgress>80)
                setUploadLoading({visible:true,percent:linkUploadProgress,title:"در حال فشرده سازی و ذخیره تصویر  . . ."})
            else
                setUploadLoading({visible:true,percent:linkUploadProgress,title:"در حال آپلود تصویر  . . ."})
        }
    },[linkUploadProgress])


    return(
        <div className="dumbbell-add-trainee-container">

            <DumbbellDrawerHeader title={"رزرو وقت حضوری"}/>



            <div className="dumbbell-add-trainee-form">

                <div className="dumbbell-add-trainee-switch-item">

                    <Switch onChange={onChange} />
                    <span>شماره موبایل ایران ندارم</span>
                </div>

                {!doesNotIranNumber?<div className="dumbbell-add-trainee-form-item land-input">
                    <input
                        value={mobile}
                        onChange={e=>set_mobile(e.target.value)}
                        type="text" name="" id="" placeholder="شماره موبایل"/>
                </div>:null}

                {doesNotIranNumber?<div className="dumbbell-add-trainee-form-item land-input">
                    <input
                        value={email}
                        onChange={e=>set_email(e.target.value)}
                        type="text" name="" id="" placeholder="ایمیل"/>
                </div>:null}


                <div className="dumbbell-add-trainee-form-item land-input">
                    <input
                        value={first_name}
                        onChange={e=>set_first_name(e.target.value)}
                        type="text" name="" id="" placeholder="نام"/>
                </div>

                <div className="dumbbell-add-trainee-form-item land-input">
                    <input
                        value={last_name}
                        onChange={e=>set_last_name(e.target.value)}
                        type="text" name="" id="" placeholder="نام خانوادگی"/>

                </div>


                <Spin spinning={otpSpin}>
                    <div className="dumbbell-invoice-page-form-verify-mobile">
                        <input type="text"
                               value={otpCode}
                               onChange={e=>set_otpCode(e.target.value)}
                               placeholder={"کد تایید 6 رقمی"}/>
                        <div className="dumbbell-invoice-page-form-verify-btn">
                            {counter>0?
                                <h5>{counter}</h5>:
                                <>
                                    {doesNotIranNumber?
                                        <h5 className="clickable" onClick={sendOtpCode}>دریافت کد تایید ایمیل</h5>:
                                        <h5 className="clickable" onClick={sendOtpCode}>دریافت کد تایید موبایل</h5>
                                    }

                                </>
                            }
                        </div>


                        {doesNotIranNumber?
                            <p>کد تایید 6 رقمی ارسال شده به شماره ایمیل وارد شده را در این قسمت وارد نمایید</p>:
                            <p>کد تایید 6 رقمی ارسال شده به شماره موبایل وارد شده را در این قسمت وارد نمایید</p>
                        }
                    </div>
                </Spin>

                {/*<div className="dumbbell-add-trainee-form-item land-input">*/}
                {/*    <input*/}
                {/*        pattern="[0-9]*"*/}
                {/*        inputMode="numeric"*/}
                {/*        onChange={e=>set_mobile(convertToEnglishNumbers(e.target.value))}*/}
                {/*        value={mobile}*/}
                {/*        type="text" name="" id="" placeholder="شماره موبایل"/>*/}
                {/*</div>*/}



                {/*<div className="dumbbell-add-trainee-form-item land-input">*/}
                {/*    <input*/}
                {/*        value={email}*/}
                {/*        onChange={e=>set_email(e.target.value)}*/}
                {/*        type="text" name="" id="" placeholder="ایمیل"/>*/}
                {/*</div>*/}



                {/*<div className="dumbbell-add-trainee-form-submit" onClick={e=>{*/}
                {/*    addTrainee()*/}
                {/*}}>*/}
                {/*    <span>ایجاد پرونده ورزشی و پرداخت</span>*/}
                {/*</div>*/}

            </div>





            <div className="dumbbell-invoice-page-form">

                <div className="dumbbell-invoice-page-form-row">
                    <span className="dumbbell-invoice-page-form-value">{moment(turn.date,"YYYY-MM-DD").format('jYYYY/jMM/jDD')}</span>
                    <span className="dumbbell-invoice-page-form-label">تاریخ رزرو</span>
                </div>

                <div className="dumbbell-invoice-page-form-row">
                    <span className="dumbbell-invoice-page-form-value">{turn?.startAt}</span>
                    <span className="dumbbell-invoice-page-form-label">ساعت شروع</span>
                </div>

                <div className="dumbbell-invoice-page-form-row">
                    <span className="dumbbell-invoice-page-form-value">{turn?.endAt}</span>
                    <span className="dumbbell-invoice-page-form-label">ساعت پایان</span>
                </div>


                <div className="dumbbell-invoice-page-form-row">
                    <span className="dumbbell-invoice-page-form-value">{numberWithCommas(turn?.cost)}  تومان</span>
                    <span className="dumbbell-invoice-page-form-label">مبلغ سرویس</span>
                </div>

                <div className="dumbbell-invoice-page-form-row">
                    <span className="dumbbell-invoice-page-form-value">{numberWithCommas(turn?.pre_cost)}  تومان</span>
                    <span className="dumbbell-invoice-page-form-label">هزینه رزرو سرویس</span>
                </div>

                {/*<div className="dumbbell-invoice-page-form-row">*/}
                {/*    <span className="dumbbell-invoice-page-form-value">{}  تومان</span>*/}
                {/*    <span className="dumbbell-invoice-page-form-label">تخفیف برنامه </span>*/}
                {/*</div>*/}

            </div>

            <div className="dumbbell-invoice-page-payable">
                <span className="dumbbell-invoice-page-payable-value">{numberWithCommas(turn?.pre_cost)}  تومان</span>
                <span className="dumbbell-invoice-page-payable-label">مبلغ قابل پرداخت</span>
            </div>


            <div className="dumbbell-invoice-page-pay-type">
                <h5>نوع پرداخت</h5>
                <div className="dumbbell-invoice-page-pay-type-options">
                    <div className="dumbbell-invoice-page-pay-type-item" onClick={e=>set_payType("receipt")}>
                        <span>رسید</span>
                        <input checked={payType==="receipt"}  type="radio" name="pay-options" id=""/>
                    </div>
                    <div className="dumbbell-invoice-page-pay-type-item" onClick={e=>set_payType("online")}>
                        <span>درگاه آنلاین</span>
                        <input checked={payType==="online"} type="radio" name="pay-options" id=""/>
                    </div>
                </div>
            </div>

            {payType==="receipt"?
                <>

                    {page.bank_status?<div className="cart-invoice-owner-pay-info" style={{margin:"8px 16px"}}>
                        <h5>{page?.bank_card_number}</h5>
                        <div className="cart-invoice-owner-pay-info-footer">
                            <span>کارت بانکی</span>
                            <span>{page?.bank_card_owner}</span>
                        </div>
                    </div>:null}

                    {page.wallet_status?<div className="cart-invoice-owner-wallet-info" style={{margin:"8px 16px"}}>
                        <h5>{page?.wallet_number}</h5>
                        <div className="cart-invoice-owner-pay-info-footer">
                            <span>آدرس ولت</span>
                            <span>{""}</span>
                        </div>
                    </div>:null}

                </>
                :null
            }

            {imagePrev?
                <div className="dumbbell-invoice-page-pay-prev clickable" onClick={e=>{
                    fileRef.current.click();
                }}>
                    <img src={imagePrev} alt=""/>
                </div>:null
            }

            {payType==="receipt" && !imagePrev?
                <div className="dumbbell-invoice-page-pay-image clickable" onClick={e=>{
                    fileRef.current.click();
                }}>
                    <CloudUploadOutlined />
                    <span>آپلود تصویر رسید</span>
                </div>:null
            }

            {payType==="receipt"?
                <Spin spinning={false}>
                    <div className="dumbbell-login-btn" onClick={e=>{
                        sendBookingReceipt()
                    }}>
                        <span>ارسال رسید</span>
                    </div>
                </Spin>:null}

            {payType==="online"?
            <Spin spinning={false}>
                <div className="dumbbell-login-btn" onClick={e=>{
                    payPlan()
                }}>
                    <span>پرداخت</span>
                </div>
            </Spin>:null}
            <input type="file" ref={fileRef} name="" style={{visibility: "hidden"}} onChange={handleImageChange} id=""/>
        </div>
    )
}

export default DumbbellLandingPageAddBookingDrawer;
