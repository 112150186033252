import React, { useState } from "react";
import "./AttrInput.scss"

function AttrInput({done}) {
    const [inputValue, setInputValue] = useState("");

    const handleClick = (value) => {
        setInputValue((prev) => prev + value);
    };

    const handleBackspace = () => {
        setInputValue((prev) => prev.slice(0, -1));
    };

    const handleEnter = () => {
        setInputValue("");
        done(inputValue)
    };

    return (
        <div className="attr-container">
            <div className="attr-input">
                <span>{inputValue}</span>
            </div>
            <div className="attr-numLock">
                {["1", "2", "3", "4", "5", "6", "7", "8", "9", "-", "0"].map((num) => (
                    <div
                        key={num}
                        className="attr-numLock-item"
                        onClick={() => handleClick(num)}
                    >
                        {num}
                    </div>
                ))}
                <div className="attr-numLock-item" onClick={handleBackspace}>
                    پاک کردن
                </div>
            </div>
            <div className="attr-enter" onClick={handleEnter}>
                <span>ثبت</span>
            </div>
        </div>
    );
}

export default AttrInput;
