import React, {Fragment, useEffect, useRef, useState} from 'react';
import "./DumbbellMyRequests.scss";
import DumbbellHeader from "../../Components/DumbbellHeder/DumbbellHeder";
import searchIcon from "../../assets/dumbbell_image/V2/search_icon_v2.svg"
import caretDownIcon from "../../assets/dumbbell_image/V2/caret_down_icon.svg"
import chatIcon from "../../assets/dumbbell_image/V2/chat_icon.svg"
import picIcon from "../../assets/dumbbell_image/V2/pic_icon.svg"
import profileIcon from "../../assets/dumbbell_image/V2/profile_icon.svg"
import emptyIcon from "../../assets/dumbbell_image/V2/empty_icon_v2.svg"
import loadingGif from "../../assets/dumbbell_image/V2/page_spinner_v2.gif"

import messageIcon from "../../assets/dumbbell_image/message-icon.png"
import traineeFaceIcon from "../../assets/dumbbell_image/sample-trainee-face.png";
import loadingImage from "../../assets/loading/load_2.gif"
import deleteIcon from "../../assets/card_icon/delete_icon.svg";
import plusWhiteIcon from "../../assets/dumbbell_image/plus-white-icon.png"
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import useWindowDimensions from "../../Hook/useWindowDimensions";
import {Drawer, message, Spin} from "antd";
import DumbbellAddTrainee from "../DumbbellAddTrainee/DumbbellAddTrainee";
import DumbbellChatTrainer from "../DumbbellChatTrainer/DumbbellChatTrainer";
import {useHistory, useLocation, useParams} from "react-router";
import {
    acceptBookingReceiptFromBook_api,
    bookSeenApi, changePlanReceiptStatus_api,
    changeStatus_api,
    changeStatusFromBook_api,
    deleteTraineeApi,
    getMediasApi,
    getTrainerProfileApi,
    getUnreadMessage_api,
    mediaChallengeDeleteApi,
    mediaDeleteApi,
    mediaRedDeleteApi,
    ordersRequestChangeStatusApi,
    ordersRequestListApi,
    ordersRequestSeenApi,
    planSeenApi,
    requestsPlan_api,
    requestsTurn_api,
    seenMediaApi,
    traineeListApi
} from "../../Services/Services";
import defaultUser from "../../assets/images/default_user.png";
import useOnScreen from "../../Components/useOnScreen/useOnScreen";
import DumbbellBoxTrainer from "../DumbbellBoxTrainer/DumbbellBoxTrainer";

import { CodeSandboxOutlined ,UserOutlined} from '@ant-design/icons';
import moment from "moment-jalaali";
import {Setup} from "../../Services/Setup";
import {useDispatchError} from "../../Hook/useDispatchError";
import useLongPress from "../../Hook/useLongPress";
import {checkPlanEndDate, convertMonthNumberToName, numberWithCommas, setAntdDrawerWidth} from "../../Services/utils";
import {Collapse} from "react-collapse";
import sampleFace from "../../assets/images/default_user.png";
import Zoom from "react-medium-image-zoom";

let page = 0;
let search = "";
let type = "all";
let triggerID = "";




function PlanCard({plan,getPaginationPlanList}){

    const [collapseCard,set_collapseCard] = useState(false)
    const [planIsEnd,set_planIsEnd] = useState(false);
    const [p,set_p] =useState(plan)
    //
    const ref = useRef(null)
    const isVisible = useOnScreen(ref)
    const history = useHistory()

    const [pageSeen,set_pageSeen] = useState(false);
    const [currentSeen,set_currentSeen] = useState(p.seen);

    useEffect(()=>{
        set_planIsEnd(checkPlanEndDate(p))
    },[])

    const seen = async (id)=>{
        try{
            await planSeenApi(id);
        }catch(e){
            console.log(e)
        }
    }
    //
    useEffect(()=>{
        if(isVisible && !pageSeen && triggerID===p._id){
            console.log(p._id,"is vis",isVisible)
            set_pageSeen(true)
            page = page+1
            getPaginationPlanList()
        }
        if(!currentSeen && isVisible ){
            console.log(p._id,"seen",isVisible)
            set_currentSeen(true)
            seen(p._id)
            //api call
        }
    },[isVisible])

    const [planCardSpin,set_planCardSpin] = useState(false)
    const [visibleReceiptPlanDrawer,set_visibleReceiptPlanDrawer] = useState(false)
    const onReceiptPlanDrawerClose = ()=>{
        set_visibleReceiptPlanDrawer(false)
    }
    const showReceiptPlanDrawer = ()=>{
        set_visibleReceiptPlanDrawer(true)
        setTimeout(()=>{setAntdDrawerWidth()},0)
    }

    const acceptReceipt = async ()=>{
        set_planCardSpin(true)
        try{
            const res = await changePlanReceiptStatus_api({status:"accepted"},p._id)
            set_p({...p,
                status:res.data.data.status,
                pay_status:res.data.data.pay_status,
                deliveredAt:res.data.data.deliveredAt,
                payedAt:res.data.data.payedAt,
                delivery_date:res.data.data.delivery_date,
            })
            set_planCardSpin(false)
            onReceiptPlanDrawerClose()
            return message.success("رسید تایید شد")
        }catch (e) {
            console.log(e)
            set_planCardSpin(false)
            return message.error("مشکل در تغییر وضعیت")
        }
    }
    const rejectReceipt = async ()=>{
        set_planCardSpin(true)
        try{
            const res = await changePlanReceiptStatus_api({status:"rejected"},p._id)
            console.log("sss: ",res.data.data)
            set_p({...p,
                status:res.data.data.status,
                pay_status:res.data.data.pay_status,
                deliveredAt:res.data.data.deliveredAt,
                payedAt:res.data.data.payedAt,
                delivery_date:res.data.data.delivery_date,
            })
            set_planCardSpin(false)
            onReceiptPlanDrawerClose()
            return message.success("رسید رد شد")
        }catch (e) {
            console.log(e)
            set_planCardSpin(false)
            return message.error("مشکل در تغییر وضعیت")
        }
    }


    return(
        <Spin spinning={planCardSpin}>
            <>
                {p.trainee?
                    <div className="plan-card-v2-main" ref={ref} >
                        <div className="plan-card-fix-row clickable" onClick={e=>{
                            set_collapseCard(!collapseCard)
                        }}>
                            <div className="plan-card-fix-price">
                                <span className="price">{numberWithCommas(p.payable_amount)}</span>
                                {p.pay_status === "wait_for_accept" ?
                                    <span className="unit">تومان(رسید)</span> :
                                    <span className="unit">تومان</span>
                                }
                            </div>
                            <div className="plan-card-fix-trainee">
                                <span className="trainee-name">{p.trainee.first_name+" "+p.trainee.last_name}</span>
                                <span className="trainee-date">{p.title}</span>
                                <span className="trainee-date">{moment(p.createdAt).format('jYYYY/jMM/jDD HH:mm')}</span>
                                <span className={`trainee-date plan-status-label ${labelStatusColor(p.status,p.pay_status,planIsEnd)}`} >{labelStatus(p.status,p.pay_status,planIsEnd)}</span>
                            </div>
                        </div>

                        <Collapse isOpened={collapseCard}>
                            <Fragment>

                                <div className="plan-card-progress">

                                    <div className="plan-card-progress-station" style={{backgroundColor:"#3195ff"}}>
                    <span className="plan-card-progress-station-date">
                        <span>{moment(p.createdAt).format('jDD')}</span>
                        <span>{convertMonthNumberToName(moment(p.createdAt).format('jMM'))}</span>
                    </span>
                                        <span className="plan-card-progress-station-label">ثبت</span>
                                    </div>


                                    <div className="plan-card-progress-line" style={p.payedAt?{backgroundColor:"#3195ff"}:{backgroundColor:"##D9D9D9"}}></div>


                                    <div className="plan-card-progress-station" style={p.payedAt?{backgroundColor:"#3195ff"}:{backgroundColor:"##D9D9D9"}}>
                    <span className="plan-card-progress-station-date">
                        {p.payedAt?
                            <>
                                <span>{moment(p.payedAt).format('jDD')}</span>
                                <span>{convertMonthNumberToName(moment(p.payedAt).format('jMM'))}</span>
                            </>
                            :""}
                    </span>
                                        <span className="plan-card-progress-station-label">پرداخت</span>
                                    </div>

                                    <div className="plan-card-progress-line" style={p.deliveredAt?{backgroundColor:"#428fe2"}:{backgroundColor:"##D9D9D9"}}></div>



                                    <div className="plan-card-progress-station" style={p.deliveredAt?{backgroundColor:"#3195ff"}:{backgroundColor:"##D9D9D9"}}>
                    <span className="plan-card-progress-station-date">
                        {p.deliveredAt?
                            <>
                                <span>{moment(p.deliveredAt).format('jDD')}</span>
                                <span>{convertMonthNumberToName(moment(p.deliveredAt).format('jMM'))}</span>
                            </>
                            :""}
                        {p.trainee.delivery_date && parseInt(moment(p.trainee.delivery_date).format('jYYYY'))<1500 && !p.deliveredAt?
                            <div className="plan-card-delivery_date">
                                <span>{moment(p.trainee.delivery_date).format('jDD')}</span>
                                <span>{convertMonthNumberToName(moment(p.trainee.delivery_date).format('jMM'))}</span>
                            </div>
                            :""}
                    </span>
                                        <span className="plan-card-progress-station-label">تحویل</span>
                                    </div>



                                </div>







                                {p.pay_status!=="wait_for_accept" && p.pay_status!=="rejected"?
                                    <div className="plan-card-btn" >
                                <span className="clickable" onClick={e=>{
                                    history.push(`/trainer/plan-add/${p.trainee._id}/${p._id}`)
                                }}>{btnStatus(p.status,p.pay_status,planIsEnd)}</span>
                                    </div>:null
                                }


                                {p.pay_status==="wait_for_accept" || p.pay_status==="rejected"?
                                    <div className="receipt-image" >
                                        <Zoom>
                                            <img src={Setup.filePoint+p.receipt_image} alt=""/>
                                        </Zoom>
                                    </div>
                                    :null}

                                {p.pay_status==="rejected"?
                                    <div className="plan-card-btn" >
                                <span className="clickable" onClick={e=>{
                                    showReceiptPlanDrawer()
                                }}>رد شده</span>
                                    </div>:null}


                                {p.pay_status==="wait_for_accept"?
                                    <div className="plan-card-btn" >
                                <span className="clickable" onClick={e=>{
                                    showReceiptPlanDrawer()
                                }}>تایید رسید</span>
                                    </div>:null
                                }


                                <div className="plan-card-target-v2">

                                    {p.target.length>0?
                                        <div className="plan-card-target-title">
                                            <span>هدف برنامه</span>
                                        </div>:null
                                    }


                                    <div className="plan-card-target-desc">
                                        <span>{p.target}</span>
                                    </div>

                                    <div className="plan-card-actions">
                                        <div>

                                        </div>

                                        {/*<div className="actions-icons">*/}
                                        {/*    <div className="plan-card-actions-item">*/}
                                        {/*        <img src={chatIcon} alt=""/>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="plan-card-actions-item">*/}
                                        {/*        <img src={picIcon} alt=""/>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="plan-card-actions-item">*/}
                                        {/*        <img src={profileIcon} alt=""/>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                        <div className="actions-contact clickable" onClick={e=>{
                                            history.push(`/trainer/plan-list/${p.trainee._id}`)
                                        }}>
                                            <div className="profile-info">
                                                {p.trainee?<span className="mobile-name">{p.trainee.first_name+" "+p.trainee.last_name}</span>:null}
                                                {p.trainee.mobile?<span className="mobile-contact">{p.trainee.mobile}</span>:null}
                                                {p.trainee.email?<span>{p.trainee.email}</span>:null}
                                            </div>
                                            <div className="profile-image">
                                                {p.trainee?.image?<img src={Setup.filePoint+p.trainee?.image} alt=""/>:<img src={sampleFace} alt=""/>}
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </Fragment>

                        </Collapse>


                        <Drawer height={350}
                                bodyStyle={{padding:0,margin:0,backgroundColor:"#8A86FE",borderRadius:"15px 15px 0 0"}}
                                mask={true}  closable={false}  placement="bottom" onClose={onReceiptPlanDrawerClose} visible={visibleReceiptPlanDrawer}>

                            <div className="bottom-drawer-handle">
                                <span></span>
                            </div>
                            <div className="bottom-drawer-body">
                                <div className="bottom-drawer-item clickable" onClick={e=>{
                                    acceptReceipt()
                                }}>
                                    {/*<img src={mobileIcon} alt=""/>*/}
                                    <span className="label">تایید رسید</span>
                                </div>
                                <div className="bottom-drawer-item clickable" onClick={e=>{
                                    rejectReceipt()
                                }}>
                                    {/*<img src={mailIcon} alt=""/>*/}
                                    <span className="label">رد کردن رسید</span>
                                </div>

                            </div>

                        </Drawer>


                    </div>:null
                }
            </>
        </Spin>
    )
}

function OrderCard({item,getBooks}){

    //default , booked ,accepted , cancel_me , cancel_client , not_come
    const [memberStatus,set_memberStatus] = useState("")
    const [statusSpin,set_statusSpin] = useState(false)

    useEffect(()=>{
        set_memberStatus(item.status)
    },[])


    //
    const ref = useRef(null)
    const isVisible = useOnScreen(ref)
    const history = useHistory()

    const [pageSeen,set_pageSeen] = useState(false);
    const [currentSeen,set_currentSeen] = useState(item.seen);


    const seen = async (id)=>{
        try{
            await ordersRequestSeenApi(id);
        }catch(e){
            console.log(e)
        }
    }
    //
    useEffect(()=>{
        if(isVisible && !pageSeen && triggerID===item._id){
            console.log(item._id,"is vis",isVisible)
            set_pageSeen(true)
            page = page+1
            getBooks()
        }
        if(!currentSeen && isVisible ){
            console.log(item._id,"seen",isVisible)
            set_currentSeen(true)
            seen(item._id)
            //api call
        }
    },[isVisible])

    const [handleClickedStatus,set_handleClickedStatus] = useState(null)
    const [statusDescription,set_statusDescription] = useState("")
    const handleChangeMemberStatus = async (s)=>{
        if(s==="cancel_by_customer" || s==="cancel_by_owner" || s==="send"){
            if(statusDescription.length<1){
                return message.error("لطفا برای تغییر این وضعیت توضیحات خود را اضافه نمایید")
            }
        }
        handleClickCloseBottomDrawer()
        handleClickCloseDescDrawer()
        set_statusSpin(true)
        let obj = {
            status_description:statusDescription,
            status:s
        }
        try{
            await ordersRequestChangeStatusApi(item._id,obj)
            set_memberStatus(s)
            getBooks()
            set_statusSpin(false)
        }catch (e) {
            console.log(e)
            set_statusSpin(false)
            message.error("نمیتونی وضعیت و تغییر بدی")
        }
    }

    const bookBackColor = (status)=>{

        console.log("status :",status)

        if(status==="booked"){
            return "dumbbell-booking-mg-booking-item-header booking-turn-booked-status"
        }

        if(status==="cancel_me"){
            return "dumbbell-booking-mg-booking-item-header booking-turn-cancel-me-status"
        }

        if(status==="cancel_client"){
            return "dumbbell-booking-mg-booking-item-header booking-turn-cancel-client-status"
        }

        if(status==="accepted"){
            return "dumbbell-booking-mg-booking-item-header booking-turn-accepted-status"
        }

        if(status==="not_come"){
            return "dumbbell-booking-mg-booking-item-header booking-turn-not-come-status"
        }

        return "dumbbell-booking-mg-booking-item-header booking-turn-booked-status"

    }


    const OrderBackColor = (status)=>{

        console.log("status :",status)

        if(status==="created"){
            return "dumbbell-booking-mg-booking-item-header booking-turn-booked-status"
        }

        if(status==="cancel_by_customer"){
            return "dumbbell-booking-mg-booking-item-header booking-turn-cancel-me-status"
        }

        if(status==="cancel_by_owner"){
            return "dumbbell-booking-mg-booking-item-header booking-turn-cancel-client-status"
        }

        if(status==="send"){
            return "dumbbell-booking-mg-booking-item-header booking-turn-accepted-status"
        }

        if(status==="packing"){
            return "dumbbell-booking-mg-booking-item-header booking-turn-not-come-status"
        }

        if(status==="ready_to_deliver"){
            return "dumbbell-booking-mg-booking-item-header booking-turn-pending-status"
        }

        if(status==="delivered"){
            return "dumbbell-booking-mg-booking-item-header booking-turn-accepted-status"
        }


        return "dumbbell-booking-mg-booking-item-header booking-turn-booked-status"

    }

    const convertOrderStatusToName = (status,p_status)=>{



        if(status==="created"){
            return "در حال بررسی"
        }

        if(status==="cancel_by_customer"){
            return "کنسل توسط خودم"
        }

        if(status==="cancel_by_owner"){
            return "کنسل توسط مشتری"
        }

        if(status==="send"){
            return "ارسال شده"
        }

        if(status==="packing"){
            return "در حال آماده سازی"
        }

        if(status==="ready_to_deliver"){
            return "اماده تحویل حضوری"
        }

        if(status==="delivered"){
            return "تحویل شد"
        }



        return "در دست اقدام"

    }


    const convertStatusToName = (status)=>{

        console.log("status :",status)

        if(status==="booked"){
            return "رزرو شده"
        }

        if(status==="cancel_me"){
            return "کنسل توسط خودم"
        }

        if(status==="cancel_client"){
            return "کنسل توسط مشتری"
        }

        if(status==="accepted"){
            return "پذیرش شده"
        }

        if(status==="not_come"){
            return "مراجعه نکرده"
        }

        return "رزرو شده"

    }



    const [collapseCard,set_collapseCard] = useState(false)
    const [currentStatus,set_currentStatus] = useState(item.status)

    //Drawer
    const [visibleBottomDrawer,set_visibleBottomDrawer] = useState(false)
    const [selectedVideo,set_selectedVideo] = useState("")


    const showBottomDrawer = ()=>{
        set_visibleBottomDrawer(!visibleBottomDrawer)
        setTimeout(()=>{setAntdDrawerWidth()},0)
    }

    const onBottomDrawerClose = () => {
        set_visibleBottomDrawer(false);
    };

    const handleClickCloseBottomDrawer = (m)=>{
        set_visibleBottomDrawer(false);
    }



    const [visibleDescDrawer,set_visibleDescDrawer] = useState(false)

    const showDescDrawer = (status)=>{
        set_handleClickedStatus(status)
        set_visibleDescDrawer(!visibleDescDrawer)
        setTimeout(()=>{setAntdDrawerWidth()},0)
    }

    const onDescDrawerClose = () => {
        set_visibleDescDrawer(false);
    };

    const handleClickCloseDescDrawer = (m)=>{
        set_visibleDescDrawer(false);
    }

    const [collapseCartProduct,set_collapseCartProduct] = useState(false)
    const [orderItems,set_orderItems] = useState([])
    return(
        <Fragment>
                <Spin spinning={statusSpin}>
                    <div className="dumbbell-booking-mg-booking-item" ref={ref}>

                        <div className="plan-card-fix-row clickable" onClick={e=>{
                            set_collapseCard(!collapseCard)
                        }}>

                            <div className="plan-card-fix-price">
                                <span className="price">{numberWithCommas(item.payable_amount)}</span>
                                <span className="unit">{moment(item.createdAt).format("jYYYY/jMM/jDD")}</span>
                            </div>

                            <div className="plan-card-fix-trainee">
                                <span className="trainee-name">{item.full_name}</span>
                                <span className="trainee-date">{item.mobile}</span>
                                <span className={OrderBackColor(item.status)}>{convertOrderStatusToName(item.status,item.pay_status)}</span>
                            </div>
                        </div>

                        <Collapse isOpened={collapseCard}>
                            <Fragment>

                                <div className="booking-card-price-sec">
                                    <div className="booking-card-price-row">
                                        <div className="booking-card-price-item">
                                            <h6>{item.trackId}</h6>
                                        </div>
                                        <div className="booking-card-price-item">
                                            <span>شماره سفارش </span>
                                        </div>
                                    </div>

                                    <div className="booking-card-price-row">
                                        <div className="booking-card-price-item">
                                            <h6>{numberWithCommas(item.amount)} تومان </h6>
                                        </div>
                                        <div className="booking-card-price-item">
                                            <span>هزینه سفارش</span>
                                        </div>
                                    </div>
                                    <div className="booking-card-price-row">
                                        <div className="booking-card-price-item">
                                            <h6>{numberWithCommas(item.discount)} تومان </h6>
                                        </div>
                                        <div className="booking-card-price-item">
                                            <span>تخفیف سفارش</span>
                                        </div>
                                    </div>
                                    <div className="booking-card-price-row">
                                        <div className="booking-card-price-item">
                                            <h6>{numberWithCommas(item.payable_amount)} تومان </h6>
                                        </div>
                                        <div className="booking-card-price-item">
                                            <span>هزینه قابل پرداخت</span>
                                        </div>
                                    </div>
                                    <div className="booking-card-price-row">
                                        <div className="booking-card-price-item">
                                            <h6>{item?.inPerson?"حضوری":"ارسالی"}</h6>
                                        </div>
                                        <div className="booking-card-price-item">
                                            <span>نوع تحویل</span>
                                        </div>

                                    </div>
                                </div>




                                {item.status==="created"?
                                    <div className="receipt-image" >
                                        <Zoom>
                                            <img src={Setup.filePoint+item.receipt_image} alt=""/>
                                        </Zoom>
                                    </div>
                                    :null}


                                    <div className="booking-card-items-sec">

                                        <div className="cart-invoice-detail-price-item-see-detail clickable" onClick={e=>{
                                            set_collapseCartProduct(!collapseCartProduct)
                                        }}>
                                            <span>{!collapseCartProduct?"دیدن جزییات":"عدم دیدن جزییات"}</span>
                                            <img
                                                src={caretDownIcon}
                                                alt=""
                                                className={collapseCartProduct ? "rotate" : ""}/>
                                        </div>

                                        <Collapse isOpened={collapseCartProduct}>
                                            <div className="cart-products-list">
                                                <div className="cart-products-row header-row">
                                                    <div className="cart-products-item-price">
                                                        <span>قیمت کل</span>
                                                    </div>
                                                    <div className="cart-products-item-quantity">
                                                        <span>تعداد</span>
                                                    </div>
                                                    <div className="cart-products-item-discount">
                                                        <span>تخفیف</span>
                                                    </div>
                                                    <div className="cart-products-item-original-price">
                                                        <span>قیمت</span>
                                                    </div>

                                                    <div className="cart-products-item-name">
                                                        <span>نام</span>
                                                    </div>
                                                </div>
                                                {item?.items?.map(p=>{
                                                    return(
                                                        <div className="cart-products-row">
                                                            <div className="cart-products-item-price">
                                                                <span>{numberWithCommas((p.price-p.discount)*p.quantity)}</span>
                                                            </div>
                                                            <div className="cart-products-item-quantity">
                                                                <span>{p.quantity}</span>
                                                            </div>
                                                            <div className="cart-products-item-discount">
                                                                <span>{numberWithCommas(p.discount)}</span>
                                                            </div>
                                                            <div className="cart-products-item-original-price">
                                                                <span>{numberWithCommas(p.price)}</span>
                                                            </div>

                                                            <div className="cart-products-item-name">
                                                                <span>{p.fa_name.length>30?p.fa_name.substring(0,25)+"...":p.fa_name}</span>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>

                                            <div className="booking-card-price-sec">


                                                {item.createdAt?
                                                    <div className="booking-card-price-row">
                                                        <div className="booking-card-price-item">
                                                            <h6>{moment(item.createdAt).format("HH:mm  -  jYYYY/jMM/jDD")}</h6>
                                                        </div>
                                                        <div className="booking-card-price-item">
                                                            <span>تاریخ شروع بررسی</span>
                                                        </div>
                                                    </div>:null
                                                }


                                                {item.cancelCustomerAt?
                                                    <div className="booking-card-price-row">
                                                        <div className="booking-card-price-item">
                                                            <h6>{moment(item.cancelCustomerAt,).format("HH:mm  -  jYYYY/jMM/jDD")}</h6>
                                                        </div>
                                                        <div className="booking-card-price-item">
                                                            <span>تاریخ کنسل توسط فروشگاه </span>
                                                        </div>
                                                    </div>:null
                                                }


                                                {item.cancelOwnerAt?
                                                    <div className="booking-card-price-row">
                                                        <div className="booking-card-price-item">
                                                            <h6>{moment(item.cancelOwnerAt,).format("HH:mm  -  jYYYY/jMM/jDD")}</h6>
                                                        </div>
                                                        <div className="booking-card-price-item">
                                                            <span>تاریخ کنسل توسط مشتری </span>
                                                        </div>
                                                    </div>:null
                                                }



                                                {item.packingAt?
                                                    <div className="booking-card-price-row">
                                                        <div className="booking-card-price-item">
                                                            <h6>{moment(item.packingAt,).format("HH:mm  -  jYYYY/jMM/jDD")}</h6>
                                                        </div>
                                                        <div className="booking-card-price-item">
                                                            <span>تاریخ آماده سازی</span>
                                                        </div>
                                                    </div>:null
                                                }


                                                {item.sendAt?
                                                    <div className="booking-card-price-row">
                                                        <div className="booking-card-price-item">
                                                            <h6>{moment(item.sendAt,).format("HH:mm  -  jYYYY/jMM/jDD")}</h6>
                                                        </div>
                                                        <div className="booking-card-price-item">
                                                            <span>تاریخ ارسال</span>
                                                        </div>
                                                    </div>:null
                                                }


                                                {item.readyDeliverAt?
                                                    <div className="booking-card-price-row">
                                                        <div className="booking-card-price-item">
                                                            <h6>{moment(item.readyDeliverAt,).format("HH:mm  -  jYYYY/jMM/jDD")}</h6>
                                                        </div>
                                                        <div className="booking-card-price-item">
                                                            <span>تاریخ آماده تحویل</span>
                                                        </div>
                                                    </div>:null
                                                }


                                                {item.deliveredAt?
                                                    <div className="booking-card-price-row">
                                                        <div className="booking-card-price-item">
                                                            <h6>{moment(item.deliveredAt).format("HH:mm  -  jYYYY/jMM/jDD")}</h6>
                                                        </div>
                                                        <div className="booking-card-price-item">
                                                            <span>تاریخ تحویل</span>
                                                        </div>
                                                    </div>:null
                                                }


                                            </div>

                                        </Collapse>
                                    </div>


                                {item.address || item.description || item.status_description?
                                    <div className="booking-card-description-sec">




                                        {item.address? <>
                                            <span>ادرس مشتری</span>
                                            <p>{item.address}</p>
                                        </>:null}

                                        {
                                            item.description?
                                                <>
                                                    <span>توضیحات مشتری</span>
                                                    <p>{item.description}</p>
                                                </>
                                                :null}

                                        {item.status_description?
                                            <>
                                                <span>توضیحات خودم</span>
                                                <p>{item.status_description}</p>
                                            </>:null
                                        }

                                    </div>:null
                                }



                                <div className="booking-card-price-btn">
                                    <span className="clickable" onClick={e=>{
                                        if(item.status!=="send"){
                                            showBottomDrawer()
                                        }else {
                                            message.warn("تغییر وضعیت بعد از ارسال امکان پذیر نمیباشد")
                                        }
                                    }}>{convertOrderStatusToName(item.status,item.pay_status)}</span>
                                </div>

                            </Fragment>




                        </Collapse>



                        <Drawer height={350}
                                bodyStyle={{padding:0,margin:0,backgroundColor:"#8A86FE",borderRadius:"15px 15px 0 0"}}
                                mask={true}  closable={false}  placement="bottom" onClose={onBottomDrawerClose} visible={visibleBottomDrawer}>

                            <div className="bottom-drawer-handle">
                                <span></span>
                            </div>
                            <div className="bottom-drawer-body">
                                <div className="bottom-drawer-item clickable" onClick={e=>{
                                    showDescDrawer("created")
                                    // handleChangeMemberStatus("created")
                                }}>
                                    {/*<img src={mobileIcon} alt=""/>*/}
                                    <span className="label">در حال بررسی</span>
                                </div>
                                <div className="bottom-drawer-item clickable" onClick={e=>{
                                    showDescDrawer("cancel_by_owner")
                                    // handleChangeMemberStatus("cancel_by_owner")
                                }}>
                                    {/*<img src={mailIcon} alt=""/>*/}
                                    <span className="label">کنسل توسط خودم</span>
                                </div>

                                <div className="bottom-drawer-item clickable" onClick={e=>{
                                    showDescDrawer("cancel_by_customer")
                                    // handleChangeMemberStatus("cancel_by_customer")
                                }}>
                                    {/*<img src={mailIcon} alt=""/>*/}
                                    <span className="label">کنسل توسط مشتری</span>
                                </div>

                                <div className="bottom-drawer-item clickable" onClick={e=>{
                                    showDescDrawer("packing")
                                    // handleChangeMemberStatus("packing")
                                }}>
                                    {/*<img src={mailIcon} alt=""/>*/}
                                    <span className="label">در حال آماده سازی</span>
                                </div>


                                {item.inPerson?
                                    <div className="bottom-drawer-item clickable" onClick={e=>{
                                        showDescDrawer("ready_to_deliver")
                                        // handleChangeMemberStatus("send")
                                    }}>
                                        {/*<img src={mailIcon} alt=""/>*/}
                                        <span className="label">آماده تحویل حضوری</span>
                                    </div>:<div className="bottom-drawer-item clickable" onClick={e=>{
                                        showDescDrawer("send")
                                        // handleChangeMemberStatus("send")
                                    }}>
                                        {/*<img src={mailIcon} alt=""/>*/}
                                        <span className="label">ارسال شد</span>
                                    </div>
                                }

                                {item.inPerson?
                                    <div className="bottom-drawer-item clickable" onClick={e=>{
                                        showDescDrawer("delivered")
                                        // handleChangeMemberStatus("send")
                                    }}>
                                        {/*<img src={mailIcon} alt=""/>*/}
                                        <span className="label">تحویل شد</span>
                                    </div>:null
                                }

                            </div>

                            <Drawer height={350}
                                    bodyStyle={{padding:0,margin:0,backgroundColor:"white",borderRadius:"15px 15px 0 0"}}
                                    mask={true}  closable={false}  placement="bottom" onClose={onDescDrawerClose} visible={visibleDescDrawer}>

                                <div>
                                    <div className="description-drawer">
                                        <div className="description-drawer-title">
                                            <span></span>
                                        </div>
                                        <div className="description-drawer-input">
                                            <textarea
                                                value={statusDescription}
                                                onChange={e=>set_statusDescription(e.target.value)}
                                                type="text" name="" id=""/>
                                        </div>
                                        <div className="description-drawer-hint">
                                            <p>لطفا توضیحات خود را برای این وضعیت وارد نمایید</p>
                                        </div>
                                        <div className="description-drawer-btn clickable">
                                            <span onClick={e=>{
                                                handleChangeMemberStatus(handleClickedStatus)
                                            }}>تغییر وضعیت</span>
                                        </div>
                                    </div>
                                </div>

                            </Drawer>
                        </Drawer>


                        {/*<p>{item.member.description}</p>*/}

                    </div>
                </Spin>
        </Fragment>
    )
}


function BookCard({item,getBooks}){

    //default , booked ,accepted , cancel_me , cancel_client , not_come
    const [memberStatus,set_memberStatus] = useState("")
    const [statusSpin,set_statusSpin] = useState(false)

    useEffect(()=>{
        set_memberStatus(item.status)
    },[])


    //
    const ref = useRef(null)
    const isVisible = useOnScreen(ref)
    const history = useHistory()

    const [pageSeen,set_pageSeen] = useState(false);
    const [currentSeen,set_currentSeen] = useState(item.seen);


    const seen = async (id)=>{
        try{
            await bookSeenApi(id);
        }catch(e){
            console.log(e)
        }
    }
    //
    useEffect(()=>{
        if(isVisible && !pageSeen && triggerID===item._id){
            console.log(item._id,"is vis",isVisible)
            set_pageSeen(true)
            page = page+1
            getBooks()
        }
        if(!currentSeen && isVisible ){
            console.log(item._id,"seen",isVisible)
            set_currentSeen(true)
            seen(item._id)
            //api call
        }
    },[isVisible])

    const handleAcceptReceipt = async (s)=>{
        handleClickCloseBottomDrawer()
        set_statusSpin(true)
        let obj = {
            turnId:item.for,
            linkId:item.link_id,
            status:"pay"
        }
        try{
            await acceptBookingReceiptFromBook_api(obj)
            set_memberStatus(s)
            getBooks()
            set_statusSpin(false)
        }catch (e) {
            console.log(e)
            set_statusSpin(false)
            message.error("نمیتونی وضعیت و تغییر بدی")
        }
    }


    const handleChangeMemberStatus = async (s)=>{
        handleClickCloseBottomDrawer()
        set_statusSpin(true)
        let obj = {
            turnId:item.for,
            linkId:item.link_id,
            status:s
        }
        try{
            await changeStatusFromBook_api(obj)
            set_memberStatus(s)
            getBooks()
            set_statusSpin(false)
        }catch (e) {
            console.log(e)
            set_statusSpin(false)
            message.error("نمیتونی وضعیت و تغییر بدی")
        }
    }

    const bookBackColor = (status,p_status)=>{


        console.log("status :",status)

        if(p_status==="wait_for_accept"){
            return "dumbbell-booking-mg-booking-item-header booking-turn-pending-status"
        }

        if(status==="booked"){
            return "dumbbell-booking-mg-booking-item-header booking-turn-booked-status"
        }

        if(status==="cancel_me"){
            return "dumbbell-booking-mg-booking-item-header booking-turn-cancel-me-status"
        }

        if(status==="cancel_client"){
            return "dumbbell-booking-mg-booking-item-header booking-turn-cancel-client-status"
        }

        if(status==="accepted"){
            return "dumbbell-booking-mg-booking-item-header booking-turn-accepted-status"
        }

        if(status==="not_come"){
            return "dumbbell-booking-mg-booking-item-header booking-turn-not-come-status"
        }

        return "dumbbell-booking-mg-booking-item-header booking-turn-booked-status"

    }


    const convertStatusToName = (status,p_status)=>{

        if(p_status==="wait_for_accept"){
            return "منتظر تایید رسید"
        }

        if(status==="booked"){
            return "رزرو شده"
        }

        if(status==="cancel_me"){
            return "کنسل توسط خودم"
        }

        if(status==="cancel_client"){
            return "کنسل توسط مشتری"
        }

        if(status==="accepted"){
            return "پذیرش شده"
        }

        if(status==="not_come"){
            return "مراجعه نکرده"
        }

        return "رزرو شده"

    }



    const [collapseCard,set_collapseCard] = useState(false)
    const [currentStatus,set_currentStatus] = useState(item.status)

    //Drawer
    const [visibleBottomDrawer,set_visibleBottomDrawer] = useState(false)
    const [selectedVideo,set_selectedVideo] = useState("")


    const showBottomDrawer = ()=>{
        set_visibleBottomDrawer(!visibleBottomDrawer)
        setTimeout(()=>{setAntdDrawerWidth()},0)
    }

    const onBottomDrawerClose = () => {
        set_visibleBottomDrawer(false);
    };

    const handleClickCloseBottomDrawer = (m)=>{
        set_visibleBottomDrawer(false);
    }

    return(
        <Fragment>
            {item.for?
                <Spin spinning={statusSpin}>
                     <div className="dumbbell-booking-mg-booking-item" ref={ref}>

                        <div className="plan-card-fix-row clickable" onClick={e=>{
                            set_collapseCard(!collapseCard)
                        }}>

                            <div className="plan-card-fix-price">
                                <span className="price">{`${item.startAt} - ${item.endAt}`}</span>
                                <span className="unit">{moment(item.date,"YYYY-MM-DD").format("jYYYY/jMM/jDD")}</span>
                            </div>

                            <div className="plan-card-fix-trainee">
                                <span className="trainee-name">{item.first_name+" "+item.last_name}</span>
                                <span className="trainee-date">{item.mobile}</span>
                                <span className={bookBackColor(item.status,item.pay_status)}>{convertStatusToName(item.status,item.pay_status)}</span>
                            </div>
                        </div>

                        <Collapse isOpened={collapseCard}>
                            <Fragment>

                                <div className="booking-card-price-sec">
                                    <div className="booking-card-price-row">
                                        <div className="booking-card-price-item">
                                            <h6>{item.number}</h6>
                                        </div>
                                        <div className="booking-card-price-item">
                                            <span>شماره </span>
                                        </div>
                                    </div>
                                    <div className="booking-card-price-row">
                                        <div className="booking-card-price-item">
                                            <h6>{numberWithCommas(item.cost)} تومان </h6>
                                        </div>
                                        <div className="booking-card-price-item">
                                            <span>قیمت سرویس</span>
                                        </div>
                                    </div>
                                    <div className="booking-card-price-row">
                                        <div className="booking-card-price-item">
                                            <h6>{numberWithCommas(item.pre_cost)} تومان </h6>
                                        </div>
                                        <div className="booking-card-price-item">
                                            <span>قیمت پرداخت شده</span>
                                        </div>
                                    </div>
                                </div>


                                {item.pay_status==="wait_for_accept"?
                                    <div className="receipt-image" >
                                        <Zoom>
                                            <img src={Setup.filePoint+item.receipt_image} alt=""/>
                                        </Zoom>
                                    </div>
                                    :null}

                                <div className="booking-card-description-sec">
                                    <span>توضیحات</span>
                                    <p>{item.description}</p>
                                </div>

                                <div className="booking-card-price-btn">
                                    <span className="clickable" onClick={showBottomDrawer}>{convertStatusToName(item.status,item.pay_status)}</span>
                                </div>

                            </Fragment>
                        </Collapse>


                        <Drawer height={350}
                                bodyStyle={{padding:0,margin:0,backgroundColor:"#8A86FE",borderRadius:"15px 15px 0 0"}}
                                mask={true}  closable={false}  placement="bottom" onClose={onBottomDrawerClose} visible={visibleBottomDrawer}>

                            <div className="bottom-drawer-handle">
                                <span></span>
                            </div>
                            <div className="bottom-drawer-body">
                                {item.pay_status==="wait_for_accept"?
                                    <div className="bottom-drawer-item clickable" onClick={e=>{
                                        handleAcceptReceipt("booked")
                                    }}>
                                        {/*<img src={mobileIcon} alt=""/>*/}
                                        <span className="label">تایید رسید</span>
                                    </div>:null
                                }

                                {item.pay_status!=="wait_for_accept"?
                                <div className="bottom-drawer-item clickable" onClick={e=>{
                                    handleChangeMemberStatus("booked")
                                }}>
                                    {/*<img src={mobileIcon} alt=""/>*/}
                                    <span className="label">رزرو شده</span>
                                </div>:null}

                                <div className="bottom-drawer-item clickable" onClick={e=>{
                                    handleChangeMemberStatus("cancel_me")
                                }}>
                                    {/*<img src={mailIcon} alt=""/>*/}
                                    <span className="label">کنسل توسط خودم</span>
                                </div>

                                {item.pay_status!=="wait_for_accept"?<div className="bottom-drawer-item clickable" onClick={e=>{
                                    handleChangeMemberStatus("cancel_client")
                                }}>
                                    {/*<img src={mailIcon} alt=""/>*/}
                                    <span className="label">کنسل توسط مشتری</span>
                                </div>:null}

                                {item.pay_status!=="wait_for_accept"?<div className="bottom-drawer-item clickable" onClick={e=>{
                                    handleChangeMemberStatus("accepted")
                                }}>
                                    {/*<img src={mailIcon} alt=""/>*/}
                                    <span className="label">پذیرش شده</span>
                                </div>:null}
                                {item.pay_status!=="wait_for_accept"?<div className="bottom-drawer-item clickable" onClick={e=>{
                                    handleChangeMemberStatus("not_come")
                                }}>
                                    {/*<img src={mailIcon} alt=""/>*/}
                                    <span className="label">مراجعه نکرده</span>
                                </div>:null}
                            </div>
                        </Drawer>


                        {/*<p>{item.member.description}</p>*/}

                    </div>
                </Spin>:null
            }
        </Fragment>
    )
}

function labelStatusColor(status,pay_status,planIsEnd) {
    if(planIsEnd)
        return "plan-is-end"

    if(status==="default" && pay_status==="default")
        return "plan-wait-for-pay"

    if(status==="default" && pay_status==="payed")
        return "plan-wait-for-deliver"

    if(status==="delivered" && pay_status==="payed")
        return "plan-delivered"

    if(status==="rejected" && pay_status==="rejected")
        return "plan-rejected"

    if(status==="default" && pay_status==="wait_for_accept")
        return "plan-wait-for-accept"
}


function labelStatus(status,pay_status,planIsEnd) {
    if(planIsEnd)
        return "پایان یافته"

    if(status==="default" && pay_status==="default")
        return "منتظر پرداخت"

    if(status==="default" && pay_status==="payed")
        return "منتظر تحویل"

    if(status==="delivered" && pay_status==="payed")
        return "تحویل داده شده"

    if(status==="rejected" && pay_status==="rejected")
        return "رد شده"

    if(status==="default" && pay_status==="wait_for_accept")
        return "منتظر تایید"
}


function btnStatus(status,pay_status,planIsEnd) {
    if(planIsEnd)
        return "مشاهده"

    if(status==="default" && pay_status==="default")
        return "منتظر پرداخت"

    if(status==="default" && pay_status==="payed")
        return "تنظیم برنامه"

    if(status==="delivered" && pay_status==="payed")
        return "مشاهده / ویرایش"

    if(status==="default" && pay_status==="rejected")
        return "رد شده"
    if(status==="default" && pay_status==="wait_for_accept")
        return "منتظر تایید"
}


function DumbbellMyRequests(){

    const [pageSpin,set_pageSpin] = useState(true)

    const location = useLocation()
    const history = useHistory()

    //tabs
    const [activeTab,set_activeTab] = useState("plan") //plan , turn
    const handleClickOnTab = (t)=>{
        console.log("in QAAAA",t)
        set_activeTab(t)
    }
    useEffect(()=>{
        // resetPage()
        console.log("in BBBBBB")
        console.log("loca : ",location?.state?.filter)
        if(location?.state?.filter)
            checkInitFilter()
            else
                getData()
                getServices()
    },[activeTab])

    const checkInitFilter = ()=>{
            let t = location.state.filter;
            history.replace({ state: {} })
            console.log("loca 2: ",location?.state)
            if(t==="order"){
                set_activeTab("order")
            }
            else if(t==="turn"){
                set_activeTab("turn")
            }else {
                set_activeTab("plan")
                handleChangeType(t)
            }
    }

    const resetPage = ()=>{
        type="all"
        set_currentType("all")
        set_data([])
    }

    const [services,set_services] = useState(null)
    const getServices = async()=> {
        try {
            const response = await getTrainerProfileApi()
            let srv = response.data.data.services
            set_services(srv)
            if(srv.contains("booking") && !srv.contains("plan"))
                handleClickOnTab("turn")
        }catch (e) {
            console.log(e)
        }
    }
    //data
    const [data,set_data] = useState([])
    const getData = async ()=>{
        set_pageSpin(true)
        console.log("dddddddddddddddddddddddd **")
        try{
            if(activeTab==="plan"){
                set_data([])
                const resp = await requestsPlan_api(page,search,type)
                set_data(resp.data.data.plans)
            }
            if(activeTab==="turn"){
                set_data([])
                const resp = await requestsTurn_api(page,search,type)
                set_data(resp.data.data)
            }
            if(activeTab==="order"){
                set_data([])
                const resp = await ordersRequestListApi(page,search,type)
                set_data(resp.data.data)
            }
            set_pageSpin(false)
        }catch (e) {
            set_pageSpin(false)
            console.log(e)
        }
    }

    //Drawer
    const [currentType,set_currentType] = useState("all")
    const [visibleBottomDrawer,set_visibleBottomDrawer] = useState(false)
    const [selectedVideo,set_selectedVideo] = useState("")

    const convertTypeName = (t)=>{
        switch (t) {
            case 'all':
                return 'همه'
                break;
            case 'new':
                return 'جدید ها'
                break;
            case 'delivered':
                return 'تحویل داده شده'
                break;
            case 'not_delivered':
                return 'تحویل داده نشده'
                break;
        }
    }

    const handleChangeType = (t)=>{
        set_currentType(t)
        onBottomDrawerClose()
        type=t
        getData()
    }

    const showBottomDrawer = ()=>{
        set_visibleBottomDrawer(!visibleBottomDrawer)
        setTimeout(()=>{setAntdDrawerWidth()},0)
    }

    const onBottomDrawerClose = () => {
        set_visibleBottomDrawer(false);
    };

    const handleChangeFilter=(page,t)=>{
        if(page==="request"){
            if(t==="turn"){
                set_activeTab("turn")
            }else {
                set_activeTab("plan")
                handleChangeType(t)
            }
        }

    }


    const [searchInput,set_searchInput] = useState("")

    const handleSearch = (word)=>{
        set_searchInput(word)
        if(word.length>2){
            page = 0;
            search = word;
            getData()
        }
        if(word.length===0){
            page = 0;
            search = "";
            getData()
        }
    }

    return(
        <div>

            <DumbbellHeader title="درخواست های من" changeFilter={handleChangeFilter}/>

            <div className="my-requests-page">

                <div className="tabs-row">
                    {services?.contains("booking")?
                    <div onClick={e=>handleClickOnTab("turn")} className={activeTab==="turn"?"tabs-item clickable active-tab":"tabs-item clickable"}>
                        <span>نوبت</span>
                    </div>:null}
                    {services?.contains("plan")?
                    <div onClick={e=>handleClickOnTab("plan")} className={activeTab==="plan"?"tabs-item clickable active-tab":"tabs-item clickable"}>
                        <span>برنامه آنلاین</span>
                    </div>:null}

                        <div onClick={e=>handleClickOnTab("order")} className={activeTab==="order"?"tabs-item clickable active-tab":"tabs-item clickable"}>
                            <span>سفارشات</span>
                        </div>

                </div>

                <br/>
                <div className="search-row">
                    <img src={searchIcon} alt=""/>
                    <input
                        value={searchInput}
                        onChange={e=>handleSearch(e.target.value)}
                        type="text" name="" id="" placeholder="جستجو با موبایل یا نام"/>
                </div>


                {activeTab==="plan"?
                    <div className="filter-row">
                        <div className="filter-item clickable" onClick={showBottomDrawer}>
                            <img src={caretDownIcon} alt=""/>
                            <span>{convertTypeName(currentType)}</span>
                        </div>
                    </div>:null
                }

            </div>


            {data.length<1 && !pageSpin?
                <div className="empty-state">
                    <img src={emptyIcon} alt=""/>
                    <span>هنوز درخواستی وجود</span>
                    <span>نداره !</span>
                </div>:null
            }

            {pageSpin?
            <div className="loading-state">
                <span style={{width:20,height:20,border:"1.5px solid #8d89fd", borderBottomColor: "transparent"}} className="loader"></span>
            </div>:null
            }

            <div className="page-data-list">
                {data.map(d=>{
                    if(activeTab==="plan")
                        return <PlanCard plan={d}/>
                    if(activeTab==="turn")
                        return <BookCard item={d} getBooks={getData}/>
                    if(activeTab==="order")
                        return <OrderCard item={d} getBooks={getData}/>
                })}
            </div>


            <Drawer height={380}
                    bodyStyle={{padding:0,margin:0,backgroundColor:"#8A86FE",borderRadius:"15px 15px 0 0"}}
                    mask={true}  closable={false}  placement="bottom" onClose={onBottomDrawerClose} visible={visibleBottomDrawer}>

                <div className="bottom-drawer-handle">
                    <span></span>
                </div>
                <div className="bottom-drawer-body">
                    <div className="bottom-drawer-item clickable" onClick={e=>handleChangeType("all")} >
                        <span className="label">همه برنامه ها</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>handleChangeType("new")} >
                        <span className="label">درخواسست برنامه جدید</span>
                    </div>
                    <div className="bottom-drawer-item clickable"  onClick={e=>handleChangeType("delivered")}>
                        <span className="label">برنام های تحویل داده شده</span>
                    </div>
                    <div className="bottom-drawer-item clickable"  onClick={e=>handleChangeType("not_delivered")}>
                        <span className="label">برنام های تحویل داده نشده</span>
                    </div>
                </div>
            </Drawer>

        </div>
    )
}



export default DumbbellMyRequests;
