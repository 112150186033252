import React,{useEffect,useState,useRef} from 'react';
import "./DumbbellSendDrawer.scss";
import {quickSendPlanNew_api, quickSendPlanTrainee_api, traineeListApi} from "../../Services/Services";
import useOnScreen from "../useOnScreen/useOnScreen";
import {useHistory} from "react-router";
import moment from "moment-jalaali";
import {Setup} from "../../Services/Setup";
import defaultUserIcon from "../../assets/images/default_user.png";
import messageIcon from "../../assets/dumbbell_image/V2/chat_icon.svg";
import picIcon from "../../assets/dumbbell_image/V2/pic_icon.svg";
import profileIcon from "../../assets/dumbbell_image/V2/profile_icon.svg";
import {Drawer, message, Popconfirm, Switch} from "antd";
import DumbbellBoxTrainer from "../../Pages/DumbbellBoxTrainer/DumbbellBoxTrainer";
import DumbbellChatTrainer from "../../Pages/DumbbellChatTrainer/DumbbellChatTrainer";
import DumbbellAddTrainee from "../../Pages/DumbbellAddTrainee/DumbbellAddTrainee";
import {validation} from "../../Services/utils";
let page = 0;
let search = "";
let triggerID = "";
let type = "all";

function SendForm ({
    trainee=null,
    data=null,
    voice="",
    doneSend,
    duration=null
                   }){


    const [spin,set_spin] = useState(false)
    //form state
    const [mobile,set_mobile] = useState("")
    const [email,set_email] = useState("")
    const [firstName,set_firstName] = useState("")
    const [lastName,set_lastName] = useState("")
    const [title,set_title] = useState("")
    const [target,set_target] = useState("")
    let [supportDay,set_supportDay] = useState("30")
    const [chatOption,set_chatOption] = useState(false)
    const [boxOption,set_boxOption] = useState(false)

    const resetForm = ()=>{
        set_mobile("")
        set_firstName("")
        set_lastName("")
        set_title("")
        set_target("")
        set_chatOption(false)
        set_boxOption(false)
    }

    useEffect(()=>{
        resetForm()
        if(trainee){
            set_mobile(trainee.mobile)
            set_firstName(trainee.first_name)
            set_lastName(trainee.last_name)
        }
    },[])

    const handleSendPlan = async ()=>{
        set_spin(true)
        try{
            if(trainee){
                if(title.length<1){
                    set_spin(false)
                    return message.warn({
                        content: "لطفا عنوان برنامه را وارد نمایید",
                        className: 'toast-class'
                    });
                }


                let objTrainee = {
                    trainee_id:trainee._id,
                    data:data,
                    voice:voice,
                    duration:duration,
                    support_day:supportDay,
                    title:title,
                    target:target,
                    chat_option:chatOption,
                    box_option:boxOption
                }
                await quickSendPlanTrainee_api(objTrainee)
                doneSend()
            }else {


                if(mobile.length<1 && !doesNotIranNumber){
                    set_spin(false)
                    return message.warn({
                        content: "لطفا موبایل شاگرد را وارد نمایید",
                        className: 'toast-class'
                    });
                }

                if(email.length<1 && doesNotIranNumber){
                    set_spin(false)
                    return message.warn({
                        content: "لطفا ایمیل شاگرد را وارد نمایید",
                        className: 'toast-class'
                    });
                }

                if(doesNotIranNumber && !validation("EMAIL",email)){
                    set_spin(false)
                    return message.error({
                        content: "فرمت ایمیل درست نمیباشد",
                        className: 'toast-class'
                    });
                }

                if(title.length<1){
                    set_spin(false)
                    return message.warn({
                        content: "لطفا عنوان برنامه را وارد نمایید",
                        className: 'toast-class'
                    });
                }

                if(firstName.length<1){
                    set_spin(false)
                    return message.warn({
                        content: "لطفا نام شاگرد را وارد نمایید",
                        className: 'toast-class'
                    });
                }

                if(lastName.length<1){
                    set_spin(false)
                    return message.warn({
                        content: "لطفا نام خانوادگی را وارد نمایید",
                        className: 'toast-class'
                    });
                }

                let objNew = {
                    voice:voice,
                    data:data,
                    duration:duration,
                    support_day:supportDay,
                    mobile:mobile,
                    first_name:firstName,
                    last_name:lastName,
                    email:email,
                    title:title,
                    target:target,
                    chat_option:chatOption,
                    box_option:boxOption
                }
                await quickSendPlanNew_api(objNew)
            }

            set_spin(false)
            return message.success({
                content: "برنامه با موفقیت ارسال شد",
                className: 'toast-class'
            });

        }catch (e) {
            console.log("e : ",e)
            set_spin(false)
            switch (e.response?.data?.message) {
                case 'trainee is exist':
                    return message.warn({
                        content: "این شماره قبلا ثبت شده است",
                        className: 'toast-class'
                    });
                    break;
                case 'not access':
                    return message.warn({
                        content: "شما اجازه این کار را ندارید , لطفا با پشتیبانی تماس بگیرید",
                        className: 'toast-class'
                    });
                    break;
                default:
                    return message.warn({
                        content: "مشکل در ارسال برنامه",
                        className: 'toast-class'
                    });
                    break
            }


        }
    }

    const [doesNotIranNumber,set_doesNotIranNumber] = useState(false)
    const onChange = (checked) => {
        set_doesNotIranNumber(!doesNotIranNumber)
    };

    return(
        <div className="send-drawer-new">
            <div className="send-drawer-new-form">
                <div className="dumbbell-add-trainee-switch-item">
                    <Switch onChange={onChange} />
                    <span>شماره موبایل ایران ندارد</span>
                </div>
                {!doesNotIranNumber?
                <div className="send-drawer-new-form-item">
                    <input
                        disabled={trainee?._id}
                        value={mobile}
                        onChange={e=>set_mobile(e.target.value)}
                        type="text" name="" id="" placeholder="شماره موبایل"/>
                </div>:null}
                {doesNotIranNumber?
                <div className="send-drawer-new-form-item">
                    <input
                        disabled={trainee?._id}
                        value={email}
                        onChange={e=>set_email(e.target.value)}
                        type="text" name="" id="" placeholder="ایمیل"/>
                </div>:null}
                <div className="send-drawer-new-form-item">
                    <input
                        disabled={trainee?._id}
                        value={firstName}
                        onChange={e=>set_firstName(e.target.value)}
                        type="text" name="" id="" placeholder="نام شاگرد"/>
                </div>
                <div className="send-drawer-new-form-item">
                    <input
                        disabled={trainee?._id}
                        value={lastName}
                        onChange={e=>set_lastName(e.target.value)}
                        type="text" name="" id="" placeholder="نام خانوادگی شاگرد"/>
                </div>
                <div className="send-drawer-new-form-item">
                    <input
                        value={title}
                        onChange={e=>set_title(e.target.value)}
                        type="text" name="" id="" placeholder="عنوان برنامه"/>
                </div>
                <div className="send-drawer-new-form-item">
                    <input
                        value={target}
                        onChange={e=>set_target(e.target.value)}
                        type="text" name="" id="" placeholder="هدف برنامه"/>
                </div>

                <div className="send-drawer-new-form-item">
                    <select
                        value={supportDay}
                        onChange={e=>set_supportDay(e.target.value)}
                        name="" id="">
                        <option value="30">30</option>
                        <option value="60">60</option>
                        <option value="90">90</option>
                        <option value="120">120</option>
                        <option value="150">150</option>
                        <option value="180">180</option>
                    </select>
                </div>


                <span>طول مدت پشتیبانی (روز)</span>

                <div className="send-drawer-new-form-item">
                    <input
                        checked={chatOption}
                        onClick={e=>set_chatOption(!chatOption)}
                        type="checkbox" name="" id=""/>
                    <span>قابلیت چت با مربی </span>
                </div>
                <div className="send-drawer-new-form-item">
                    <input
                        checked={boxOption}
                        onClick={e=>set_boxOption(!boxOption)}
                        type="checkbox" name="" id=""/>
                    <span>قابلیت تبادل عکس با مربی </span>
                </div>
                <div className="send-drawer-new-form-item">
                    <button onClick={handleSendPlan} className="clickable">{
                        spin?
                            <span style={{width:20,height:20,border:"1.5px solid #ffffff", borderBottomColor: "transparent"}} className="loader"></span>:
                            <span>ارسال برنامه</span>
                    }</button>


                </div>
            </div>
        </div>
    )
}

function TraineeCard({doneSend,trainee,getPaginationTraineeList,plan,basePlan}){
    const ref = useRef()
    const isVisible = useOnScreen(ref)
    const history = useHistory()

    const [pageSeen,set_pageSeen] = useState(false);


    useEffect(()=>{
        if(isVisible && !pageSeen && triggerID===trainee._id){
            console.log(trainee._id,"is vis",isVisible)
            set_pageSeen(true)
            page = page+1
            getPaginationTraineeList()
        }
    },[isVisible])



    const confirm = ()=>{
        showSendDrawer()
    }
    const cancel = ()=>{

    }

    //send drawer
    const [sendDrawer,set_sendDrawer] = useState(false)
    const closeSendDrawer = ()=>{
        set_sendDrawer(false)
    }
    const showSendDrawer = ()=>{
        set_sendDrawer(true)
    }

    return(
        <div className="dumbbell-trainee-card-send" ref={ref}>
            <Drawer destroyOnClose={true} bodyStyle={{padding: 0, margin: 0, backgroundColor: "##C6C3FF"}} placement={"bottom"} height={"80vh"} closable={false} onClose={closeSendDrawer} visible={sendDrawer}>
                <SendForm doneSend={doneSend} voice={basePlan.voice} trainee={trainee} data={plan} duration={basePlan.duration}/>
            </Drawer>
            <div className="card-send-btn clickable" onClick={confirm}>
                    <span>ارسال</span>
            </div>

            <div className="trainee-card-row-send ">

                <div className="dumbbell-trainee-card-send-drawer" >
                    <div className="dumbbell-trainee-card-trainee-info-send" >
                        <span className="dumbbell-trainee-card-drawer-info-name-send">{trainee.first_name+" "+trainee.last_name}</span>
                        <span className="dumbbell-trainee-card-drawer-info-mobile-send">{trainee.mobile}</span>
                    </div>
                    <div className="dumbbell-trainee-card-trainee-thumb-send" onClick={e=>{history.push(`/trainer/plan-list/${trainee._id}`)}}>
                        {trainee.image?<img className="trainee-image" src={Setup.filePoint+trainee.image} alt=""/>
                            :
                            <img src={defaultUserIcon} alt=""/>
                        }
                    </div>
                </div>

            </div>



        </div>
    )
}


function DumbbellSendDrawer({done,basePlan,plan}){

    const [activeTab,set_activeTab] = useState("trainees") // trainees , new
    const [trainees, set_trainees] = useState([])
    const [pageSpin,set_pageSpin] = useState(true)
    const [totalCount, set_totalCount] = useState(0)

    useEffect(()=>{
        console.log("plan : ",plan)
        console.log("basePlan : ",basePlan)
        getTraineeList()
    },[])

    const getTraineeList = async ()=>{
        set_pageSpin(true)
        set_trainees([])
        console.log("trace 1 :",type)
        try{
            const res = await traineeListApi(0,search,type)
            set_pageSpin(false)
            let trainee = res.data.data.trainee
            let count = res.data.data.count
            triggerID = trainee.length>45?trainee[45]._id:""
            set_trainees(trainee)
            set_totalCount(count)
        }catch(e){
            set_pageSpin(false)
            console.log(e)
        }
    }

    const getPaginationTraineeList = async ()=>{
        try{
            const res = await traineeListApi(page,search,type)
            let trainee = res.data.data.trainee
            let count = res.data.data.count
            triggerID = trainee.length>45?trainee[45]._id:""
            set_trainees(oldArray => [...oldArray, ...trainee])
            set_totalCount(count)
        }catch(e){
            console.log(e)
        }
    }

    const handleChangeTab = (tab)=>{
        set_activeTab(tab)
    }

    return(
        <div className="send-drawer-container">

            <div className="plan-add-tabs">
                <div className={`plan-add-tabs-item-v2 ${activeTab==="new"?"active-tabs":""}`}   onClick={e=>handleChangeTab("new")}>
                    <span>شخص جدید</span>
                </div>
                <div className={`plan-add-tabs-item-v2 ${activeTab==="trainees"?"active-tabs":""}`} onClick={e=>handleChangeTab("trainees")}>
                    <span>شاگردان</span>
                </div>
            </div>

            {activeTab==="trainees"?
                <div className="send-drawer-trainees" >
                    {trainees.map(t=>{
                        return(
                            <TraineeCard doneSend={done} plan={plan} basePlan={basePlan} getPaginationTraineeList={getPaginationTraineeList} trainee={t}/>
                        )
                    })}
                </div>
                :null}

            {activeTab==="new"?
                <SendForm  data={plan} voice={basePlan.voice} duration={basePlan.duration} />
            :null
            }

        </div>
    )
}

export default  DumbbellSendDrawer;
