import React, {Fragment, useEffect, useState} from 'react';
import "./DumbbellCallbackLand.scss";
import loginImage from  "../../assets/dumbbell_image/login.png";
import logoWhite from  "../../assets/dumbbell_image/logo-white.png";
import mobileIcon from  "../../assets/dumbbell_image/mobile_icon.png";
import {Spin,message} from "antd";
import {
    addTraineeApi, completeRequestApi,
    landVerifyInvoiceApi,
    landViewInvoiceAuthorityApi, landViewInvoiceRequestApi,
    loginApi,
    verifyInvoiceApi,
    viewInvoiceApi,
    viewInvoiceAuthorityApi
} from "../../Services/Services";
import "antd/dist/antd.css"
import {useHistory,useLocation,useParams} from "react-router";
import {numberWithCommas, validation} from "../../Services/utils";
import moment from "moment-jalaali";

function DumbbellCallbackLand(){

    const search = useLocation().search;
    const authority=new URLSearchParams(search).get("Authority");
    const status=new URLSearchParams(search).get("Status");

    const {requestId} = useParams()

    useEffect(()=>{
        document.body.style.backgroundColor = "#8A86FE";
    },[])

    const history = useHistory()
    const [spin,set_spin] = useState(true)
    const [mode,set_mode] = useState("default") //default - invoice - failed - success
    const [data,set_data] = useState({})
    const [otp,set_otp] = useState("")


    let [blood,set_blood] = useState("")
    let [gender,set_gender] = useState("man")
    let [age,set_age] = useState("")
    let [weight,set_weight] = useState("")

    let [target,set_target] = useState("")
    let [sport_background,set_sport_background] = useState("")
    let [disease_background,set_disease_background] = useState("")
    let [supplement_use_background,set_supplement_use_background] = useState("")
    let [drug_use_background,set_drug_use_background] = useState("")



    useEffect(()=>{
        console.log(authority)
        console.log(status)
        getInvoice()
    },[])


    const [sendSpin,set_sendSpin] = useState(false)
    const handleSendForm = async ()=>{

        if(sendSpin)
                return


        if(otp.length<4){
            return message.error({
                content: "لطفا کد تایید را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(age.length<2){
            return message.error({
                content: "لطفا حدود سن را وارد نمایید",
                className: 'toast-class'
            });
        }
        if(weight.length<2){
            return message.error({
                content: "لطفا حدود وزن را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(target.length<2){
            return message.error({
                content: "لطفا هدف خود را وارد نمایید",
                className: 'toast-class'
            });
        }

        set_sendSpin(true)

        let obj ={
            profile:{
                target,
                blood,
                gender,
                age,
                weight,
                sport_background,
                disease_background,
                supplement_use_background,
                drug_use_background
            },
            otp:otp
        }

        try{
            await completeRequestApi(data.request._id,obj)
            set_sendSpin(false)
            set_compMode('completed')
            return message.success({
                content: "اطلاعات شما تکمیل و ارسال شد",
                className: 'toast-class'
            });
        }catch (e) {
            set_sendSpin(false)
            console.log(e.response)
            if(e.response.status===403){
                return message.error({
                    content: "کد تایید اشتباه میباشد",
                    className: 'toast-class'
                });
            }
            return message.error({
                content: "مشکل در ارسال اطلاعات بوجود آمده است",
                className: 'toast-class'
            });
        }



    }

    const [compMode,set_compMode] = useState("default")

    const getInvoice = async ()=>{
        let resp = null;
        try{
            if(authority){
                resp = await landViewInvoiceAuthorityApi(authority)
                set_data(resp.data.data)
                set_compMode(resp.data.data.request.complete)
                set_mode("invoice")
                if(status==="OK" && authority.length>5){
                    if(resp.data.data.request.pay!=="payed"){
                        if(resp.data.data.request.complete!=="completed"){
                            verifyProcess()
                        }
                    }
                    else {
                        set_mode("success")
                        set_spin(false)
                    }
                }else {
                    set_spin(false)
                    set_mode("failed")
                }
            }
            else{
                resp = await landViewInvoiceRequestApi(requestId)
                set_data(resp.data.data)
                set_compMode(resp.data.data.request.complete)
                set_mode("invoice")
                let request = resp.data.data.request
                if(request.pay==="payed"){
                        set_mode("success")
                        set_spin(false)
                }else {
                    set_spin(false)
                    set_mode("failed")
                }
            }



        }catch (e) {
            set_spin(false)
            set_mode("failed")
            console.log(e)
        }
    }


    const verifyProcess = async ()=>{
        try{
            await landVerifyInvoiceApi(authority)
            set_mode("success")
            set_spin(false)
        }catch (e) {
            set_spin(false)
            set_mode("failed")
        }
    }

    return(
        <div className="dumbbell-login-container">



            <Spin spinning={spin}>

                <div className="dumbbell-login-default">

                    <div className="dumbbell-invoice-page callback-page" style={{paddingBottom:20,paddingTop:20}}>

                        <div className="dumbbell-invoice-page-title">
                            <span>{mode==="success"?"پرداخت شد":""}</span>
                            <span>{mode==="failed"?"پرداخت ناموفق":""}</span>
                        </div>

                        {/*{mode!=="default" ?<Fragment>*/}
                        {/*    <div className="dumbbell-invoice-page-form">*/}

                        {/*        <div className="dumbbell-invoice-page-form-row">*/}
                        {/*            <span className="dumbbell-invoice-page-form-value">{data?.request.plan?.title}</span>*/}
                        {/*            <span className="dumbbell-invoice-page-form-label">عنوان برنامه </span>*/}
                        {/*        </div>*/}

                        {/*        <div className="dumbbell-invoice-page-form-row">*/}
                        {/*            <span className="dumbbell-invoice-page-form-value">{data?.trainer?.first_name+" "+data?.trainer?.last_name}</span>*/}
                        {/*            <span className="dumbbell-invoice-page-form-label">مربی</span>*/}
                        {/*        </div>*/}


                        {/*        <div className="dumbbell-invoice-page-form-row">*/}
                        {/*            <span className="dumbbell-invoice-page-form-value">{data?.request.plan?.delivery_time}</span>*/}
                        {/*            <span className="dumbbell-invoice-page-form-label">حداکثر زمان تحویل(روز)</span>*/}
                        {/*        </div>*/}

                        {/*        <div className="dumbbell-invoice-page-form-row">*/}
                        {/*            <span className="dumbbell-invoice-page-form-value">{numberWithCommas(data?.request.plan?.cost)}  تومان</span>*/}
                        {/*            <span className="dumbbell-invoice-page-form-label">مبلغ برنامه</span>*/}
                        {/*        </div>*/}

                        {/*        <div className="dumbbell-invoice-page-form-row">*/}
                        {/*            <span className="dumbbell-invoice-page-form-value">{numberWithCommas(0)}  تومان</span>*/}
                        {/*            <span className="dumbbell-invoice-page-form-label">تخفیف برنامه </span>*/}
                        {/*        </div>*/}

                        {/*    </div>*/}


                        {/*    <div className="dumbbell-invoice-page-payable">*/}
                        {/*        <span className="dumbbell-invoice-page-payable-value">{numberWithCommas(data?.request.plan?.cost)}  تومان</span>*/}
                        {/*        <span className="dumbbell-invoice-page-payable-label">مبلغ قابل پرداخت</span>*/}
                        {/*    </div>*/}

                        {/*</Fragment>:null}*/}




                        {mode==="success"?
                            <Fragment>
                                <div className="dumbbell-callback-message-success">
                                    <span className="dumbbell-callback-message-title">پرداخت شما با موفقیت انجام شد</span>
                                    {data?.request.plan?.delivered_type==="instant"?
                                        <span className="dumbbell-callback-message-text">حداکثر تا دقایقی دیگر برنامه توسط {data?.trainer?.first_name+" "+data?.trainer?.last_name} تنظیم شده و برای شما ارسال می شود</span>
                                        :null}
                                    {data?.request.plan?.delivered_type!=="instant"?
                                        <span className="dumbbell-callback-message-text">حداکثر تا مدت زمان {data?.request.plan?.delivery_estimate} روز از تاریخ {moment(data?.request?.payedAt).format(" jYYYY/jMM/jDD ")} برنامه توسط {data?.trainer?.first_name+" "+data?.trainer?.last_name} تنظیم شده و برای شما ارسال می شود</span>
                                        :null}

                                </div>
                                {/*{compMode==="completed"?*/}
                                {/*    <div className="dumbbell-callback-message-warning">*/}
                                {/*        <span className="dumbbell-callback-message-text">درخواست برای این برنامه تکمیل شده و شما میتوانید با ورود به اپلیکیشن از طریق لینک زیر مراحل آماده شدن برنامه خود را مشاهده نمایید</span>*/}
                                {/*    </div>:null*/}
                                {/*}*/}
                                {/*{compMode==="not_complete"?*/}
                                {/*<div className="dumbbell-callback-message-warning">*/}
                                {/*    <span className="dumbbell-callback-message-text">برای  دریافت برنامه لطفا فرم زیر را تکمیل و ارسال نمایید</span>*/}
                                {/*</div>:null}*/}
                            </Fragment>
                            :null
                        }

                        {mode==="failed"?<div className="dumbbell-callback-message-failed">
                            <span className="dumbbell-callback-message-title">پرداخت ناموفق</span>
                        </div>:null}

                        {/*{mode==="success" &&  compMode==="not_complete"?*/}
                        {/*    <div className="otp-sec">*/}
                        {/*        <span> یک کد تایید 6 رقمی به شماره {data.request.mobile} ارسال شد, لطفا آن را وارد نمایید و سپس فرم را تکمیل کنید </span>*/}
                        {/*        <input*/}
                        {/*            placeholder={"کد تایید 6 رقمی را وارد نمایید"}*/}
                        {/*            value={otp}*/}
                        {/*            onChange={e => set_otp(e.target.value)}*/}
                        {/*            type="text" name="" id=""/>*/}
                        {/*    </div>:null*/}
                        {/*}*/}


                    </div>




                    {/*{mode === "success" && compMode === "not_complete" ?*/}
                    {/*    <div className="dumbbell-land-add-trainee-form">*/}

                    {/*        <div className="dumbbell-land-add-trainee-form-item">*/}
                    {/*            <div className="select-wr">*/}
                    {/*                <select*/}
                    {/*                    value={blood}*/}
                    {/*                    onChange={e => set_blood(e.target.value)}*/}
                    {/*                    name="" id="">*/}
                    {/*                    <option value="A">A</option>*/}
                    {/*                    <option value="A+">A+</option>*/}
                    {/*                    <option value="A-">A-</option>*/}
                    {/*                    <option value="B">B</option>*/}
                    {/*                    <option value="B+">B+</option>*/}
                    {/*                    <option value="B-">B-</option>*/}
                    {/*                    <option value="AB">AB</option>*/}
                    {/*                </select>*/}
                    {/*            </div>*/}

                    {/*            <span>گروه خون</span>*/}
                    {/*        </div>*/}

                    {/*        <div className="dumbbell-land-add-trainee-form-item">*/}
                    {/*            <div className="select-wr">*/}
                    {/*                <select*/}
                    {/*                    value={gender}*/}
                    {/*                    onChange={e => set_gender(e.target.value)}*/}
                    {/*                    name="" id="">*/}
                    {/*                    <option value="woman">زن</option>*/}
                    {/*                    <option value="man">مرد</option>*/}
                    {/*                </select>*/}
                    {/*            </div>*/}
                    {/*            <span>جنسیت</span>*/}
                    {/*        </div>*/}

                    {/*        <div className="dumbbell-land-add-trainee-form-item">*/}
                    {/*            <input*/}
                    {/*                value={age}*/}
                    {/*                onChange={e => set_age(e.target.value)}*/}
                    {/*                type="text" name="" id=""/>*/}
                    {/*            <span>حدود سن</span>*/}
                    {/*        </div>*/}

                    {/*        <div className="dumbbell-land-add-trainee-form-item">*/}
                    {/*            <input*/}
                    {/*                value={weight}*/}
                    {/*                onChange={e => set_weight(e.target.value)}*/}
                    {/*                type="text" name="" id=""/>*/}
                    {/*            <span>حدود وزن</span>*/}
                    {/*        </div>*/}

                    {/*        <div className="dumbbell-land-add-trainee-form-item">*/}
                    {/*<textarea*/}
                    {/*    value={target}*/}
                    {/*    onChange={e => set_target(e.target.value)}*/}
                    {/*></textarea>*/}
                    {/*            <span>هدف شما از برنامه</span>*/}
                    {/*        </div>*/}

                    {/*        <div className="dumbbell-land-add-trainee-form-item">*/}
                    {/*<textarea*/}
                    {/*    value={sport_background}*/}
                    {/*    onChange={e => set_sport_background(e.target.value)}*/}
                    {/*></textarea>*/}
                    {/*            <span>سابقه ورزشی</span>*/}
                    {/*        </div>*/}

                    {/*        <div className="dumbbell-land-add-trainee-form-item">*/}
                    {/*<textarea*/}
                    {/*    value={disease_background}*/}
                    {/*    onChange={e => set_disease_background(e.target.value)}*/}
                    {/*></textarea>*/}
                    {/*            <span>سابقه بیماری</span>*/}
                    {/*        </div>*/}

                    {/*        <div className="dumbbell-land-add-trainee-form-item">*/}
                    {/*<textarea*/}
                    {/*    value={supplement_use_background}*/}
                    {/*    onChange={e => set_supplement_use_background(e.target.value)}*/}
                    {/*></textarea>*/}
                    {/*            <span>سابقه مصرف مکمل ورزشی</span>*/}
                    {/*        </div>*/}

                    {/*        <div className="dumbbell-land-add-trainee-form-item">*/}
                    {/*<textarea*/}
                    {/*    value={drug_use_background}*/}
                    {/*    onChange={e => set_drug_use_background(e.target.value)}*/}
                    {/*></textarea>*/}
                    {/*            <span>سابقه مصرف دارو</span>*/}
                    {/*        </div>*/}


                    {/*        /!*<div className="dumbbell-land-add-trainee-form-item">*!/*/}
                    {/*        /!*    <input type="text" name="" id=""/>*!/*/}
                    {/*        /!*    <span>تصاویر</span>*!/*/}
                    {/*        /!*</div>*!/*/}

                    {/*        <div className="dumbbell-login-btn" onClick={e => {*/}
                    {/*            handleSendForm()*/}
                    {/*        }}>*/}
                    {/*            <span>تکمیل و ارسال</span>*/}
                    {/*        </div>*/}

                    {/*    </div> : null*/}
                    {/*}*/}

                    {/*{compMode==="completed"?*/}
                    {/*    <div className="dumbbell-login-btn" onClick={e=>{*/}
                    {/*        window.open("https://www.google.com", '_blank');*/}
                    {/*    }} >*/}
                    {/*        <span>ورود به اپ</span>*/}
                    {/*    </div>:null*/}
                    {/*}*/}

                    <br/><br/>

                </div>


            </Spin>

        </div>
    )
}

export default DumbbellCallbackLand;
