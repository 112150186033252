import React, {useState, useEffect, Fragment} from "react";
import DumbbellHeader from "../../Components/DumbbellHeder/DumbbellHeder";
import DumbbellBoxTrainee from "../DumbbellBoxTrainee/DumbbellBoxTrainee";
import { PictureOutlined } from '@ant-design/icons';
import {Drawer, Spin} from "antd";
import plusWhiteIcon from "../../assets/dumbbell_image/plus-white-icon.png";
import DumbbellAddPackage from "../DumbbellAddPackage/DumbbellAddPackage";
import {deletePackage_api, deleteProduct_api, listPackage_api, listProduct_api} from "../../Services/Services";
import "./Product.scss";
import trashWhiteIcon from "../../assets/dumbbell_image/trash_white_icon.png";
import {numberWithCommas} from "../../Services/utils";
import {useDispatchLoading} from "../../Hook/useDispatchLoading";
import DumbbellAddProduct from "../DumbbellAddProduct/DumbbellAddProduct";
import {Setup} from "../../Services/Setup";

function ProductCard({item,fetchList,editPack}){
    const [cardSpin,set_cardSpin] = useState(false)
    const deletePackage = async ()=>{
        set_cardSpin(true)
        try{
             await deleteProduct_api(item._id)
            set_cardSpin(false)
            fetchList()
        }catch (e) {
            set_cardSpin(false)
            console.log(e)
        }
    }

    return(
        <Fragment>
            <Spin spinning={cardSpin}>
                <div className="package-card">
                    <div className="package-card-header">
                        <img onClick={deletePackage} className="clickable" src={trashWhiteIcon} alt=""/>
                        {item.show_in_regal?
                            <span className="order-in-regal">{item.order_in_regal}</span>:
                            null
                        }

                    </div>


                    <div className="product-image">
                        {item.status!=="active" ?
                            <div className="product-image-inactive">
                                <span>غیر فعال</span>
                            </div>:null
                        }
                        {item.status==="active" &&  item.stock<1?
                            <div className="product-image-inactive">
                                <span>ناموجود</span>
                            </div>:null
                        }
                        {item.image?
                            <img src={Setup.filePoint+item.image} alt=""/>:
                            <div className="image-placeholder">
                                <PictureOutlined/>
                            </div>
                        }
                    </div>

                    <div className="product-names">
                        <div className="product-fa_names">
                            <h4>{item.fa_name}</h4>
                        </div>
                        <div className="product-en_names">
                            <h6>{item.en_name}</h6>
                        </div>
                    </div>

                    <div className="package-card-price">

                        <span className="package-card-pay_amount">{numberWithCommas(item.price-item.discount)}تومان </span>
                        {item.discount>0?
                            <span className="package-card-amount">{numberWithCommas(item.price)}تومان </span>:
                            null
                        }
                        <span className="package-card-extra_label">{item.label} </span>
                    </div>

                    <div className="product-description">
                        <p>{item.product_description}</p>
                    </div>

                    <div className="owner_description">
                        <p>{item.owner_description}</p>
                    </div>

                    <div className="package-card-btn clickable" onClick={e=>{
                        editPack(item)
                    }}>
                        <span>ویرایش محصول</span>
                    </div>

                </div>
            </Spin>
        </Fragment>
    )
}

function Product (){

    const [data,set_data] = useState([])
    const setLoader = useDispatchLoading()

    const getProducts = async ()=>{
        setLoader(true)
        try{
            const resp = await listProduct_api()
            setLoader(false)
            set_data(resp.data.data)
        }catch (e) {
            setLoader(false)
            console.log(e)
        }
    }

    useEffect(()=>{
        getProducts()
    },[])

    //drawer
    const [visibleAddPackageDrawer,set_visibleAddPackageDrawer] = useState(false)

    const showBoxDrawer = ()=>{
        set_visibleAddPackageDrawer(!visibleAddPackageDrawer)
    }

    const onAddPackageDrawerClose = () => {
        set_visibleAddPackageDrawer(false);
    };

    const handleDoneSendRequest =()=>{
        onAddPackageDrawerClose()
        getProducts()
    }

    const [currentEdit,set_currentEdit] = useState(null)
    const editPack =(pack)=>{
        set_currentEdit(pack)
        showBoxDrawer()
    }

    return(
        <>
            <DumbbellHeader title="محصولات"/>
            <div className="package-page">
                <div className="package-list">
                    {data.map(d=>{
                        return(
                            <ProductCard editPack={editPack} item={d} fetchList={getProducts}/>
                        )
                    })}
                </div>
            </div>

            <div className="float-add-btn-v2 clickable" onClick={e=>{
                set_currentEdit(null)
                showBoxDrawer()
            }}>
                <img src={plusWhiteIcon} alt=""/>
            </div>

            <Drawer push={false} destroyOnClose={true}  bodyStyle={{padding:0,margin:0}}  width={300} closable={false}  onClose={onAddPackageDrawerClose} visible={visibleAddPackageDrawer}>
                <DumbbellAddProduct edit={currentEdit} handleDoneSendRequest={handleDoneSendRequest}/>
            </Drawer>
            </>
    )

}

export default Product
